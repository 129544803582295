<template>
  <div class="h-full w-full flex flex-col">
    <div
      id="woosmap"
      ref="map"
      class="w-full h-full bg-white-rock">
      <div class="flex flex-col items-center justify-center w-full h-full font-semibold">
        <vue3-lottie
          :animation-data="FruitsLoader"
          :width="100"
          autoplay
          class="h-auto"
          loop />
        Chargement de la carte...
      </div>
    </div>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import FruitsLoader from 'Lottie/FruitsLoader';

import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  MARKER_DEFAULT_SELECTED,
  MARKER_USER,
} from 'Classes/utils/MapUtils';

import {
  RESET_USER_SEARCH,
  UPDATE_SELECTED_DELIVERY_POINT,
  UPDATE_USER_MARKER,
} from 'Stores/types/mapMutationsTypes';
import {
  SEARCH_WOOS_DELIVERY_POINTS,
  FETCH_PUBLIC_DELIVERY_POINTS,
  RESET_MAP,
  SETUP_MAP,
} from 'Stores/types/mapActionsTypes';

import SET_WOOS_MAP_LOADER from 'Components/deliveryPoint/map/DlpMapPublic';

import WoosMapMixin from 'Mixins/WoosMapMixin';

export default {
  name: 'WoosMap',

  components: { Vue3Lottie },

  props: {
    hideOverlay: {
      type: Boolean,
      default: false,
    },
    customOptions: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [WoosMapMixin],

  data() {
    return {
      FruitsLoader,
      DOMisReady: false,
    };
  },

  computed: {
    ...mapGetters('map', [
      'getWoosScriptIsLoaded',
      'getSelectedDeliveryPoint',
      'getWoosMap',
      'getWoosStoreOverlay',
      'getUserSearch',
      'getWoosDlpList',
      'getUserMarkerLatLng',
      'getStoreMarkerLatLng',
      'getMapCenter',
      'getMapZoom',
      'getWoosData',
      'getDeliveryPointById',
    ]),
    latlngParams() {
      return this.$potagerRoute?.params?.latlng;
    },
    loadDlpsWatcher() {
      return this.getWoosScriptIsLoaded && !!this.getWoosMap && !!this.getWoosData && !!this.getUserMarkerLatLng;
    },
    isReady() {
      return this.DOMisReady && this.getWoosScriptIsLoaded;
    },
  },

  watch: {
    isReady(isLoaded) {
      if (isLoaded) {
        this.$nextTick(() => {
          this.FETCH_PUBLIC_DELIVERY_POINTS()
            .then(() => {
              this.$wait.start(SET_WOOS_MAP_LOADER);
              this.SETUP_MAP({
                mapElement: this.$refs.map,
                customOptions: this.customOptions,
              });
            });
        });
      }
    },
    loadDlpsWatcher(load) {
      if (load) this.SEARCH_WOOS_DELIVERY_POINTS(this.getUserMarkerLatLng);
    },
    getMapCenter(mapCenter) {
      if (mapCenter && this.getWoosMap) {
        this.getWoosMap.flyTo({
          center: mapCenter,
          animate: true,
          zoom: this.getMapZoom,
        });
      }
    },
    getMapZoom(mapZoom) {
      if (mapZoom && this.getWoosMap) {
        this.getWoosMap.flyTo({
          zoom: mapZoom,
          animate: true,
          center: this.getMapCenter,
        });
      }
    },
    getWoosStoreOverlay(overlay) {
      if (!this.hideOverlay) overlay?.setMap(this.getWoosMap);
    },
    latlngParams(latlng) {
      if (latlng) {
        const [lat, lng] = latlng.split(',');
        const position = {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };

        this.UPDATE_USER_MARKER(position);
      }
    },
    getUserMarkerLatLng: {
      handler(latLng) {
        if (latLng) {
          this.userMarker?.setMap(null);
          this.userMarker = new woosmap.map.Marker({
            position: latLng,
            icon: MARKER_USER,
            map: this.getWoosMap,
          });
          this.SEARCH_WOOS_DELIVERY_POINTS(latLng);
        } else {
          this.userMarker?.setMap(null);
        }
      },
      immediate: true,
    },
    getStoreMarkerLatLng: {
      handler(latLng) {
        if (latLng) {
          this.storeMarker?.setMap(null);
          this.storeMarker = new woosmap.map.Marker({
            position: latLng,
            icon: MARKER_DEFAULT_SELECTED,
            map: this.getWoosMap,
          });
        } else {
          this.storeMarker?.setMap(null);
        }
      },
      immediate: true,
    },
    getSelectedDeliveryPoint(dlp) {
      if (this.getWoosMap) {
        if (dlp) {
          this.focusSelectedDeliveryPoint();
        } else {
          this.unFocusSelectedDeliveryPoint();
        }
      }
    },
    getWoosMap(map) {
      if (map) {
        this.$wait.end(SET_WOOS_MAP_LOADER);

        if (this.getUserMarkerLatLng) {
          this.SEARCH_WOOS_DELIVERY_POINTS(this.getUserMarkerLatLng)
            .then(() => {
              if (this.latlngParams) this.boundDlpS(false);
            });
        }

        // on map load, we focus on the selected delivery point
        this.focusSelectedDeliveryPoint();

        // LISTENERS
        woosmap.map.event
          .addListener(map, 'store_selected', (store) => {
            const splitId = store.properties.store_id.split('_');
            const dlp = this.getDeliveryPointById(splitId[0], splitId[1]);
            if (dlp) {
              this.UPDATE_SELECTED_DELIVERY_POINT(dlp);

              // we don't want to $emit on mobile because we want to show pin on map and not redirect
              // the @onDlpSelect event is handled in the dlp-map-public__dlp-infos
              if (!this.$mq.bp768) this.$emit('onDlpSelect', dlp);
            } else {
              console.error('dlp not found', store);
            }
          });

        woosmap.map.event
          .addListener(map, 'dragend', () => {
            this.RESET_USER_SEARCH();
            const {
              _lat: lat,
              _lng: lng
            } = map.getCenter();
            this.SEARCH_WOOS_DELIVERY_POINTS({
              lat,
              lng
            });
          });
      }
    },
    getWoosDlpList() {
      if (this.getUserSearch) this.boundDlpS();
    }
  },

  methods: {
    ...mapMutations('map', [
      RESET_USER_SEARCH,
      UPDATE_USER_MARKER,
      UPDATE_SELECTED_DELIVERY_POINT,
    ]),
    ...mapActions('map', {
      FETCH_PUBLIC_DELIVERY_POINTS,
      SEARCH_WOOS_DELIVERY_POINTS,
      RESET_MAP,
      SETUP_MAP,
    }),
  },

  mounted() {
    this.DOMisReady = true;
    if (this.getWoosScriptIsLoaded === undefined) this.initializeWoosMap();
  },

  beforeUnmount() {
    // if we kill the component before the map is loaded, we need to stop the loader
    this.$wait.end(SET_WOOS_MAP_LOADER);

    if (this.getWoosMap) this.RESET_MAP();
  },
};
</script>
