import { GoogleAuthProvider } from 'firebase/auth';

import Provider from 'Classes/social-providers';
import IconPtcyGoogle from 'Icons/color/IconPtcyGoogle';

export default new Provider({
  name: 'google',
  icon: IconPtcyGoogle,
  color: '#4285F4',

  getProviderInstance: () => {
    const instance = new GoogleAuthProvider();
    instance.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
    instance.setCustomParameters({
      prompt: 'select_account',
    });
    return instance;
  },

  getLoginData: (data) => ({
    accessToken: data._tokenResponse.oauthAccessToken,
  }),
});
