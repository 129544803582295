<template>
  <div class="panel-section">
    <component
      :is="!order || order?.isEditable ? 'panel-section-navigator' : 'div'"
      :to="!order || order?.isEditable ? { name: 'mon-compte_payment-methods', query: { context, orderId: order?.id } } : null"
      class="panel-section__title">
      Paiement

      <template #right>
        <potager-button
          v-if="!order || order?.isEditable"
          is-rounded
          size="small"
          theme="stroke">
          Gérer
        </potager-button>
      </template>

      <potager-badge
        v-if="order && !order?.isEditable"
        :theme="paymentStatus.type"
        rounded
        size="medium">
        {{ paymentStatus.label }}
      </potager-badge>
    </component>

    <panel-section-navigator
      v-for="(payment, index) in paymentsList"
      :key="index"
      :to="getPaymentRoute(payment)">
      <span class="text-sm font-medium">
        <component
          :is="payment.icon"
          class="mr-2" />

        {{ payment.label }}
      </span>

      <template
        v-if="payment?.toggle || payment?.value"
        #right>
        <potager-switch
          v-if="payment.toggle"
          :is-loading="payment.loading"
          :model-value="payment.isActive"
          @update:modelValue="payment.toggle" />
        <span
          v-else-if="payment.value"
          :class="[
            'font-bold text-sm',
            { 'line-through': [PaymentStatus.Refused, PaymentStatus.Canceled].includes(payment.status) },
          ]">
          {{ payment.value }}
        </span>
      </template>
    </panel-section-navigator>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerReceiptLine from 'UI/PotagerReceiptLine';
import PotagerButton from 'UI/PotagerButton';
import PotagerSwitch from 'UI/Form/PotagerSwitch';

import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import { getPaymentMethodByCard } from 'Classes/payment-methods';

import IconArrowRight from 'Icons/bold/IconArrowRight';

import { Context } from 'PotagerLogic/Enums/Context';
import { getOrderPaymentStatus } from 'PotagerLogic/Utils/Order/OrderStatus';
import { PaymentStatus } from 'PotagerLogic/Enums/Payment';
import { formatPrice } from 'PotagerLogic/Formatting';

export default {
  name: 'PaymentSection',
  components: {
    PotagerBadge,
    PotagerSwitch,
    PotagerReceiptLine,
    PanelSectionNavigator,
    PotagerButton,
    IconArrowRight,
  },
  props: {
    order: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      Context,
      PaymentStatus,
    };
  },
  computed: {
    ...mapGetters('user', [
      'getBankCards',
      'getRestaurantPMs',
      'getDefaultRestaurantPM',
      'getCardById',
    ]),
    paymentStatus() {
      return getOrderPaymentStatus(this.order);
    },
    paymentsList() {
      return !this.order || this.order?.isEditable ? this.editablePaymentsList : this.lockedPaymentsList;
    },
    editablePaymentsList() {
      return [
        ...this.getBankCards
          .filter((card) => card.priority === 'PRIMARY')
          .map(this.mapEditableCard),
        ...this.getRestaurantPMs
          .map(this.mapEditablePayment)
      ].filter(Boolean);
    },
    lockedPaymentsList() {
      return this.order?.paymentAttempt?.payments?.map(this.mapLockedPayment)
        .filter(Boolean);
    },
    context() {
      return this.order ? Context.Order : Context.Basket;
    }
  },
  methods: {
    mapEditableCard(card) {
      const pm = getPaymentMethodByCard(card);
      const cardNumber = card.number?.replace(/XXXX/g, '•••• ');
      return pm ? {
        label: cardNumber ? `${pm.abbr} : ${cardNumber}` : pm.title,
        route: {
          ...pm.route,
          props: {
            context: Context.Basket,
          }
        },
        icon: pm.defaultIcon,
      } : null;
    },
    mapEditablePayment(payment) {
      const pm = getPaymentMethodByCard(payment);
      return pm ? {
        label: pm.abbr,
        isActive: payment?.usableForSubscription,
        toggle: () => pm.actions.setDefault({
          isDefault: !payment?.usableForSubscription,
          cardId: payment?.id
        }),
        icon: pm.defaultIcon,
        loading: this.$wait.is(pm.loaders.editSettings),
      } : null;
    },
    mapLockedPayment(payment) {
      const pm = getPaymentMethodByCard(payment);
      const card = this.getCardById(payment.blueCard);
      const cardNumber = card ? card.number?.replace(/XXXX/g, '•••• ') : null;
      return pm ? {
        label: cardNumber ? `${pm.abbr} : ${cardNumber}` : pm.title,
        icon: pm.defaultIcon,
        value: formatPrice(payment.amount),
        status: payment.status,
      } : null;
    },
    getPaymentRoute(payment) {
      return payment?.route && !payment?.toggle ? {
        ...payment.route,
        query: {
          context: this.context,
          skipHub: true,
          orderId: this.order?.id,
        },
      } : null;
    }
  }
};
</script>
