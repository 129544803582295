<template>
  <transition
    mode="out-in"
    name="fade-fast-translate-y">
    <div
      v-if="showMinTotal"
      class="bg-sandy-beach panel-section__line px-4 py-3 text-xs.5 font-medium">
      Plus que <b class="font-bold">
        {{ formatPrice(diffPrice) }}
      </b> pour commander (min. {{ formatPrice(getOneShotMinimumAmount) }})

      <div class="progress-bar mt-2.5 h-1 bg-white rounded overflow-hidden">
        <div
          :style="{ transform: `translateX(-${100 - percentage}%)` }"
          class="progress-bar__fill bg-mango-tango h-full w-full rounded transition ease-out duration-300" />
      </div>
    </div>

    <panel-section-navigator
      v-else-if="showCoupons"
      :to="{ name: 'basket_coupons' }"
      class="bg-white-rock mb-1 px-4 py-3 text-xs.5">
      Vous avez <b>
        {{ pluralize(getUserCoupons.length, 'code') }} {{ getUserCoupons.length > 1 ? 'promos' : 'promo' }}
      </b>
      {{ getUserCoupons.length > 1 ? 'disponibles' : 'disponible' }}
    </panel-section-navigator>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatPrice, pluralize } from 'PotagerLogic/Formatting';

import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator.vue';

export default {
  name: 'BasketPreFooter',
  components: { PanelSectionNavigator },
  methods: {
    pluralize,
    formatPrice
  },
  computed: {
    ...mapGetters('app', ['getOneShotMinimumAmount']),
    ...mapGetters('basket', ['getSubTotal', 'isSubscription', 'getSimulatedOrder']),
    ...mapGetters('user', ['getUserCoupons']),
    diffPrice() {
      return this.getOneShotMinimumAmount - this.getSubTotal;
    },
    percentage() {
      return Math.round((this.getSubTotal / this.getOneShotMinimumAmount) * 100);
    },
    isBasketEmpty() {
      return Number(this.getSubTotal) === 0;
    },
    showMinTotal() {
      return !this.isBasketEmpty && this.diffPrice > 0 && !this.isSubscription;
    },
    showCoupons() {
      return this.getUserCoupons.length > 0 && this.getSimulatedOrder?.coupons.length === 0;
    }
  },
};
</script>
