<template>
  <component
    :is="seo ? 'picture' : 'div'"
    ref="wrapper"
    :style="wrapperStyles"
    class="potager-picture">
    <source
      v-if="getWebpImage"
      :srcset="getWebpImage.src"
      type="image/webp">

    <transition name="fade-fast">
      <img
        v-if="imgCondition"
        ref="img"
        :alt="alt || title"
        :class="[getClass]"
        :data-src="getImage.src"
        :loading="disableLazyLoad ? 'eager' : 'lazy'"
        :src="getImage?.src"
        :style="{
          objectPosition: `${position} !important`,
          objectFit: backgroundSize || 'cover',
          height: height,
          width: width || (ratio ? 'auto' : '100%'),
          aspectRatio: ratio,
        }"
        :title="title || alt"
        @error="onImgLoaded(false)"
        @load="onImgLoaded(true)">
    </transition>

    <transition name="fade">
      <div
        v-if="!isLoaded && placeholder"
        :class="[
          getClass,
          'potager-picture__img--placeholder absolute inset-0',
          `${typeof placeholder === 'string' ? placeholder : placeholder ? 'bg-white-rock' : ''}`
        ]"
        :style="{
          fontSize: $el ? `${$el.offsetWidth / 4 < 16 ? 16 : $el.offsetWidth / 4}px` : '16px',
        }">
        <icon-logo-potager-mini
          class="absolute animate-pulse top-1/2 left-1/2 m-0 text-bianca/50 leading-none text-6xl potager-picture__placeholder" />
      </div>
    </transition>

    <slot />
  </component>
</template>

<script>
import { propsBuilder } from 'UI/Tools';
import IconLogoPotagerMini from 'Icons/bold/IconLogoPotagerMini';
import { importer, getClosestAvailableSize } from 'PotagerAssets/images';
// - **220px - Extra Small" (XS) - quality 90**
// - **360px - "Small Retina" (SR) - quality 90**
// - **540px : "Medium Retina" (MR) - quality 85**
// - **640px "Large Mobile / Small Tablet" (LM/ST) - quality 85**
// - **768px "Tablet Portrait" (TP) - quality 80**
// - **1024px "Tablet Landscape / Small Desktop" (TL/SD) - quality 80**
// - **1480px "Large Retina" (LR) - quality 75**
// - **2048px "Extra Large Retina" (XLR) - quality 75**
const API_FORMATS = [
  {
    name: 'urlExtraSmall',
    width: 220,
  },
  {
    name: 'urlSmallRetina',
    width: 360,
  },
  {
    name: 'urlMediumRetina',
    width: 540,
  },
  {
    name: 'urlSmallTablet',
    width: 640,
  },
  {
    name: 'urlTabletPortrait',
    width: 768,
  },
  {
    name: 'urlTabletLandscape',
    width: 1024,
  },
  {
    name: 'urlLargeRetina',
    width: 1480,
  },
  {
    name: 'urlExtraRetina',
    width: 2048,
  },
];

export const props = {
  src: {
    type: [String, Object],
    required: false,
    default: undefined,
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  alt: {
    type: String,
    required: false,
    default: undefined,
  },
  height: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  width: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  background: {
    type: Boolean,
    required: false,
    default: false,
  },
  backgroundSize: {
    type: String,
    required: false,
    default: 'cover',
  },
  backgroundRepeat: {
    type: String,
    required: false,
    default: null,
  },
  position: {
    type: String,
    required: false,
    default: null,
  },
  seo: {
    type: Boolean,
    required: false,
    default: true,
  },
  placeholder: {
    type: [Boolean, String],
    required: false,
    default: false,
  },
  // Overpass lazy load
  noLazyLoad: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  nativeResolution: {
    type: Boolean,
    required: false,
    default: false,
  },
  extraImgClass: {
    type: String,
    required: false,
    default: '',
  },
  ratio: {
    type: String,
    required: false,
    default: undefined,
  },
};

export default {
  components: {
    IconLogoPotagerMini,
  },

  data: () => ({
    isVisible: false,
    loaded: false,
    loadError: false,
    image: undefined,
    srcset: undefined,
    observer: undefined,
  }),

  props: propsBuilder(props),

  watch: {
    isVisible: {
      handler(isVisible) {
        if (this.logTestCondition) console.info('isVisible', isVisible);
        if ((isVisible || this.disableLazyLoad) && this.src) {
          this.loadPicture();
        }
      },
    },
    src: {
      handler(val) {
        if (this.logTestCondition) console.info('src', this.src);
        if (val && this.$el && (this.isVisible || this.disableLazyLoad)) {
          this.loadPicture();
        }
      },
      immediate: true,
      deep: true,
    },
    // change les images aux breakpoints tablet, mobile, desktop
    $mq: {
      handler(newMq, oldMq) {
        if (this.logTestCondition) console.info('$mq', newMq, oldMq);
        if (this.nativeResolution) return;
        if (this.srcset) {
          const oldWidth = oldMq.width;
          const newWidth = newMq.width;

          if (newWidth !== oldWidth && newWidth > oldWidth) {
            this.$nextTick(() => {
              this.loadPicture();
            });
          }
        }
      },
    },
  },

  computed: {
    logTestCondition() {
      const srcString = undefined;

      if (this.src) {
        const source = typeof this.src === 'object' ? this.src.url : this.src;
        return source?.includes(srcString);
      }

      return false;
    },
    imgRatio() {
      return this.src.height / this.src.width || 1;
    },
    isLoaded() {
      if (this.logTestCondition) console.info('isLoaded', this.loaded, this.background, !this.seo, this.loaded || (!this.seo && this.background));
      // is <img /> loaded or background && !seo (meaning it's a background image)
      return this.loaded || (!this.seo && this.background);
    },
    imgCondition() {
      if (this.logTestCondition) console.info('imgCondition', this.getImage, this.isVisible, this.seo);
      return this.getImage && this.isVisible && this.seo && !this.loadError;
    },
    wrapperStyles() {
      if (this.logTestCondition) console.info('wrapperStyles', this.width, this.height, this.background, this.backgroundSize, this.position);
      const styles = {
        width: this.width,
        height: this.height,
        minHeight: !this.background ? `${this.height || (this.width && this.height) || (this.$el?.offsetWidth && this.$el?.offsetWidth)}px` : undefined,
      };

      if (!this.seo && this.background && this.getImage) {
        styles.backgroundImage = `url(${this.getImage.src})`;
        styles.backgroundPosition = `${this.position} !important`;
        styles.backgroundSize = this.backgroundSize || 'cover';
        styles.backgroundRepeat = this.backgroundRepeat || 'no-repeat';
      }

      return styles;
    },
    getWebpImage() {
      if (this.logTestCondition) console.info('getWebpImage', this.image, this.image?.filter((file) => file.format === 'webp')?.[0]);
      return this.image?.filter((file) => file.format === 'webp')?.[0];
    },
    getImage() {
      if (this.logTestCondition) console.info('getImage', this.image, this.image?.filter((file) => !file.format !== 'webp')?.[0]);
      return this.image?.filter((file) => file.format !== 'webp')?.[0];
    },
    getClass() {
      if (this.logTestCondition) console.info('getClass', this.extraImgClass, this.imgCondition, this.background, this.seo, this.isLoaded, this.width, this.height);
      return [
        ...this.extraImgClass.split(' '),
        {
          'potager-picture__img h-full': true,
          '-z-10 absolute inset-0': this.background,
          'object-cover object-left': this.width || this.height,
        }];
    },
    disableLazyLoad() {
      // si on est en mode impression, on force le chargement de l'image, ou que l'image est trop petite (~= icon)
      const isSmallWrapper = this.$el.offsetWidth <= 100 && this.$el.offsetHeight <= 100;
      const isSmallSrc = (this.src?.width && this.src?.width <= 100) && (this.src?.height && this.src?.height <= 100);
      return this.noLazyLoad || this.nativeResolution || window.matchMedia('print').matches || isSmallWrapper || isSmallSrc;
    },
  },

  methods: {
    loadImageFromAssets(value, isOriginal) {
      const dimensions = isOriginal ? 3840 : {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      };

      return importer(value, dimensions)
        .then(result => result)
        .catch(() => {
          // Tente d'importer l'image depuis le dossier local si l'importer échoue
          return import(`Images/${value}`)
            .then(({ default: src }) => [{
              src,
              path: value,
              format: this.getFormatFromUrl(src),
            }])
            .catch(() => {
              console.error('Failed to load image:', value);
              return [];
            });
        });
    },

    loadImageFromApi(val, original) {
      if (this.logTestCondition) console.info('loadImageFromApi', val, original);
      const screenPixelRatio = window.devicePixelRatio || 1;

      this.srcset = API_FORMATS
        .filter((format) => !!val[format.name])
        .map((format) => ({
          format: this.getFormatFromUrl(val[format.name]),
          height: format.width * this.imgRatio,
          src: val[format.name],
          width: format.width,
        }));

      this.srcset.push({
        src: val.url,
        format: this.getFormatFromUrl(val.url),
        width: val.width,
        height: val.height,
        original: true,
      });

      if (this.logTestCondition) console.info('loadImageFromApi srcset', this.$el, this.$el.offsetWidth, this.$el.offsetHeight);
      return [original ? this.srcset.find((file) => file.original) : getClosestAvailableSize(this.srcset, {
        width: this.$el.offsetWidth * screenPixelRatio,
        height: this.$el.offsetHeight * screenPixelRatio,
      })];
    },

    getFormatFromUrl(url) {
      return url.slice((Math.max(0, url.lastIndexOf('.')) || Infinity) + 1);
    },

    async loadPicture(original) {
      if (this.logTestCondition) console.info('loadPicture', this.src);

      let val = this.src;
      if (!val) return;

      if (typeof val === 'string') {
        if (val.indexOf('http') !== -1) {
          this.image = [{
            src: val,
            path: val,
            format: this.getFormatFromUrl(val),
          }];
          return;
        }

        const hash = val.indexOf('?') !== -1 ? val.split('?')[1] : undefined;
        val = val.split('?')[0];

        this.image = await this.loadImageFromAssets.call(this, val, original);
        this.image.forEach((file) => {
          file.path += (hash ? `?${hash}` : '');
          file.src += (hash ? `?${hash}` : '');
        });
      } else if (typeof val === 'object' && val.url) {
        this.image = this.loadImageFromApi.call(this, val, original);
      }

      if (this.logTestCondition) console.info('loadPicture final image', this.image);
    },

    onImgLoaded(success) {
      if (this.logTestCondition) console.info('onImgLoaded');
      if (success) {
        this.loaded = true;
        this.$emit('onLoaded', this.getImage, this.$el);
      } else {
        this.$emit('onError', this.getImage);
        if (this.getImage?.original) {
          this.loadError = true;
        } else {
          this.loadError = false;
          this.$nextTick(() => {
            this.loadPicture(true);
          });
        }
      }
    },

    init() {
      if (this.disableLazyLoad) {
        this.isVisible = true;
      } else {
        // use intersection observer to observe wrapper to replace the
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.isVisible = true;
              this.observer.disconnect();
            }
          });
        });

        if (this.$refs.wrapper) {
          this.observer.observe(this.$refs.wrapper);
        }
      }
    },
  },

  mounted() {
    this.init();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);

    if (this.observer) {
      this.observer.disconnect();
    }
  },
};

</script>

<style lang="scss" scoped>
.potager-picture {
  @apply block;

  &__placeholder {
    transform: translate(-50%, -50%);
  }

  &__img {
    @apply block;
  }
}
</style>
