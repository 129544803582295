<template>
  <div
    :class="[
      'dlp-map-public',
      'w-full h-full flex flex-col bg-bianca self-start dlp-map-public--panel',
    ]">
    <dlp-list-switcher
      v-if="$mq.bp768"
      class="absolute top-3 left-16 z-10" />

    <woos-map @onDlpSelect="$emit('onDlpSelect', $event)" />

    <transition name="appear-from-bottom">
      <div
        v-if="$mq.bp768 && getSelectedDeliveryPoint"
        class="dlp-map-public__dlp-infos shadow-none absolute bottom-0 left-0 right-0 pointer-events-auto z-20">
        <transition name="slide-right-to-left">
          <div
            :key="getSelectedDeliveryPoint.id"
            class="w-full p-4 pb-8 bottom-0">
            <dlp-card
              :context="context"
              :delivery-point="getSelectedDeliveryPoint"
              class="shadow-lg"
              closable
              condensed
              is-selected
              show-gdds
              @onClick="$emit('onDlpSelect', getSelectedDeliveryPoint)"
              @onClose="UPDATE_SELECTED_DELIVERY_POINT(null)" />
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  UPDATE_SELECTED_DELIVERY_POINT,
  UPDATE_USER_MARKER,
} from 'Stores/types/mapMutationsTypes';
import {
  SEARCH_WOOS_DELIVERY_POINTS,
} from 'Stores/types/mapActionsTypes';

import WoosMapMixin from 'Mixins/WoosMapMixin';

import DlpCard from 'Components/deliveryPoint/DlpCard';
import DlpListSwitcher from 'Components/deliveryPoint/DlpListSwitcher';
import WoosMap from 'Components/map/WoosMap';

import { Context } from 'PotagerLogic/Enums/Context';

export const SET_WOOS_MAP_LOADER = 'SET_WOOS_MAP_LOADER';

export default {
  components: {
    WoosMap,
    DlpListSwitcher,
    DlpCard,
  },

  mixins: [WoosMapMixin],

  props: {
    context: {
      type: String,
      required: false,
      default: Context.Basket,
    },
  },
  emits: ['onDlpSelect'],
  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
    ]),
    latlngParams() {
      return this.$potagerRoute?.params?.latlng;
    },
    searchPublicIdQueryParam() {
      return this.$potagerRoute.query.search_public_id;
    },
  },

  watch: {
    latlngParams: {
      handler(latlng) {
        if (latlng && !this.searchPublicIdQueryParam) {
          const [lat, lng] = latlng.split(',');
          const position = {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          };

          this.UPDATE_USER_MARKER(position);
          this.SEARCH_WOOS_DELIVERY_POINTS(position)
            .then(() => this.boundDlpS(true));
        }
      },
    },
  },

  methods: {
    ...mapMutations('map', [
      UPDATE_SELECTED_DELIVERY_POINT,
      UPDATE_USER_MARKER,
    ]),
    ...mapActions('map', {
      SEARCH_WOOS_DELIVERY_POINTS,
    }),
  },
};
</script>
