<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Récapitulatif
    </h1>

    <p class="mb-10">
      👍 Votre {{ isFirstOrder ? '1ère' : '' }} commande sera <b>livrée le {{ getDeliveryDate }}.</b> Pas d’inquiétude,
      vous pouvez modifier et stopper
      votre abonnement quand vous voulez.
    </p>

    <tunnel-payment-aside class="text-left" />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import { getGddFormattedDay } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDay';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelPaymentAside from 'Components/tunnel/aside/TunnelPaymentAside';

export default {

  components: {
    TunnelPaymentAside,
    TunnelBody,
  },

  computed: {
    ...mapGetters('user', [
      'getOrders',
      'getTotalPaidOrders',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getGroupDeliveryDay',
    ]),
    isFirstOrder() {
      return !this.getOrders.length && !this.getTotalPaidOrders;
    },
    getDeliveryDate() {
      return getGddFormattedDay(this.getGroupDeliveryDay, { showHours: true });
    },
  },
};
</script>
