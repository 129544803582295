import { getAuth, signInWithPopup } from 'firebase/auth';
import { getApp } from '@firebase/app';

import { SOCIAL_LOGIN_USER_ACTION } from 'Stores/types/sessionActionsTypes';
import { UPDATE_TEMPORARY_CUSTOMER_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';
import { inAppWebView } from 'Classes/ConfigurationManager';
import { capitalize } from 'PotagerLogic/Formatting';

import PotagerProviderButton from 'UI/PotagerProviderButton';
import ModalInfo from 'Modals/ModalInfo';

import { openModal } from 'Plugins/potagerModals';

import { h } from 'vue';

import { useGtm } from '@gtm-support/vue-gtm';
import { notify } from '@kyvg/vue3-notification';
import { storeDispatch } from 'Services/storeServices.js';
import WORDINGS from 'PotagerLogic/Constants/Wordings';

const gtm = useGtm();

// firebase auth loader
export const FIREBASE_AUTH_LOADER = 'firebase-auth-loader';

export default class {
  constructor(config) {
    this.config = config;
  }

  getLoginButton(app, callback) {
    const label = `Connexion avec ${capitalize(this.config.name)}`;
    const {
      colors,
      icon
    } = this.config;
    const theme = 'white';

    return {
      render: () => h(PotagerProviderButton, {
        colors,
        theme,
        onClick: async () => {
          try {
            const resp = await this.login(app);
            if (typeof callback === 'function') callback(resp);
          } catch ({ error }) {
            if (error?.response?.status === 401) {
              openModal(ModalInfo, {
                title: 'On ne se connaît pas (encore) !',
                text: 'Vous n\'avez pas encore de compte Potager City avec cette adresse mail. <b>Inscrivez-vous pour continuer.</b>',
                onCloseRedirect: { name: 'register' },
                closeBtnTheme: 'go-green',
                closeBtnText: 'M’inscrire',
              });
            } else {
              console.error(error);
              notify({
                type: 'error',
                title: 'Oups. Une erreur est survenue',
                text: `Une erreur inattendue est survenue. Merci de réessayer plus tard ou de ${WORDINGS.COMMON.SAV_LINK.FULL} si le problème persiste.`,
              });
            }
          }
        },
      }, {
        default: () => label,
        icon: () => h(icon)
      }),
    };
  }

  getRegisterButton(app, onSuccess) {
    const label = `S'inscrire avec ${capitalize(this.config.name)}`;

    const register = (w) => this.register(app, w);

    return {
      render: () => h(PotagerProviderButton, {
        colors: this.config.colors,
        theme: 'white',
        onClick: () => {
          register()
            .then((response) => {
              if (typeof onSuccess === 'function') onSuccess(response);
            });
        },
      }, {
        default: () => label,
        icon: () => h(this.config.icon)
      }),
    };
  }

  tracking(app, value, params = {}) {
    try {
      gtm.trackEvent({
        event: 'Modal',
        eventLabel: `Modal${capitalize(this.config.name)}`,
        eventValue: value,
        params,
      });
    } catch (e) { /* Do Nothing */
    }
  }

  signIn(app) {
    return new Promise((resolve, reject) => {
      this.tracking(app, 'open');

      if (inAppWebView && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ socialSignUp: this.config.name }));

        const onResponse = (e) => {
          try {
            const data = JSON.parse(e.data);
            if (data.fromApp) {
              window.removeEventListener('message', onResponse);
              document.removeEventListener('message', onResponse);

              data.success ? resolve(data.success) : reject();
            }
          } catch (e) { /* Do nothing */
          }
        };

        window.addEventListener('message', onResponse);
        document.addEventListener('message', onResponse);
      } else {
        storeDispatch(`wait/start`, FIREBASE_AUTH_LOADER);
        signInWithPopup(getAuth(getApp()), this.config.getProviderInstance())
          .then((data) => resolve(data))
          .catch((error) => {
            this.tracking(app, 'dismiss');
            reject(error);
          })
          .finally(() => storeDispatch(`wait/end`, FIREBASE_AUTH_LOADER));
      }
    });
  }

  login(app) {
    return new Promise((resolve, reject) => {
      this.signIn(app)
        .then((data) => {
          const param = {
            provider: this.config.name, ...this.config.getLoginData(data),
          };

          app.$store.dispatch(`session/${SOCIAL_LOGIN_USER_ACTION}`, param)
            .then((response) => {
              this.tracking(app, 'success', { login: true });
              resolve({
                response,
                data
              });
            })
            .catch((error) => {
              app.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_ACTION}`, { ...this.getUserData(data) })
                .then(() => {

                  notify({
                    type: 'info',
                    title: 'On ne se connaît pas (encore) !',
                    text: 'Vous n\'avez pas encore de compte Potager City avec cette adresse mail. <b>Inscrivez-vous pour continuer.</b>',
                  });
                })
                .catch((err) => console.error(err))
                .finally(() => {
                  reject({
                    error,
                    data
                  });
                });
            });
        })
        .catch((error) => {
          reject({ error });
        });
    });
  }

  register(app) {
    return new Promise((resolve, reject) => {
      this.login(app)
        .then(({ response }) => {
          resolve({
            mode: 'login',
            response,
            provider: this.config.name,
          });
        })
        .catch(({
          error,
          data
        }) => {
          if (error?.response?.status === 401) {
            app.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_ACTION}`, { ...this.getUserData(data) })
              .then((response) => {
                this.tracking(app, 'success', { subscription: true });
                resolve({
                  mode: 'register',
                  response,
                  provider: this.config.name,
                });
              })
              .catch((err) => reject(err));
          } else {
            reject(error);
          }
        });
    });
  }

  getUserData(data) {
    const { providerId } = data;
    const providersArray = data.user.providerData;
    const providerDatas = providersArray.find((provider) => provider.providerId === providerId);
    const providerFormattedId = providerId.split('.')[0] + 'Id';
    return {
      firstName: data.user.displayName.split(' ')[0],
      lastName: data.user.displayName.split(' ')[1],
      email: data.user.email,
      [providerFormattedId]: providerDatas.uid,
    };
  }
}
