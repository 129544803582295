<template>
  <flying-page>
    <div class="variety-detail">
      <transition name="fade-abs">
        <skeleton
          v-if="variety.loading"
          context="variety" />

        <div v-else>
          <potager-picture
            :src="variety.image"
            :title="variety.name"
            background
            class="variety-detail__poster" />

          <div class="variety-detail__content">
            <section class="variety-detail__section">
              <h1 class="variety-detail__title">
                {{ variety.title }}
              </h1>
            </section>

            <wordpress-article
              v-if="variety.article && variety.article.length"
              :sections="variety.article"
              class="variety-detail__section"
              is-small />

            <section class="variety-detail__section">
              <h2 class="variety-detail__subtitle">
                Conseils de conservation et préparation
              </h2>
              <div class="variety-detail__text">
                <span v-html="advice" />
              </div>
            </section>

            <section class="variety-detail__section">
              <calendar-product-seasons
                :seasons="variety.season"
                :title="variety.name ? variety.name : ''">
                <template #desc>
                  <p class="variety-detail__text">
                    Nous nous engageons à respecter les saisons de tous les produits en
                    vente sur notre site. Et puis de toute façon, c’est toujours meilleur !
                  </p>
                </template>
              </calendar-product-seasons>
            </section>

            <section class="variety-detail__section">
              <h2 class="variety-detail__subtitle">
                Caractéristiques - {{ variety.name }}
              </h2>
              <div class="variety-detail__text">
                <span v-html="description" />
              </div>
            </section>

            <section class="variety-detail__section">
              <h2 class="variety-detail__subtitle">
                Le saviez-vous ?
              </h2>
              <div class="variety-detail__text">
                <span v-html="anecdotes" />
              </div>
            </section>

            <section
              v-if="boxesRelated.length"
              class="variety-detail__section print:hidden">
              <h3 class="variety-detail__subtitle">
                Ce produit est disponible dans nos paniers cette semaine
              </h3>

              <potager-row>
                <potager-col
                  v-for="(boxRelated, index) in boxesRelated"
                  :key="index"
                  col="12">
                  <card-product
                    :box="boxRelated"
                    landscape />
                </potager-col>
              </potager-row>
            </section>

            <section class="variety-detail__section variety-detail__section--grey print:hidden">
              <social-media
                :facebook="facebook"
                :mail="mail"
                :pinterest="pinterest"
                :twitter="twitter" />
            </section>

            <upselling-slider flying />

            <associated-content-slider
              :items="varietiesRelated"
              :title="`Les autres variétés ${variety.season?.length <= 2 ? 'de saison' : ''}`" />

            <section
              v-if="!isSubscriptionActive"
              class="variety-detail__section print:hidden">
              <push-subscription condensed />
            </section>
          </div>
        </div>
      </transition>

      <micro-datas
        v-if="!variety.loading"
        :content="variety"
        context="product" />
    </div>
  </flying-page>
</template>

<script>
import FlyingPageMixin from 'Mixins/FlyingPageMixin';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import WordpressArticle from 'Components/article/WordpressArticle';
import CalendarProductSeasons from 'Components/calendarProductSeasons/CalendarProductSeasons';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { isEmpty } from 'Classes/utils/BasicUtils';
import { cleanWordpress } from 'Classes/utils/WordpressUtils';
import { getResolvedUrl, calcSlug } from 'Classes/utils/RouteUtils';

export default {

  mixins: [
    FlyingPageMixin,
  ],

  components: {
    PotagerPicture,
    PotagerRow,
    PotagerCol,
    WordpressArticle,
    CalendarProductSeasons,
  },

  data: () => ({
    variety: { loading: true },
  }),

  computed: {
    id() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    advice() {
      return cleanWordpress(this.variety.advice);
    },
    description() {
      return cleanWordpress(this.variety.description);
    },
    anecdotes() {
      return cleanWordpress(this.variety.anecdotes);
    },
    boxesRelated() {
      return this.getDashboardProducts.filter((e) => {
        if (!e.loading) {
          return e.products.filter((p) => p.id === this.id).length > 0;
        }
        return null;
      });
    },
    varietiesRelated() {
      return this.variety.linkedBySeasonProducts.map((e) => ({
        image: e.image,
        id: e.id,
        title: e.title || e.name,
        type: 'supplier-product',
      }));
    },
    mail() {
      return {
        id: this.variety.id,
        subject: this.variety.title,
        body: `Hello,\nViens découvrir ce produit sur Potager City :\n\n${this.canonical}`,
        url: this.canonical,
      };
    },
    twitter() {
      return {
        url: this.canonical,
        text: `${this.variety.title} - Potager City`,
      };
    },
    facebook() {
      return { url: this.canonical };
    },
    pinterest() {
      return {
        url: this.canonical,
        image: this.variety.image ? this.variety.image.url : null,
        description: `${this.variety.title} - Potager City`,
      };
    },
    canonical() {
      if (this.variety.id && this.variety.title) {
        return {
          name: 'produits',
          params: {
            slug: calcSlug(this.variety.title),
            id: this.variety.id,
          }
        };
      }
      return {
        name: 'products-list'
      };
    },
  },

  watch: {
    id() {
      this.getVariety();
    },
  },

  methods: {
    getVariety() {
      this.$api.supplierProducts.findOneById(this.id)
        .then((response) => {
          this.variety = response.data;
        })
        .catch(() => {
          this.$events.emit('404');
        });
    },
  },

  mounted() {
    this.getVariety();

    if (!this.isLoaded) this.fetchDashboard();
  },

  head() {
    let metaInfo = {};

    if (this.variety.id) {
      metaInfo = {
        title: this.variety.title,
        description: this.variety.description,
        image: this.variety.image ? this.variety.image.url : null,
        link: [{
          rel: 'canonical',
          href: this.canonical,
        }],
      };
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },
};
</script>
