import { mapGetters, mapMutations } from 'vuex';

import { DEFAULT_FOCUS_ZOOM_LEVEL } from 'Classes/utils/MapUtils';

import {
  UPDATE_MAP_CENTER,
  UPDATE_MAP_ZOOM,
  UPDATE_STORE_MARKER,
  UPDATE_WOOS_SCRIPT_LOADED
} from 'Stores/types/mapMutationsTypes';

export default {
  data() {
    return {
      userMarker: null,
      storeMarker: null,
      loadedResources: {
        multisearchWidget: false,
        map: false,
        style: false,
      },
    };
  },
  computed: {
    ...mapGetters('map', ['getWoosDlpList', 'getWoosMap', 'getUserAddress']), ...mapGetters('homeDelivery', ['getHomeDeliveryAddress',]),
  },
  methods: {
    ...mapMutations('map', {
      UPDATE_MAP_CENTER,
      UPDATE_MAP_ZOOM,
      UPDATE_STORE_MARKER,
    }),
    initializeWoosMap() {
      this.$store.commit(`map/${UPDATE_WOOS_SCRIPT_LOADED}`, false);

      return new Promise((resolve, reject) => {
        const loadResource = (url, resourceName, isStyle = false) => {
          return new Promise((resolve, reject) => {
            if (this.loadedResources[resourceName]) return resolve();

            const resource = document.createElement(isStyle ? 'link' : 'script');
            if (isStyle) {
              resource.rel = 'stylesheet';
              resource.href = url;
            } else {
              resource.src = url;
            }

            resource.onload = () => {
              this.loadedResources[resourceName] = true;
              resolve();
            };
            resource.onerror = () => {
              reject(new Error(`Failed to load the resource ${resourceName}`));
            };

            document.head.append(resource);
          });
        };

        const url1 = 'https://sdk.woosmap.com/multisearch/multisearch.js';
        const url2 = `https://sdk.woosmap.com/map/map.js?key=${import.meta.env.VITE_WOOS_MAP_KEY}`;
        const urlStyle = 'https://sdk.woosmap.com/localities/style.2.0.css';

        Promise.all([loadResource(url1, 'multisearchWidget'), loadResource(url2, 'map'), loadResource(urlStyle, 'style', true),])
          .then(() => {
            this.$store.commit(`map/${UPDATE_WOOS_SCRIPT_LOADED}`, true);
            resolve();
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      });
    },
    boundDlpS(animate = true) {
      if (!this.getWoosScriptIsLoaded || !this.getWoosDlpList || this.getWoosDlpList?.length === 0 || this.getSelectedDeliveryPoint) return;

      const bounds = new woosmap.map.LatLngBounds();
      const dlps = this.getWoosDlpList
        .filter((dlp) => dlp.properties.distance < 10000)
        .slice(0, 10)
        .concat(this.getWoosDlpList.slice(0, 5))
        .slice(0, 5);

      const dlpCoordinates = dlps.map((dlp) => dlp.geometry.coordinates);
      // on inclut dans le bounds les coordonnées de la recherche utilisateur
      if (this.getUserAddress) {
        dlpCoordinates.push([this.getUserAddress.geometry.location.lng, this.getUserAddress.geometry.location.lat]);
      }

      dlpCoordinates.forEach((coordinates) => bounds.extend(new woosmap.map.LatLng(coordinates[1], coordinates[0])));
      this.getWoosMap.fitBounds(bounds, { padding: 50 }, animate);
    },
    focusSelectedDeliveryPoint() {
      if (this.getSelectedDeliveryPoint) {
        const zoom = this.getWoosMap && this.getWoosMap.getZoom() < DEFAULT_FOCUS_ZOOM_LEVEL ? DEFAULT_FOCUS_ZOOM_LEVEL : undefined;
        this.UPDATE_MAP_ZOOM(zoom);
        const {
          lat,
          lng
        } = this.getSelectedDeliveryPoint?.isHomeDelivery ? this.getHomeDeliveryAddress : this.getSelectedDeliveryPoint;
        this.UPDATE_STORE_MARKER({
          lat,
          lng
        });
        this.UPDATE_MAP_CENTER({
          lat,
          lng
        });
      }
    },
    unFocusSelectedDeliveryPoint() {
      this.UPDATE_STORE_MARKER(null);
      this.boundDlpS();
    },
  },
};
