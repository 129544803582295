<template>
  <div
    v-if="order.timeSlot.hasSubstitute && order.timeSlot.note"
    class="panel-section panel-section--align-center bg-rossabel">
    <div class="panel-section__title text-cinnabar w-full justify-center min-h-min">
      Remarque
    </div>

    <div class="panel-section__p">
      {{ order.timeSlot.note }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountOrdersSubstituteSection',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
};
</script>
