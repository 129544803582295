<template>
  <div
    v-wave="isSelectable"
    :class="{
      'pm-selectable-item': true,
      'pm-selectable-item--selectable': isSelectable,
    }"
    @click="onClick">
    <div class="pm-selectable-item__content">
      <div>
        <p class="pm-selectable-item__type first-letter:uppercase">
          <slot name="type" />
        </p>

        <p
          v-if="$slots.icon || $slots.details"
          class="pm-selectable-item__details">
          <span
            v-if="$slots.icon"
            class="pm-selectable-item__details__icon">
            <slot name="icon" />
          </span>
          <slot
            v-if="$slots.details"
            name="details" />
        </p>

        <p
          v-if="$slots.readmore"
          class="pm-selectable-item__readmore">
          <slot name="readmore" />
        </p>
      </div>

      <potager-switch
        v-if="!!payment"
        v-model="value"
        :is-disabled="isDisabled"
        :is-loading="isLoading"
        class="pm-selectable-item__switch" />

      <span
        v-else-if="$slots.icon"
        class="pm-selectable-item__icon">
        <slot name="icon" />
      </span>
    </div>

    <p
      v-if="$slots.legend"
      class="pm-selectable-item__legend">
      <slot name="legend" />
    </p>
  </div>
</template>

<script>
import PotagerSwitch from 'UI/Form/PotagerSwitch';

export default {

  components: {
    PotagerSwitch,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    payment: {
      type: [Object],
      required: false,
      default: () => {
      },
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isSelectable() {
      return !!this.payment && !this.isLoading && !this.isDisabled;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        if (this.isSelectable) this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss">
.pm-selectable-item {
  padding: .875rem 1.375rem;
  border-radius: .25rem;
  border: solid 1px $porcelain;
  @apply text-sm.5;
  line-height: 1.25em;
  transition: border-color .3s;
  background: $white;
  @include disableTap();

  &--selectable {
    cursor: pointer;

    @include hover() {
      border-color: $warmGrey;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__type {
    font-weight: $weightBold;
    margin-bottom: .25em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__details {
    font-weight: $weightBold;
    color: $warmGrey;

    &__icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: .5rem;;
    }
  }

  &__icon {
    @apply text-xl;
    line-height: 0;
    margin: 0 .5rem;
  }

  &__switch {
    margin: 0;
  }

  &__legend {
    margin-top: .5rem;
    @apply text-xxs;
    line-height: 1.25em;
  }

  &--small {
    @apply text-xs.5;
    padding: .875rem .25rem .875rem .875rem;

    .pm-selectable-item__details__icon {
      margin-right: .25rem;
    }
  }

}
</style>
