<template>
  <div class="div bg-bianca rounded-xl px-6 py-8">
    <h2 class="font-black text-2xl text-british-racing mb-6">
      Partagez votre expérience
    </h2>

    <p class="mb-6">
      Pour faire connaître l’aventure Potager City aux personnes en recherche de bons produits frais & de saison,
      donnez votre avis sur les différentes plateformes !
    </p>

    <div class="flex justify-center items-center gap-3 bp425:flex-col">
      <a
        class="h-[55px] bg-white rounded-lg inline-flex justify-center items-center px-5 border border-porcelain w-max"
        href="https://www.google.com/search?sa=X&sca_esv=8a3ef2c58aed1d49&hl=fr-FR&biw=1454&bih=823&tbm=lcl&sxsrf=ACQVn09Tk4-VRiesTRWDgmKwfRYnRfMaAg:1711028771217&q=avis%20sur%20potager%20city%20lyon&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIytLS0NDE0NjI1MTAzBbItjTYwMr5ilEosyyxWKC4tUijIL0lMTy1SSM4sqVTIqczPW8SKRxIAN_5h7VMAAAA&rldimm=199941325406599992&ved=0CAcQ5foLahcKEwj4qYTWv4WFAxUAAAAAHQAAAAAQBQ#lkt=LocalPoiReviews&arid=ChdDSUhNMG9nS0VJQ0FnSUQ5MnBMOGhBRRAB"
        target="_blank">
        <potager-picture
          alt="Google"
          background-size="contain"
          class="h-[33px]"
          src="logo-google" />
      </a>

      <a
        class="h-[55px] bg-white rounded-lg inline-flex justify-center items-center px-5 border border-porcelain w-max"
        href="https://fr.trustpilot.com/review/www.potagercity.fr"
        target="_blank">
        <potager-picture
          alt="Trustpilot"
          background-size="contain"
          class="h-[33px]"
          src="logo-trustpilot" />
      </a>
    </div>
  </div>
</template>

<script>
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'NpsThanksPage',
  components: { PotagerPicture },
};
</script>
