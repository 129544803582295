<template>
  <base-input
    ref="input"
    v-model="value"
    v-bind="$props"
    @change="$emit('change', $event)"
    @onBlur="$emit('onBlur', $event)"
    @onEnter="$emit('onEnter', $event)"
    @onError="$emit('onError', $event)"
    @onFocus="$emit('onFocus', $event)"
    @onKeyDown="$emit('onKeyDown', $event)"
    @onKeyUp="$emit('onKeyUp', $event)"
    @onPrefixClick="$emit('onPrefixClick', $event)"
    @onReset="$emit('onReset', $event)"
    @onSubmit="$emit('onSubmit', $event)">
    <template #input>
      <select
        :id="name"
        ref="input"
        v-model="value"
        :class="getSelectClasses"
        :disabled="disabled"
        :multiple="!!multiple"
        @blur="expanded = false"
        @focus="expanded = true">
        <option
          v-for="option in options"
          :key="typeof option === 'object' ? option.value : option"
          :disabled="option?.disabled"
          :value="typeof option === 'object' ? option.value : option">
          {{ typeof option === 'object' ? option.label : option }}
        </option>
      </select>
    </template>

    <template #icon>
      <slot name="icon" />
    </template>

    <template #innerInput>
      <potager-button
        :class="[
          'text-xxs text-charcoal absolute right-2 top-2 pointer-events-none',
        ]"
        is-square
        size="small"
        theme="white">
        <template #icon>
          <icon-arrow-down1 />
        </template>
      </potager-button>
    </template>
  </base-input>
</template>

<script>

import { propsBuilder } from 'UI/Tools';
import PotagerButton from 'UI/PotagerButton';
import InputMixin from 'UI/Form/Input/InputMixin';
import BaseInput from 'UI/Form/Input/BaseInput';

import IconArrowDown1 from 'Icons/bold/IconArrowDown1';

export const props = {
  multiple: {
    type: Boolean,
    default: false,
  },
};

export default {
  name: 'SelectInput',
  props: propsBuilder(props),
  emits: ['change', 'onBlur', 'onEnter', 'onError', 'onFocus', 'onKeyDown', 'onKeyUp', 'onPrefixClick', 'onReset', 'onSubmit'],
  data() {
    return {
      expanded: false,
    };
  },
  components: {
    BaseInput,
    PotagerButton,
    IconArrowDown1
  },
  mixins: [InputMixin],
  computed: {
    getSelectClasses() {
      return [
        'pr-8',
        { 'text-warm-grey': !this.value },
        { 'h-12 overflow-hidden': !!this.multiple && !this.expanded },
        { 'select-none': !this.multiple },
        ...this.getClasses,
      ];
    },
  },
};
</script>
