<template>
  <span>
    <component
      :is="icon.component"
      v-for="(icon, index) in icons"
      :key="`${icon.PM.name}-${index}`"
      :class="[
        'inline-block align-middle mx-2',
        `text-${icon.PM.name}`,
      ]"
      :style="{ color: icon.PM.colors?.primary }" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import { PaymentMethods, PaymentMethodsArray } from 'Classes/payment-methods';
import { PaymentMethodIntegration, PaymentMethodType } from 'PotagerLogic/Enums/PaymentMethods';

export default {
  props: {
    types: {
      type: Array,
      required: false,
      default: () => [PaymentMethodType.BankCard],
      validator: (val) => {
        const types = Object.values(PaymentMethodType);
        return val.every((type) => types.includes(type));
      },
    },
    alternativeIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getPaymentGateway',
    ]),
    icons() {
      const iconsAttr = this.alternativeIcons ? 'alternativeIcons' : 'icons';

      // Déterminer les PM à filtrer en fonction de la gateway
      const filteredGateway = this.getPaymentGateway === PaymentMethodIntegration.Paygreen
        ? PaymentMethods.Stripe.name
        : PaymentMethods.PaygreenBankCard.name;

      // Transformer chaque type de paiement en une liste d'icônes associées.
      return this.types.flatMap(type => PaymentMethodsArray
        .filter((PM) => PM.type === type)
        .flatMap((PM) => {
          const icons = PM[iconsAttr] || []; // Assurer que 'icons' n'est jamais 'undefined'.
          return icons.map((icon) => ({
            PM,
            component: icon
          }));
        }))
        // Filtrer les icônes en fonction de la gateway
        .filter((icon) => icon.PM.name !== filteredGateway);
    },
  },
};
</script>
