<template>
  <panel
    :back-route="getBackRoute"
    title="Votre adresse">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        😎&nbsp;La livraison à domicile est disponible dans votre ville.
      </p>
    </template>

    <div class="bg-bianca px-6">
      <dlp-form-home
        ref="addressForm"
        :back-route="getBackRoute"
        :context="context"
        in-panel
        @onSubmit="onSubmit($event)" />
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import { getDlpDetailsRoute, getDlpSearchRoute } from 'Classes/utils/DlpRouteUtils';
import { DELIVERY_HOME_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import Panel from 'Components/panel/Panel';
import DlpFormHome from 'Components/deliveryPoint/step3/DlpFormHome';

export default {
  components: {
    Panel,
    DlpFormHome,
  },

  props: {
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('homeDelivery', [
      'getDeliveryPoint',
    ]),
    getBackRoute() {
      return getDlpSearchRoute({
        context: this.context,
        currentRoute: this.$potagerRoute
      });
    },
  },

  methods: {
    onSubmit() {
      this.$router.push(getDlpDetailsRoute({
        context: this.context,
        type: DELIVERY_HOME_TYPE,
        deliveryPoint: this.getDeliveryPoint,
        currentRoute: this.$potagerRoute
      }));
    },
  },
};
</script>
