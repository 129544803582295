<template>
  <potager-form
    id="registration-form"
    ref="form"
    :models="[
      lastName,
      firstName,
      email,
      password,
      phone,
      optinRgpd,
      optinCompositionBox,
      optinPtcyContent,
      optinPartnerContent,
      optinPtcyContentSms,
    ]"
    @canSubmit="(e) => canSubmit = e"
    @onSubmit="onSubmit">
    <potager-input
      v-model="lastName"
      :constraints="[
        { constraint: 'required', field: 'lastName' },
        {
          constraint: 'regex',
          field: 'lastName',
          regex: /^[^@\.]+$/,
          message: 'Veuillez saisir un nom valide',
        },
      ]"
      external-label
      label="Nom*"
      name="lastName"
      placeholder=" " />

    <potager-input
      v-model="firstName"
      :constraints="[
        { constraint: 'required', field: 'firstName' },
        {
          constraint: 'regex',
          field: 'firstName',
          regex: /^[^@\.]+$/,
          message: 'Veuillez saisir  un prénom valide',
        },
      ]"
      external-label
      label="Prénom*"
      name="firstName"
      placeholder=" " />

    <template v-if="!getProviderName">
      <potager-input
        ref="email"
        v-model="email"
        :constraints="[
          { constraint: 'required', field: 'emailConnexion' },
          { constraint: 'email', field: 'emailConnexion' },
        ]"
        :readonly="!editableEmail"
        external-label
        label="Adresse email*"
        name="emailConnexion"
        placeholder=" "
        truncate
        type="email"
        @click="!editableEmail && $emit('onEmailClick')">
        <template #action>
          <potager-button
            v-if="!editableEmail"
            is-square
            size="small"
            tabindex="-1"
            theme="white-rock"
            type="button">
            <template #icon>
              <icon-pencil2 class="text-sm" />
            </template>
          </potager-button>
        </template>
      </potager-input>

      <potager-input
        v-model="password"
        :constraints="[{ constraint: 'required', field: 'password' }]"
        external-label
        label="Mot de passe*"
        name="password"
        placeholder=" "
        type="password" />
    </template>

    <potager-input
      v-model="phone"
      :constraints="[{ constraint: 'phone', field: 'phone' }]"
      external-label
      label="Téléphone portable"
      name="phone"
      placeholder=" "
      type="tel" />

    <div class="optins mt-6 text-left">
      <p class="mb-2 text-xs.5">
        <b> Je souhaite recevoir&nbsp;: </b>
      </p>

      <p
        class="pb-4 mb-2 mb-4 border-b border-0 border-white-rock border-solid">
        <potager-checkbox
          v-model="optinCompositionBox"
          group-name="optin-ptcy-composition-box"
          pulse>
          la composition hebdomadaire des paniers et de la boutique
        </potager-checkbox>
      </p>

      <p class="mb-2 text-xs.5">
        <b> …des informations et offres&nbsp;: </b>
      </p>

      <p class="mb-2">
        <potager-checkbox
          v-model="optinPtcyContent"
          group-name="optin-ptcy-content-email">
          par email ou notification de notre part
        </potager-checkbox>
      </p>

      <p class="mb-2">
        <potager-checkbox
          v-model="optinPtcyContentSms"
          :disabled="!phone"
          group-name="optin-ptcy-content-sms">
          par SMS de notre part
        </potager-checkbox>
      </p>

      <p class="pb-4 mb-4 border-b border-0 border-white-rock border-solid">
        <potager-checkbox
          v-model="optinPartnerContent"
          group-name="optin-partner-content-email">
          par email ou notification de nos partenaires
        </potager-checkbox>
      </p>

      <p class="mb-2 font-bold">
        <potager-checkbox
          v-model="optinRgpd"
          :constraints="[{ constraint: 'required', field: 'optin-rgpd' }]"
          group-name="optin-rgpd">
          J’accepte
          <potager-link
            :to="{ name: 'confidentialite-et-donnees-personnelles' }"
            class="underline text-forest-green"
            target="_blank">
            la politique de protection des données personnelles
          </potager-link>
          et
          <potager-link
            :to="{ name: 'cgv' }"
            class="underline text-forest-green"
            target="_blank">
            les CGV </potager-link>*
        </potager-checkbox>
      </p>
    </div>

    <potager-button
      :disabled="!canSubmit"
      :is-loading="isLoading"
      class="mt-4 mb-6"
      full-width
      theme="go-green"
      type="submit">
      Créer mon compte
    </potager-button>
  </potager-form>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_ACTION,
} from 'Stores/types/temporaryCustomerActionsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';

import IconPencil2 from 'Icons/bold/IconPencil2';

import Providers from 'Classes/social-providers/Providers';
import { capitalize } from 'PotagerLogic/Formatting';

import GtmMixin from 'Mixins/GtmMixin';

const generateRandomPassword = () => Array(64)
  .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
  .map((x) => x[Math.floor(Math.random() * x.length)])
  .join('');

export default {
  props: {
    editableEmail: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerCheckbox,
    PotagerForm,
    PotagerInput,
    PotagerButton,
    IconPencil2,
  },
  emits: ['onSuccess'],

  data: () => ({
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    phone: undefined,
    password: undefined,
    optinRgpd: false,
    optinCompositionBox: false,
    optinPtcyContent: false,
    optinPartnerContent: false,
    optinPtcyContentSms: false,
    canSubmit: false,
  }),

  computed: {
    ...mapGetters('temporaryCustomer', [
      'getEmail',
      'getLastName',
      'getFirstName',
      'getPhone',
      'getSponsorshipCode',
      ...Providers.map((e) => `get${capitalize(e.config.name)}Id`),
    ]),
    ...mapGetters('homeDelivery', [
      'getHomeDeliveryAddressFirstName',
      'getHomeDeliveryAddressLastName',
      'getHomeDeliveryAddressPhone',
    ]),
    getOptins() {
      return {
        optinCommunicationAppMobile: this.optinPtcyContent,
        optinCommunicationCampaign: this.optinPtcyContent,
        optinCommunicationCampaignSms: this.optinPtcyContentSms,
        optinCompositionBoxEmail: this.optinCompositionBox,
        optinCompositionBoxPushnotif: this.optinCompositionBox,
        optinGazetteEmail: this.optinPtcyContent,
        optinGazettePushnotif: this.optinPtcyContent,
        optinOrderShippedPushnotif: this.optinPtcyContent,
        optinOrderValidatedPushnotif: this.optinPtcyContent,
        optinPartnership: this.optinPartnerContent,
        optinPartnershipPushnotif: this.optinPartnerContent,
        optinRappelOrderEmail: this.optinPtcyContent,
        optinRappelOrderPushnotif: this.optinPtcyContent,
      };
    },
    isLoading() {
      return (
        this.$wait.is(UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION)
        || this.$wait.is(UPDATE_TEMPORARY_CUSTOMER_ACTION)
      );
    },
    getPassword() {
      return (
        this.password || (this.getProviderName ? generateRandomPassword() : null)
      );
    },
    getProviderName() {
      return this.$route.params?.provider;
    },
  },

  watch: {
    phone(phone) {
      // manage case of =
      // 1. add a phone number
      // 2. check the "sms" optin
      // 3. delete the phone number
      // > in this case uncheck the "sms" optin
      if (!phone) {
        this.optinPtcyContentSms = false;
      }
    },
  },

  methods: {
    onSubmit() {
      if (this.editableEmail) {
        this.checkEmail();
      } else {
        this.submitForm();
      }
    },
    checkEmail() {
      const { email } = this;
      const sponsorshipCode = this.getSponsorshipCode;

      this.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_ACTION}`, {
        email,
        sponsorshipCode
      })
        .then(() => {
          this.$api.login.checkIfEmailExist(email)
            .then(() => {
              this.$notify({
                type: 'error',
                title: 'Cette adresse email est déjà utilisée',
              });

              this.$emit('onEmailAlreadyExist');
            })
            .catch(() => {
              this.submitForm();
            });
        });
    },
    submitForm() {
      const sponsorshipCode = this.getSponsorshipCode;
      const optins = this.getOptins;
      const password = this.getPassword;
      const {
        firstName,
        lastName,
        phone,
        email,
      } = this;

      const param = {
        email,
        firstName,
        lastName,
        phone,
        password,
        optins,
        sponsorshipCode,
      };

      if (this.getProviderName) {
        param[`${this.getProviderName}Id`] = this[`get${capitalize(this.getProviderName)}Id`];
      }

      this.$store
        .dispatch(
          `temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_SEND_MAIL_ACTION}`,
          param,
        )
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Bienvenue chez Potager City !',
            text: 'Votre compte a bien été créé.',
          });

          this.$emit('onSuccess');
        });
    },
  },

  mounted() {
    this.email = this.getEmail || undefined;
    this.firstName = this.getFirstName || this.getHomeDeliveryAddressFirstName || undefined;
    this.lastName = this.getLastName || this.getHomeDeliveryAddressLastName || undefined;
    this.phone = this.getPhone || this.getHomeDeliveryAddressPhone || undefined;
  },
};
</script>
