<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    title="Coupons">
    <template #before>
      <status-banner type="warning">
        <span v-html="WORDINGS.COUPONS.INFORMATION_BANNER" />
      </status-banner>
    </template>

    <template #header>
      <coupon-input :context="Context.User" />
    </template>

    <div class="panel-section panel-section--transparent mt-0 mb-10">
      <div class="panel-section__separator m-0" />
    </div>

    <coupon-selector
      :context="Context.User" />

    <coupon-automated-resume class="mt-8" />
  </panel>
</template>

<script>
import WORDINGS from 'PotagerLogic/Constants/Wordings';
import { Context } from 'PotagerLogic/Enums/Context';

import Panel from 'Components/panel/Panel';
import StatusBanner from 'Components/statusBanner/StatusBanner';
import CouponInput from 'Components/coupons/CouponInput';
import CouponSelector from 'Components/coupons/CouponSelector';
import CouponAutomatedResume from 'Components/coupons/CouponAutomatedResume';

import MetaInfosService from 'Classes/services/MetaInfoService';

export default {
  components: {
    CouponAutomatedResume,
    CouponSelector,
    CouponInput,
    StatusBanner,
    Panel,
  },

  data() {
    return {
      WORDINGS,
      Context,
    };
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),
};
</script>
