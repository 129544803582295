import BoxGtmAdapter from './BoxGtmAdapter';

export default function beginCheckoutGtmData(store) {
  const subscriptionBasketProduct = store.getters['subscriptionBasket/getCustomerSubscriptionProduct'];
  const products = subscriptionBasketProduct ? [subscriptionBasketProduct] : store.getters['basket/getProducts'];
  // if similar products are in the products, we need to merge them
  // with the quantity attribut of all similar products
  const productsMap = products
    .reduce((map, product) => {
      const { id } = product;
      const existingProduct = map.find((p) => p.id === id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        map.push({
          ...product,
          quantity: 1
        });
      }
      return map;
    }, []);
  const total = subscriptionBasketProduct ? store.getters['subscriptionBasket/getTotal'] : store.getters['basket/getTotal'];
  const coupons = store.getters['basket/getBasketCoupons'];
  const coupon = coupons ? coupons.map((c) => c.couponCode)
    .join(', ') : coupons;
  return {
    event: 'begin_checkout',
    currency: 'EUR',
    value: parseFloat(total),
    coupon,
    items: productsMap.map((box) => new BoxGtmAdapter(store, { box })),
  };
}
