<template>
  <basket-subscription-page v-if="isUserLoaded && !canRestoreSubscription && !isSubscriptionActive" />

  <panel
    v-else
    :back-route="{ name: 'mon-compte' }"
    title="Mon abonnement">
    <template v-if="isUserLoaded">
      <template v-if="isClosedStoreAndRegion">
        <div class="panel-section">
          <p class="panel-section__title">
            Abonnement non disponible
          </p>

          <p class="panel-section__p mb-0">
            L'abonnement n'est pas disponible dans votre région.
          </p>
        </div>
      </template>

      <template v-else-if="isSubscriptionActive">
        <div class="text-center">
          <div
            class="inline-flex items-center bg-white py-3 5 px-4 rounded-full text-go-green mx-auto mb-10 font-semibold text-xs.5">
            <icon-check-circle1 class="text-lg mr-2" />
            Activé
          </div>
        </div>

        <div class="panel-section">
          <p class="panel-section__title">
            Mon panier

            <potager-button
              v-gtm
              :to="{ name: 'tunnel-box-slider' }"
              is-rounded
              size="small"
              theme="stroke">
              Modifier
            </potager-button>
          </p>

          <card-product
            :box="getSubscription.product"
            class="panel-section__line"
            landscape
            read-only />
        </div>

        <dlp-resume
          :context="Context.Subscription">
          <div class="panel-section__title">
            Livraison
          </div>
        </dlp-resume>

        <subscriber-benefits-section />

        <div class="panel-section--transparent">
          <div class="panel-section__separator my-10" />

          <div class="text-xl text-center font-bold mb-8">
            Livraisons à venir
          </div>

          <orders-time-slots-list />
        </div>

        <div class="flex justify-center">
          <potager-button
            v-gtm
            :to="{ name: 'mon-compte_subscription_suspend_form' }"
            is-rounded
            theme="british-racing">
            Me désabonner
          </potager-button>
        </div>
      </template>

      <template v-else>
        <subscriber-benefits-section />

        <dlp-resume
          :context="Context.Subscription"
          :delivery-point="getSubscription.deliveryPoint"
          readonly>
          <p class="panel-section__title">
            Abonnement précédent

            <potager-badge
              rounded
              size="medium"
              theme="rossabel">
              Désactivé
            </potager-badge>
          </p>

          <panel-section-navigator>
            <template #left>
              <icon-shopping-bag />
            </template>

            <span class="text-xs.5">
              {{ getSubscription.product.name }}
            </span>
          </panel-section-navigator>
        </dlp-resume>

        <div class="text-center">
          <potager-button
            v-gtm
            :is-loading="$wait.is(RESTORE_SUBSCRIPTION_LOADER)"
            :to="!canRestoreSubscription ? { name: 'tunnel-box-slider' } : undefined"
            is-rounded
            theme="british-racing"
            @onClick="canRestoreSubscription ? restoreSubscription() : undefined">
            Réactiver mon abonnement
          </potager-button>
        </div>
      </template>
    </template>

    <div
      v-else
      class="panel__loader">
      <icon-ptcy-loader-dots />
    </div>

    <template
      v-if="isUserLoaded && !isClosedStoreAndRegion && !isSubscriptionActive"
      #footer>
      <potager-button
        v-gtm
        :to="{ name: 'tunnel-box-slider' }"
        full-width
        theme="go-green">
        Choisir mon abonnement
      </potager-button>
    </template>
  </panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_SUBSCRIPTION_TIME_SLOTS_ACTION } from 'Stores/types/userActionsTypes';

import BasketSubscriptionPage from 'Pages/basket/BasketSubscriptionPage';

import PotagerButton from 'UI/PotagerButton';
import PotagerBadge from 'UI/PotagerBadge';

import Panel from 'Components/panel/Panel';
import DlpResume from 'Components/deliveryPoint/DlpResume';
import OrdersTimeSlotsList from 'Components/timeSlots/OrdersTimeSlotsList';
import CardProduct from 'Components/cards/CardProduct';
import SubscriberBenefitsSection from 'Components/subscriberBenefits/SubscriberBenefitsSection';
import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';
import { Context } from 'PotagerLogic/Enums/Context';

import MetaInfosService from 'Classes/services/MetaInfoService';

import IconPtcyLoaderDots from 'Icons/regular/IconPtcyLoaderDots';
import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';
import IconShoppingBag from 'Icons/bold/IconShoppingBag';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';
import { PAY_SUBSCRIPTION_BASKET } from 'Stores/types/subscriptionBasketActionsTypes';
import TunnelSubscriptionMixin from 'Mixins/TunnelSubscriptionMixin';

export const RESTORE_SUBSCRIPTION_LOADER = 'RESTORE_SUBSCRIPTION_LOADER';

export default {
  mixins: [
    PaymentMethodsMixin,
    TunnelSubscriptionMixin,
  ],
  components: {
    BasketSubscriptionPage,
    CardProduct,
    DlpResume,
    IconCheckCircle1,
    IconPtcyLoaderDots,
    IconShoppingBag,
    OrdersTimeSlotsList,
    Panel,
    PanelSectionNavigator,
    PotagerBadge,
    PotagerButton,
    SubscriberBenefitsSection,
  },

  data() {
    return {
      Context,
      RESTORE_SUBSCRIPTION_LOADER,
    };
  },

  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'isSubscriptionActive',
      'getSubscription',
    ]),
    ...mapGetters('dashboard', [
      'isClosedStoreAndRegion'
    ]),
    canRestoreSubscription() {
      return this.getSubscription.product
        && this.getSubscription.groupDeliveryDay
        && this.paymentMethods?.primary?.payment_id
        && !this.isClosedStoreAndRegion;
    },
  },

  methods: {
    ...mapActions('user', {
      getSubscriptionTimeSlots: GET_SUBSCRIPTION_TIME_SLOTS_ACTION,
    }),
    tmsToFormat,
    restoreSubscription() {
      this.$wait.start(RESTORE_SUBSCRIPTION_LOADER);
      const onError = () => {
        this.$wait.end(RESTORE_SUBSCRIPTION_LOADER);
        this.$notify({
          type: 'error',
          title: 'Échec de la réactivation',
          text: 'Une erreur est survenue lors de la réactivation de votre abonnement',
        });
        this.$router.push({ name: 'tunnel-box-slider' });
      };

      this.updateSubscriptionBasket(this.getSubscription.product)
        .then(() => {
          this.$store.dispatch(`subscriptionBasket/${PAY_SUBSCRIPTION_BASKET}`, this.paymentMethods?.primary?.payment_id)
            .then((resp) => {
              if (resp.success) {
                this.$notify({
                  type: 'success',
                  title: 'Abonnement réactivé',
                  text: 'Votre abonnement a bien été réactivé',
                });
              } else {
                console.error(resp);
                onError();
              }
            })
            .finally(() => {
              this.$wait.end(RESTORE_SUBSCRIPTION_LOADER);
            });
        })
        .catch((err) => {
          console.error(err);
          onError();
        });
    },
  },

  created() {
    this.getSubscriptionTimeSlots();
  },

  head: MetaInfosService.generate({
    title: 'Abonnement',
  }),
};
</script>
