<template>
  <template v-if="inline">
    <div
      v-for="(note) in [deliveryNote, genericNote]?.filter(Boolean)"
      :key="note?.title"
      :class="[
        'panel-section__line font-medium pt-5 flex items-center gap-3',
        {
          'bg-rossabel': [MessageType.Warning, MessageType.Error].includes(note.type),
          'bg-beryl-green': note.type === MessageType.Success,
          'bg-white-rock': ![MessageType.Error, MessageType.Warning, MessageType.Success].includes(note.type)
        }
      ]">
      <div
        :class="[
          'text-2xl',
          { 'text-cinnabar': note.type === MessageType.Warning },
        ]">
        <icon-information-circle />
      </div>

      <div>
        <p
          class="font-bold"
          v-html="note.title" />
        <p v-html="note.content" />
      </div>
    </div>
  </template>

  <template v-else>
    <div class="panel-section text-center">
      <div
        v-for="note in [deliveryNote, genericNote].filter(Boolean)"
        :key="note?.title"
        :class="[
          'panel-section__line',
          {
            'bg-rossabel': note.type === MessageType.Warning,
            'bg-beryl-green': note.type === MessageType.Success,
            'bg-white-rock': ![MessageType.Error, MessageType.Warning, MessageType.Success].includes(note.type)
          }]">
        <p
          class="text-sm.5 font-bold"
          v-html="note.title" />

        <p v-html="note.content" />

        <potager-button
          v-if="order"
          :to="{ name: 'mon-compte_orders_detail', params: { orderId: order.id } }"
          class="mt-3"
          is-rounded
          size="small"
          theme="stroke">
          Voir ma commande
        </potager-button>
      </div>
    </div>
  </template>
</template>

<script>
import { MessageType } from 'PotagerLogic/Enums/Messages';

import IconInformationCircle from 'Icons/regular/IconInformationCircle';
import PotagerButton from 'UI/PotagerButton.vue';

export default {
  name: 'TimeSlotNote',

  components: {
    PotagerButton,
    IconInformationCircle,
  },

  props: {
    timeSlot: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      default: undefined,
    },
    onlyDeliveryNote: {
      type: Boolean,
      default: false,
    },
    onlyGenericNote: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      MessageType,
    };
  },

  computed: {
    genericNote() {
      return !this.onlyDeliveryNote && this.timeSlot?.note ? {
        title: 'Remarque',
        content: this.timeSlot?.note,
        type: MessageType.Info,
      } : undefined;
    },
    deliveryNote() {
      return !this.onlyGenericNote && this.timeSlot?.deliveryNote;
    }
  },
};
</script>
