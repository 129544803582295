<template>
  <potager-form
    class="nps-reason"
    @onSubmit="onSubmit">
    <potager-input
      v-model="reason"
      external-label
      label="Facultatif mais (très) apprécié, votre avis compte ! "
      name="nps-reason"
      placeholder="Mon avis en quelques mots"
      textarea />

    <potager-button
      :is-loading="isLoading"
      theme="forest"
      type="submit">
      Valider
    </potager-button>
  </potager-form>
</template>

<script>
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';

import { SEND_NPS } from '../NpsSurveyPage';

export default {
  name: 'NpsNotes',
  components: {
    PotagerInput,
    PotagerButton,
    PotagerForm,
  },
  emits: ['onSubmit'],
  data() {
    return {
      reason: undefined,
    };
  },
  computed: {
    isLoading() {
      return this.$wait.is(SEND_NPS);
    },
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit', { reason: this.reason });
    },
  },
};
</script>
