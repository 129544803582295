<template>
  <potager-button
    :is-circle="$mq.bp640"
    :to="{ name: 'basket' }"
    is-rounded
    theme="beryl-green">
    <template #icon>
      <icon-shopping-bag />

      <potager-badge
        v-if="($mq.bp640 && getProducts?.length) || (subTotal <= 0 && getProducts?.length > 0)"
        class="absolute top-0 -right-2"
        rounded
        size="mini"
        theme="british-racing">
        {{ getProducts?.length }}
      </potager-badge>
    </template>

    <transition name="fade-fast">
      <span v-if="subTotal > 0">
        {{ formatPrice(subTotal) }}
      </span>
      <span v-else>
        Panier
      </span>
    </transition>
  </potager-button>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';
import PotagerBadge from 'UI/PotagerBadge';

import IconShoppingBag from 'Icons/bold/IconShoppingBag';
import { formatPrice } from 'PotagerLogic/Formatting';

export default {
  name: 'BasketButton',
  components: {
    PotagerBadge,
    PotagerButton,
    IconShoppingBag,
  },
  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('basket', [
      'getCouponsTotal',
      'getSubTotal',
      'getProducts',
    ]),
    subTotal() {
      return this.getSubTotal - this.getCouponsTotal;
    },
  },
  methods: {
    formatPrice,
  },
};
</script>
