<template>
  <potager-form
    class="nps-notes"
    @onSubmit="onSubmit">
    <div class="flex mb-3">
      <potager-radio
        v-for="npsValue in 10"
        :key="`nps-${npsValue}`"
        :class="[
          'nps-notes__radio',
          'py-3 rounded font-bold mr-1 last:mr-0 mt-0 relative flex-1',
          {
            'bg-light-red': npsValue < 7,
            'bg-yellow-crayola': npsValue >= 7 && npsValue < 9,
            'bg-beryl-green': npsValue >= 9,
          }
        ]"
        :disabled="isLoading"
        :label="`Notez ${npsValue}`"
        :model-value="npsValue === nps"
        :name="`nps-${npsValue}`"
        @update:modelValue="nps = npsValue">
        {{ npsValue }}
      </potager-radio>
    </div>

    <div class="flex justify-between items-center w-full mb-6">
      <div class="flex items-center font-bold text-xs">
        <icon-smiley-sad class="text-lg text-maximum-red mr-2" />
        1 - Peu probable
      </div>

      <div class="flex items-center font-bold text-xs">
        10 - Très probable
        <icon-smiley-happy class="text-lg text-go-green ml-2" />
      </div>
    </div>

    <potager-button
      :disabled="!nps"
      :is-loading="isLoading"
      theme="forest"
      type="submit">
      Valider
    </potager-button>
  </potager-form>
</template>

<script>
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerButton from 'UI/PotagerButton';
import PotagerRadio from 'UI/Form/PotagerRadio';

import IconSmileyHappy from 'Icons/bold/IconSmileyHappy';
import IconSmileySad from 'Icons/bold/IconSmileySad';

import { SEND_NPS } from '../NpsSurveyPage';

export default {
  name: 'NpsNotes',
  components: {
    PotagerRadio,
    PotagerButton,
    PotagerForm,
    IconSmileyHappy,
    IconSmileySad,
  },
  emits: ['onSubmit'],
  data() {
    return {
      nps: undefined,
    };
  },
  computed: {
    isLoading() {
      return this.$wait.is(SEND_NPS);
    },
  },
  methods: {
    onSubmit() {
      this.$emit('onSubmit', { nps: this.nps });
    },
  },
};
</script>

<style lang="scss" scoped>
.nps-notes {
  $this: &;

  &__radio {
    @include outlineFocus();

    > :deep() * {
      @apply flex-col items-center;
    }

    :deep().potager-checkbox {
      &__input-wrapper {
        @apply mr-0 mb-1.5;
        width: 1rem;
        height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        min-width: 1rem;
        min-height: 1rem;
      }

      &__label {
        @apply after:absolute after:inset-0;
      }
    }
  }
}
</style>
