<template>
  <tunnel-body class="text-center">
    <h1 class="mb-6 text-xl font-extrabold">
      Félicitations
    </h1>

    <template v-if="isSubscriptionActive">
      <p class="font-bold">
        👍&nbsp;Votre abonnement est activé&nbsp;!
      </p>

      <p class="mb-3">
        Votre première commande a été créée et sera renouvelée chaque semaine.
      </p>
    </template>

    <template v-else>
      <p class="font-bold">
        👍&nbsp;Votre compte a bien été créé&nbsp;!
      </p>

      <p class="mb-3">
        Vous pouvez accéder à notre boutique.
      </p>
    </template>

    <potager-button
      :to="getNextStepRoute()"
      class="mx-auto mb-10"
      theme="go-green">
      Découvrir la boutique
    </potager-button>

    <template v-if="!isInAppWebView">
      <div class="mb-6 text-center">
        <p :class="['text-lg font-bold mb-2']">
          Testez notre application
        </p>

        <p class="mb-6">
          Créez, complétez puis suivez l’état de vos commandes depuis votre smartphone.
        </p>

        <potager-app-button
          class="inline-block"
          store="apple"
          theme="stroke" />

        <potager-app-button
          class="inline-block"
          store="google"
          theme="stroke" />
      </div>
    </template>

    <potager-picture
      class="tunnel-success__phone w-full mx-auto -mb-8"
      src="phone-dashboard.png" />
  </tunnel-body>
</template>

<script>
import MobileAppMixin from 'Mixins/MobileAppMixin';
import GtmMixin from 'Mixins/GtmMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerAppButton from 'UI/PotagerAppButton';
import PotagerPicture from 'UI/PotagerPicture';

import TunnelBody from 'Components/tunnel/TunnelBody';

import MetaInfosService from 'Classes/services/MetaInfoService';

import { mapGetters } from 'vuex';
import { inAppWebView } from 'Classes/ConfigurationManager';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  mixins: [
    MobileAppMixin,
    GtmMixin,
  ],

  components: {
    PotagerPicture,
    PotagerButton,
    PotagerAppButton,
    TunnelBody,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getOrderCardId',
    ]),
    ...mapGetters('user', [
      'getUser',
      'getSubscription',
      'getSubscriptionOrder',
      'isSubscriptionActive',
    ]),
    isInAppWebView() {
      return inAppWebView();
    },
  },

  mounted() {
    this.trackPurchaseSuccess(this.getSubscriptionOrder, this.getUser, this.getSubscription, this.getOrderCardId);
  },

  head: MetaInfosService.generate({
    title: 'Votre abonnement a été activé',
  }),
};
</script>

<style lang="scss" scoped>
.tunnel-success {
  &__phone {
    max-width: 375px;
  }
}
</style>
