<template>
  <div class="panel-section--transparent mb-4 text-center">
    <div
      v-if="!isUserLoaded && !isTemporaryCustomerLoaded"
      class="bg-white-rock animate-pulse h-[36px] mb-3" />

    <template v-else>
      <b v-if="context === Context.User">
        {{
          getCoupons?.length
            ? pluralize(getCoupons?.length, 'code promo associé', 'codes promos associés')
            : 'Aucun code promo associé'
        }} à votre compte
      </b>

      <b v-else>
        {{
          getContextCoupons?.length
            ? pluralize(getContextCoupons?.length, 'code promo ajouté', 'codes promos ajoutés')
            : 'Aucun code promo ajouté'
        }}
      </b>
    </template>
    <slot name="header" />
  </div>

  <ul class="coupon-selector">
    <template v-for="(group, i) in getGroupedCoupons">
      <coupon-checkbox
        v-for="coupon in group.coupons"
        :key="`${i}-${coupon.id}-${resetKey}`"
        :checkable="context !== Context.User"
        :coupon="coupon"
        :is-checked="coupon.isChecked"
        :is-disabled="isCouponDisabled(coupon)"
        :is-loading="isCouponLoading(coupon)"
        @onChecked="onChecked" />
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_ORDER_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_ORDER_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
} from 'Stores/types/userActionsTypes';

import CouponCheckbox from 'Components/coupons/CouponCheckbox';

import { Context, SubscriptionContexts } from 'PotagerLogic/Enums/Context';
import { getCouponsGroupedByType, getForbiddenTypes } from 'potagerlogic/src/Utils/Coupon/CouponHelpers';
import { CouponType, CouponValueType } from 'PotagerLogic/Enums/Coupons';
import { pluralize } from 'PotagerLogic/Formatting';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

export default {
  components: {
    CouponCheckbox,
  },

  props: {
    context: {
      type: String,
      required: true,
      default: Context.Basket,
    },
    autoCheckNewCoupon: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    // This is used to force the re-render of the coupons list when a coupon is added or removed
    resetKey: 0,
    Context,
    SubscriptionContexts,
  }),

  computed: {
    ...mapGetters('user', [
      'getUserCoupons',
      'isUserLoaded'
    ]),
    ...mapGetters('temporaryCustomer', [
      'isTemporaryCustomerLoaded'
    ]),
    ...mapGetters('subscriptionBasket', [
      'getLimitDate'
    ]),
    getCoupons() {
      return [
        ...this.getUserCoupons,
        ...this.getContextCoupons,
        ...(this.context === Context.Basket ? this.$store.getters['basket/getRemovedCoupons'] : []),
      ]
        .filter((coupon, index, self) => index === self.findIndex((c) => c.id === coupon.id))
        .map(coupon => ({
          ...coupon,
          isChecked: this.isCouponChecked(coupon),
        }))
        .reverse();
    },
    getGroupedCoupons() {
      const skeleton = [
        {
          coupons: [
            1,
            2,
            3
          ].map(() => ({ skeleton: true }))
        }
      ];
      return this.isUserLoaded || this.isTemporaryCustomerLoaded ? getCouponsGroupedByType(this.getCoupons) : skeleton;
    },
    getContextCoupons() {
      switch (this.context) {
        case Context.Subscription:
        case Context.SubscriptionBasket:
          return this.$store.getters['subscriptionBasket/getSubscriptionBasketCoupons'];
        case Context.Order:
          return this.order ? this.$store.getters['user/getOrderCoupons'](this.order.id) : [];
        default:
          return this.$store.getters['basket/getSimulatedOrderCoupons'];
      }
    },
    getAddAction() {
      switch (this.context) {
        case Context.Subscription:
          return ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case Context.Order:
          return ADD_COUPON_ON_ORDER_ACTION;
        default:
          return ADD_COUPON_ON_BASKET_ACTION;
      }
    },
    getRemoveAction() {
      switch (this.context) {
        case Context.Subscription:
          return REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case Context.Order:
          return REMOVE_COUPON_ON_ORDER_ACTION;
        default:
          return REMOVE_COUPON_ON_BASKET_ACTION;
      }
    },
    isLoading() {
      return this.$wait.is([
        this.getAddAction,
        this.getRemoveAction,
      ]);
    },
  },

  watch: {
    getUserCoupons(newVal, oldValue) {
      if (newVal !== oldValue && this.autoCheckNewCoupon) {
        const newCoupon = newVal.filter((obj) => !oldValue.some((obj2) => obj.id === obj2.id));
        if (newCoupon && newCoupon.length === 1 && !this.isCouponDisabled(newCoupon[0])) {
          this.onChecked({
            isChecked: true,
            coupon: newCoupon[0],
          });
        }
      }
    },
  },

  methods: {
    tmsToFormat,
    pluralize,
    isCouponLoading(coupon) {
      return this.$wait.is([
        `${this.getAddAction}_${coupon.id}`,
        `${this.getAddAction}_${coupon.couponCode}`,
        `${this.getRemoveAction}_${coupon.id}`,
        `${this.getRemoveAction}_${coupon.couponCode}`,
      ]);
    },
    isCouponDisabled(coupon) {
      if (coupon.isChecked) return false;
      return this.isCouponDisabledByType(coupon)
        || this.isCouponDisabledByValueType(coupon)
        || this.isCouponDisabledByLimitLuncheonReached(coupon);
    },
    isCouponDisabledByType(coupon) {
      if (!this.getContextCoupons.length) return false;
      const forbiddenTypes = this.getContextCoupons.flatMap((c) => getForbiddenTypes(c.couponType));
      return forbiddenTypes.includes(coupon.couponType);
    },
    isCouponDisabledByValueType(coupon) {
      if (!this.getContextCoupons.length) return false;

      return this.getContextCoupons.some((e) => {
        const isValueAndValue = e.couponValueType === CouponValueType.Value && coupon.couponValueType === CouponValueType.Value;
        const isValueAndProduct = (e.couponValueType === CouponValueType.Value && coupon.couponValueType === CouponValueType.Product)
          || (e.couponValueType === CouponValueType.Product && coupon.couponValueType === CouponValueType.Value);
        const isDifferentTypes = e.couponType !== coupon.couponType;
        const isProductAndPercent = (e.couponValueType === CouponValueType.Percent && coupon.couponValueType === CouponValueType.Product)
          || (e.couponValueType === CouponValueType.Product && coupon.couponValueType === CouponValueType.Product);

        return !(isValueAndValue || isValueAndProduct || (isDifferentTypes && isProductAndPercent));
      });
    },
    isCouponDisabledByLimitLuncheonReached(coupon) {
      if (this.getContextCoupons.length && coupon.couponType === CouponType.Luncheon) {
        return this.getContextCoupons.filter((c) => c.couponType === CouponType.Luncheon).length > 1;
      }
      return false;
    },
    isCouponChecked(coupon) {
      return this.getContextCoupons.filter(c => c.id === coupon.id).length > 0;
    },
    getAction(isChecked) {
      return isChecked ? this.getAddAction : this.getRemoveAction;
    },
    onChecked({
      isChecked,
      coupon
    }) {
      const action = this.getAction(isChecked);
      const params = {
        couponId: coupon.id,
        couponCode: coupon.couponCode
      };
      if (this.order) params.orderId = this.order.id;

      this.$store.dispatch(`user/${action}`, params)
        .catch(() => this.resetKey += 1);
    },
  },
};
</script>
