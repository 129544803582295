<template>
  <div class="text-center">
    <potager-form
      id="dlp-search-input"
      :models="[value]"
      class="text-left"
      @onSubmit="onSubmit">
      <potager-input
        :id="name"
        ref="input"
        v-model="value"
        :constraints="constraints"
        :disabled="isLoading"
        :inline-submit="isLookingForPrivates"
        :is-loading="isLoading"
        :label="label"
        :name="name"
        :placeholder="placeholder"
        autocomplete="off"
        class="dlp-search-input"
        external-label
        full-width
        reset
        truncate
        @onPrefixClick="onPrefix"
        @onReset="onReset">
        <template #icon>
          <component :is="getIcon" />
        </template>
      </potager-input>
    </potager-form>

    <potager-button
      v-if="locate === true"
      :is-disabled="isLocating"
      class="text-go-green underline flex mx-auto items-center justify-center mt-2 text-xs.5 font-bold"
      theme="transparent"
      @onClick="locateUser">
      <template #icon>
        <component :is="getLocateIcon" />
      </template>

      Autour de moi
    </potager-button>
  </div>
</template>

<script>
import { createApp, h } from 'vue';
import Autocomplete from '@tarekraafat/autocomplete.js';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  UPDATE_USER_SEARCH,
  RESET_SEARCH_RESULT,
  RESET_USER_SEARCH,
  UPDATE_USER_ADDRESS,
} from 'Stores/types/mapMutationsTypes';
import {
  LOCATE_USER,
  SEARCH_PRIVATES_DELIVERY_POINTS,
  FETCH_DELIVERY_POINT,
} from 'Stores/types/mapActionsTypes';
import { MAP_STORE_LOADERS } from 'Classes/Loaders';

import GtmMixin from 'Mixins/GtmMixin';
import WoosMapMixin from 'Mixins/WoosMapMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';

import IconLocationTarget from 'Icons/regular/IconLocationTarget';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconTwoPinMarker from 'Icons/bold/IconTwoPinMarker';
import IconSearch1 from 'Icons/bold/IconSearch1';

import { pluralize } from 'PotagerLogic/Formatting';
import { fetchLocalitiesDetails } from 'Classes/Woosmap';

import DlpSearchInputResultLine from 'Components/deliveryPoint/deliverySearchInput/DlpSearchInputResultLine';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  mixins: [
    GtmMixin,
    WoosMapMixin,
  ],

  components: {
    PotagerInput,
    PotagerButton,
    PotagerForm,
    PotagerRow,
    PotagerCol,
    IconLocationTarget,
    IconTwoPinMarker,
  },

  emits: ['onError', 'onSearch'],

  data: () => ({
    value: '',
    multisearchInstance: undefined,
    autocompleteInstance: undefined,
  }),

  props: {
    context: {
      type: String,
      required: false,
      default: Context.Basket,
    },
    lookingFor: {
      type: String,
      required: false,
      default: 'publics',
    },
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Commerçant, adresse, ville...',
    },
    name: {
      type: String,
      required: false,
      default: 'dlp-search-input',
    },
    constraints: {
      type: Array,
      required: false,
      default: () => [],
    },
    displayPrivateRedirect: {
      type: Boolean,
      required: false,
      default: false,
    },
    deliveryChoiceTracking: {
      type: Boolean,
      required: false,
      default: false,
    },
    locate: {
      type: [Boolean, String],
      required: false,
      default: false,
      validator(value) {
        return [true, false, undefined, null, 'inline'].includes(value);
      },
    },
  },

  computed: {
    ...mapGetters('map', [
      'getUserSearch',
      'getPrivateSearchResult',
      'getMapPublicSearchResult',
      'getUserPosition',
      'getWoosScriptIsLoaded',
    ]),
    isLoading() {
      return this.$wait.is(MAP_STORE_LOADERS);
    },
    isLookingForPublics() {
      return this.lookingFor === 'publics';
    },
    isLookingForPrivates() {
      return this.lookingFor === 'privates';
    },
    getIcon() {
      if (this.isLookingForPrivates) return IconSearch1;
      if (this.locate === 'inline') return this.isLocating ? IconPtcyLoaderSpinner : IconLocationTarget;
      return IconTwoPinMarker;
    },
    gtmType() {
      if (this.isLookingForPrivates) {
        return 'PrivatePoint';
      }
      return 'PublicPoint';
    },
    isLocating() {
      return this.$wait.is(LOCATE_USER);
    },
    getLocateIcon() {
      return this.isLocating ? IconPtcyLoaderSpinner : IconLocationTarget;
    },
    searchPublicIdQueryParam() {
      return this.$potagerRoute.query.search_public_id;
    },
    latlngParam() {
      return this.$potagerRoute.params.latlng;
    },
  },

  watch: {
    getWoosScriptIsLoaded: {
      handler(isLoaded) {
        if (isLoaded) {
          // $nextTick doesn't work on PR/PP switch
          setTimeout(() => {
            if (!this.isLookingForPrivates) {
              if (!this.getUserPosition) {
                this.$store.dispatch(`map/${LOCATE_USER}`, {})
                  .finally(() => {
                    this.setAutoComplete();
                  });
              } else {
                this.setAutoComplete();
              }
            }
          });
        }
      },
      immediate: true,
    },
    getUserSearch(val, oldval) {
      if (!val) return;

      const results = this.isLookingForPublics ? val?.suggestions : this.getPrivateSearchResult;
      const result = results?.[0];
      let value = val?.search;

      const latlngParam = `${result?.geometry?.location?.lat},${result?.geometry?.location?.lng}`;
      if (this.latlngParam && latlngParam !== this.latlngParam) {
        this.$potagerRoute.params.latlng = latlngParam;
        this.$router.replace(this.$potagerRoute);
      }

      const formattedResult = {};

      if (result.city) formattedResult.city = result.city;
      if (result.postalCode) formattedResult.postalCode = result.postalCode;

      if (result.types) {
        const postalCode = result.types
          .find((type) => type === 'postal_code') ? result.name : result.address_components
          .find((component) => component.types
            .includes('postal_code'))?.long_name;
        if (postalCode) formattedResult.postalCode = Array.isArray(postalCode) ? postalCode[0] : postalCode.toString();

        const city = result.types
          .find((type) => (type === 'locality' || type === 'postal_town')) ? result.name : result.address_components
          .find((component) => (component.types
            .includes('locality') || component.types
            .includes('postal_town')))?.long_name;
        if (city) formattedResult.city = city;

        if (!value) value = '>geolocation<';
      }

      this.trackMapSearch(formattedResult, value, this.gtmType);
      if (!val && oldval) this.onReset();
    },
    searchPublicIdQueryParam: {
      handler(publicId) {
        // on app, when doing a search with autocomplete, we get the public id il query params to retrieve de locality result-
        if (publicId) {
          fetchLocalitiesDetails(publicId)
            .then((locality) => {
              this.value = locality?.name;
              this.$store.commit(`map/${UPDATE_USER_SEARCH}`, {
                search: this.value,
                suggestions: [locality],
              });
            });
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('map', {
      UPDATE_USER_SEARCH,
      RESET_SEARCH_RESULT,
      RESET_USER_SEARCH,
    }),
    ...mapActions('map', {
      SEARCH_PRIVATES_DELIVERY_POINTS,
      FETCH_DELIVERY_POINT,
    }),
    pluralize,
    onSubmit() {
      // only private because public is handled by autocomplete
      if (this.isLookingForPrivates) {
        this.SEARCH_PRIVATES_DELIVERY_POINTS(this.value)
          .then((results) => this.onSearch(results))
          .catch((err) => this.$emit('onError', err));
      }
    },
    onSearch(result) {
      if (this.deliveryChoiceTracking) {
        this.trackDeliveryChoice({
          type: this.gtmType,
          search: this.value,
        });
      }

      this.$emit('onSearch', {
        search: this.value,
        result,
      });
    },
    onReset() {
      this.RESET_USER_SEARCH();
      this.RESET_SEARCH_RESULT();
      this.autocompleteInstance?.close();
    },
    locateUser() {
      this.$store.dispatch(`map/${LOCATE_USER}`, {
        geocodeAddress: true,
        navigatorLocalisation: true,
      })
        .then((userPosition) => {
          this.$store.commit(`map/${UPDATE_USER_ADDRESS}`, userPosition);
          this.$emit('onSearch', {
            search: undefined,
            result: userPosition,
          });
        });
    },
    setAutoComplete() {
      const input = document.getElementById(this.name) && this.$refs.input;
      if (!input) return;

      const localitiesParams = {
        components: { country: ['fr'] },
        types: ['address', 'postal_code', 'locality', 'metro_station'],
      };

      if (this.getUserPosition?.geometry?.location) {
        const {
          lat,
          lng
        } = this.getUserPosition.geometry.location;
        localitiesParams.location = `${lat},${lng}`;
      }

      this.multisearchInstance = window.woosmap.multisearch({
        apiOrder: ['localities', 'store'],
        debounceTime: 100,
        store: {
          fallbackBreakpoint: false,
          key: import.meta.env.VITE_WOOS_MAP_KEY,
          minInputLength: 3,
        },
        localities: {
          fallbackBreakpoint: 0.3,
          key: import.meta.env.VITE_WOOS_MAP_KEY,
          minInputLength: 3,
          params: localitiesParams,
        },
      });

      this.autocompleteInstance = new Autocomplete({
        data: {
          src: async () => this.multisearchInstance.autocompleteMulti(this.value),
          keys: ['highlight'],
          cache: false,
        },
        maxResults: 10,
        placeHolder: this.placeholder,
        selector: `#${this.name}`,
        observer: false,
        searchEngine: (query, record) => record,
        resultsList: {
          tabSelect: true,
          class: 'dlp-search-input__result-list',
        },
        resultItem: {
          element: (item, data) => {
            const app = createApp({
              render: () => h(DlpSearchInputResultLine, { result: data.value }),
            });
            const container = document.createElement('div');
            app.mount(container);
            item.innerHTML = container.innerHTML;
            item.setAttribute('data-api', data.value.api);
            this.$nextTick(() => app.unmount());
          },
          selected: 'dlp-search-input__result-line--selected',
          highlight: true,
        },
      });

      // on select, get details and emit onSelect
      this.$refs.input.$el.addEventListener('selection', (event) => {
        this.multisearchInstance.detailsMulti(event.detail.selection.value)
          .then((selection) => {
            this.UPDATE_USER_SEARCH({
              search: this.value,
              suggestions: [selection],
            });

            // if selection is a store we UPDATE_SELECTED_DELIVERY_POINT
            // if not, it's a address so we UPDATE_USER_ADDRESS
            if (selection?.store_id) {
              this.value = selection.name;
              this.FETCH_DELIVERY_POINT({
                regionId: selection.store_id.split('_')[0],
                id: selection.store_id.split('_')[1],
              })
                .then((dlp) => this.onSearch(dlp));
            } else {
              this.value = selection.formatted_address;
              this.$store.commit(`map/${UPDATE_USER_ADDRESS}`, selection);
              this.onSearch(selection);
            }
          });
      });
    },
    onPrefix() {
      if (this.locate === 'inline') this.locateUser();
    },
  },

  mounted() {
    if (this.getWoosScriptIsLoaded === undefined) this.initializeWoosMap();
  },

  beforeUnmount() {
    this.autocompleteInstance?.close();
    this.autocompleteInstance = undefined;
    this.multisearchInstance = undefined;
  },
};
</script>

<style lang="scss">
.autoComplete_wrapper {
  @apply relative;
}

.dlp-search-input {
  + ul {
    @apply absolute z-10 w-full bg-white rounded-b-lg shadow-lg;
  }

  &__result-list {
    // add a border between elements list with data-api="localities" & data-api="store"
    // to separate publics and privates results
    [data-api="localities"] + [data-api="store"] {
      .potager-button__element {
        @apply mt-2;
        @apply before:border-t before:border-white-rock before:border-solid before:absolute before:top-1 before:left-4 before:right-4 before:z-10;
      }
    }

    > li {
      &:not(:last-child) {
        .potager-button__element {
          @apply rounded-none;
        }
      }

      &:last-child {
        .potager-button__element {
          @apply rounded-t-none;
        }
      }
    }
  }
}
</style>
