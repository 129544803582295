import { Context } from 'PotagerLogic/Enums/Context';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  const subscriptionBasketDeliveryPoint = rootGetters['session/getDeliveryPoint'](Context.SubscriptionBasket);
  const subscriptionDeliveryPoint = rootGetters['session/getDeliveryPoint'](Context.Subscription);
  const deliveryPoint = subscriptionBasketDeliveryPoint || subscriptionDeliveryPoint;

  if (deliveryPoint?.isHomeDelivery) {
    const address = subscriptionBasketDeliveryPoint
      ? rootGetters['session/getAddress'](Context.SubscriptionBasket)
      : rootGetters['session/getAddress'](Context.Subscription);
    return !!deliveryPoint && !!address === expectedValue;
  }

  return !!deliveryPoint === expectedValue;
};

export default {
  type: 'hasDeliveryPoint',
  values: [true, false],
  test,
};
