<template>
  <time-slot-note
    v-if="getSubscriptionOrder"
    :order="getSubscriptionOrder"
    :time-slot="getSubscriptionOrder.timeSlot"
    class="mx-auto"
    only-delivery-note />
</template>

<script>
import { mapGetters } from 'vuex';

import TimeSlotNote from 'Components/timeSlots/TimeSlotNote';

export default {
  name: 'SubscriberBarDeliveryNote',
  components: { TimeSlotNote },
  computed: {
    ...mapGetters('user', ['getSubscriptionOrder']),
  },
};
</script>
