<template>
  <component
    :is="isPanel ? 'panel' : 'potager-container'"
    :back-route="backRoute"
    :title="`Ajouter une ${paymentMethod.title}`">
    <div class="panel-section">
      <paygreen-new-card-form
        :name="name"
        class="panel-section__line"
        legend-cgv
        @success="onSuccess" />
    </div>
  </component>
</template>

<script>
import MobileAppMixin from 'Mixins/MobileAppMixin';
import Panel from 'Components/panel/Panel';

import PotagerContainer from 'UI/PotagerContainer';

import MetaInfosService from 'Classes/services/MetaInfoService';
import PaygreenNewCardForm from 'Components/paygreen/PaygreenNewCardForm';

import { getPaymentMethodByName } from 'Classes/payment-methods';
import { PaymentMethodType } from 'PotagerLogic/Enums/PaymentMethods';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    PaygreenNewCardForm,
    Panel,
    PotagerContainer,
  },

  computed: {
    route() {
      return this.isPanel ? this.$potagerRoute : this.$route;
    },
    name() {
      return this.route.params.name;
    },
    context() {
      return this.$potagerRoute?.query?.context || Context.User;
    },
    backRoute() {
      const orderId = this.$potagerRoute.params.orderId || this.$potagerRoute.query.orderId;
      if (this.context === Context.Basket) {
        return {
          name: 'basket_payment'
        };
      } else if (this.context === Context.Order && orderId) {
        return {
          name: 'mon-compte_orders_detail',
          params: {
            orderId,
          }
        };
      } else {
        return {
          name: 'mon-compte_payment-methods'
        };
      }
    },
    paymentMethod() {
      return getPaymentMethodByName(this.name);
    },
    isPanel() {
      return !!this.$potagerRoute?.meta?.isPanel;
    },
  },

  methods: {
    onSuccess(newCard) {
      if (this.isInAppWebView) {
        this.redirectWebApp();
      } else {
        const params = newCard.brand === PaymentMethodType.BankCard ? { cardId: newCard.id } : undefined;
        const redirect = {
          name: this.backRoute.name,
          params: {
            ...params,
            ...this.backRoute.params,
          },
        };

        this.$router.push(redirect)
          .catch(() => {
          });
      }
    },
  },

  head: MetaInfosService.generate({
    title: 'Ajouter une carte',
  }),

};
</script>
