import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('dashboard', [
      'getDashboardRegion',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getRegionId',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
  },

  watch: {
    isLoggedIn: {
      handler() {
        this.fetchDashboard();
      },
    },
    isSubscriptionActive: {
      handler() {
        // si on se désabonne afin d'actualiser l'ordre des éléments du dashboard
        this.fetchDashboard();
      },
    },
    getRegionId: {
      handler() {
        this.fetchDashboard();
      },
    },
  },

  methods: {
    fetchDashboard() {
      if (this.$wait.is(FETCH_DASHBOARD_ACTION)) this.$wait.end(FETCH_DASHBOARD_ACTION);
      this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_ACTION}`, true);
    },
  },

  created() {
    if (!this.isLoaded || this.getDashboardRegion?.id !== this.getRegionId) {
      this.fetchDashboard();
    }
  },
};
