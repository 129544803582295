<template>
  <potager-checkbox
    v-model="value"
    type="radio"
    v-bind="$props">
    <slot />
  </potager-checkbox>
</template>

<script>
import { propsBuilder } from 'UI/Tools';
import PotagerCheckbox, { props as PotagerCheckboxProps } from 'UI/Form/PotagerCheckbox';

const {
  type,
  ...filteredProps
} = PotagerCheckboxProps;
export const props = filteredProps;

export default {

  components: {
    PotagerCheckbox,
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  props: propsBuilder(props),
};
</script>
