<template>
  <potager-button
    v-wave="!readonly"
    :class="[
      `potager-navigator potager-navigator--${theme}`,
      { 'potager-navigator--readonly': readonly }
    ]"
    :href="!readonly ? href : null"
    :is-loading="isLoading"
    :target="target"
    :theme="theme"
    multi-line
    :to="!readonly ? (to || href) : null"
    @click="onClick">
    <template
      v-if="$slots.icon"
      #icon>
      <slot name="icon" />
    </template>

    <div class="flex justify-between items-center w-full">
      <div class="potager-navigator__body block flex-1 overflow-hidden whitespace-break-spaces">
        <slot />
      </div>

      <div class="potager-navigator-arrow hidden flex-initial">
        <component
          :is="href ? 'icon-arrow-corner-right' : 'icon-arrow-right1'"
          v-if="!readonly"
          :class="[
            'block ml-2',
            {'-rotate-90': href}
          ]" />
      </div>
    </div>
  </potager-button>
</template>

<script>
import IconArrowRight1 from 'Icons/bold/IconArrowRight1';
import IconArrowCornerRight from 'Icons/bold/IconArrowCornerRight';

import { BUTTON_THEMES } from 'Classes/Constants';

import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    IconArrowRight1,
    IconArrowCornerRight,
    PotagerButton,
  },

  props: {
    to: {
      type: [Object, String],
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
      values: ['_blank', '_parent', '_self', '_top'],
    },
    tag: {
      type: String,
      required: false,
      default: 'button',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: 'white',
      values: BUTTON_THEMES,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['onClick'],

  methods: {
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.potager-navigator {
  $navigator: &;

  border-radius: 0;
  text-align: left;
  font-size: .9375rem;
  line-height: 1.5rem;
  justify-content: space-between !important;
  width: 100%;
  padding: $panel_padding;

  [class*='panel-section__'] + &,
  & + & {
    &:last-child:not(:first-child),
    &:not(:first-child) {
      &:before {
        content: '';
        display: block;
        height: 1px;
        background: rgba($black, .07);
        position: absolute;
        top: 0;
        left: $panel_padding;
        right: $panel_padding;
      }
    }
  }

  &--readonly {
    cursor: default;
  }

  :deep().potager-button {
    &__icon {
      font-size: 1.5rem;
      margin-right: .5rem;
    }

    &__text {
      overflow: visible !important;
    }
  }
}
</style>
