<template>
  <potager-form
    id="coupons-manager"
    @onSubmit="addCoupon">
    <potager-input
      v-model="couponCode"
      :constraints="[
        { constraint: 'required', field: 'code' },
        { constraint: 'length', field: 'code', options: { min: 3 } },
      ]"
      :inline-submit-gtm="{ label: 'ajouter un coupon', couponCode }"
      :is-loading="isLoading"
      inline-submit
      name="code"
      placeholder="Code promo"
      reset>
      <template #icon>
        <icon-discount-bubble />
      </template>
    </potager-input>
  </potager-form>
</template>

<script>
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';

import IconDiscountBubble from 'Icons/bold/IconDiscountBubble';
import { Context } from 'potagerlogic/src/Enums/Context';
import {
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_ORDER_ACTION, ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  ADD_COUPON_ON_USER_ACTION
} from 'Stores/types/userActionsTypes';

export default {
  name: 'CouponInput',
  components: {
    PotagerInput,
    PotagerForm,
    IconDiscountBubble,
  },
  emits: { 'onSuccess': null },
  props: {
    context: {
      type: String,
      required: false,
      default: Context.User,
      validator: (val) => [
        Context.Basket,
        Context.SubscriptionBasket,
        Context.Subscription,
        Context.Order,
        Context.User
      ].includes(val),
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    couponCode: null,
  }),
  computed: {
    isLoading() {
      return this.$wait.is(this.addCouponAction);
    },
    addCouponAction() {
      switch (this.context) {
        case Context.SubscriptionBasket:
          return ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case Context.Basket:
          return ADD_COUPON_ON_BASKET_ACTION;
        case Context.Order:
          return ADD_COUPON_ON_ORDER_ACTION;
        default:
          return ADD_COUPON_ON_USER_ACTION;
      }
    },
  },
  methods: {
    addCoupon() {
      const params = {
        couponCode: this.couponCode?.toUpperCase(),
        ...(this.order ? { orderId: this.order.id } : {}),
      };

      this.$store.dispatch(`user/${this.addCouponAction}`, params)
        .then(() => {
          this.couponCode = '';
          this.$events.emit('request:inputSuccess', {
            field: 'code',
            message: 'Le code promo a été ajouté',
          });
        });
    },
  },
};
</script>
