<template>
  <dlp-search-input
    ref="input"
    :constraints="[
      { constraint: 'required', field: 'search' },
      { constraint: 'length', field: 'search', options: { min: 3 } },
    ]"
    :context="context"
    delivery-choice-tracking
    locate
    looking-for="publics"
    @onSearch="onSearch" />
</template>

<script>
import DlpSearchInput from 'Components/deliveryPoint/DlpSearchInput';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  props: {
    context: {
      type: String,
      required: false,
      default: Context.Basket,
    },
  },

  components: {
    DlpSearchInput,
  },

  emits: ['onSearch'],

  methods: {
    onSearch(value) {
      this.$emit('onSearch', value);
    },
  },
};
</script>
