<template>
  <subscriber-bar-item
    v-if="getSubscriptionOrder && isOrderCanceledStatus(getSubscriptionOrder)"
    :image="getSubscriptionOrder?.product?.image"
    :redirect="routes.mySubscriptionOrder"
    text="Vous avez annulé votre panier">
    <template #icon>
      <icon-road-sign-banned />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="getSubscriptionOrder && !getSubscriptionOrder?.containsSubscriptionBox"
    :image="getSubscriptionOrder?.product?.image"
    :redirect="routes.mySubscriptionOrder"
    text="Vous avez choisi un autre panier">
    <template #icon>
      <icon-check-circle1 />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="getSubscription?.temporaryOrder || isSubscriptionOrderNotYetCreated"
    :redirect="routes.mySubscription"
    text="Rendez-vous jeudi pour découvrir la prochaine composition">
    <template #icon>
      <icon-stopwatch />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="!getSubscriptionNextOrder && !isSubscriptionSuspended"
    :redirect="routes.mySubscription"
    text="Pas de livraison prévue pour le moment">
    <template #icon>
      <icon-road-sign-warning />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="isSubscriptionSuspended"
    :redirect="routes.mySubscriptionSuspended"
    text="Votre abonnement est suspendu">
    <template #icon>
      <icon-road-sign-banned />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else-if="!getNextGenerationDate && !getSubscriptionOrder"
    :redirect="routes.mySubscription"
    is-warning
    text="Votre point de retrait est indisponible">
    <template #icon>
      <icon-road-sign-warning />
    </template>
  </subscriber-bar-item>

  <subscriber-bar-item
    v-else
    :image="getSubscription?.product?.image"
    :redirect="routes.subscriptionPage"
    text="Voir la composition du panier" />
</template>

<script>
import { mapGetters } from 'vuex';

import IconRoadSignBanned from 'Icons/regular/IconRoadSignBanned';
import IconCheckCircle1 from 'Icons/regular/IconCheckCircle1';
import IconStopwatch from 'Icons/regular/IconStopwatch';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';

import SubscriberBarItem from 'Components/subscriberBar/SubscriberBarItem';

import { isOrderCanceledStatus } from 'PotagerLogic/Utils/Order/OrderStatus';

import SubscriptionOrderMixin from 'Mixins/SubscriptionOrderMixin';

export default {
  mixins: [SubscriptionOrderMixin],

  components: {
    IconRoadSignBanned,
    IconCheckCircle1,
    IconStopwatch,
    IconRoadSignWarning,
    SubscriberBarItem,
  },
  props: {
    routes: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getSubscriptionOrder',
      'getNextGenerationDate',
      'isSubscriptionSuspended',
      'getSubscriptionNextOrder',
    ]),
  },
  methods: { isOrderCanceledStatus },
};
</script>
