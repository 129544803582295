<template>
  <potager-link
    v-gtm
    :to="{ name: 'basket_subscription' }"
    class="panel-section panel-section--slaying bg-sandy-beach">
    <potager-picture
      background
      background-size="contain"
      class="panel-section__line flex items-center"
      position="right"
      src="bag-veggies-section">
      <icon-shopping-bag-check class="text-2xl text-mango-tango mr-4" />

      <p class="text-xs">
        <span class="text-sm.5 font-semibold mb-1">Avantage abonnés</span><br>
        <span><b>Moins cher</b> et <b>plus pratique</b>&nbsp;:<br>
          récoltez les fruits de votre fidélité&nbsp;!</span>
      </p>
    </potager-picture>
  </potager-link>
</template>

<script>
import IconShoppingBagCheck from 'Icons/regular/IconShoppingBagCheck';
import PotagerPicture from 'UI/PotagerPicture';

export default {
  name: 'SubscriberBenefitsSection',
  components: {
    PotagerPicture,
    IconShoppingBagCheck,
  },
};
</script>

<style lang="scss" scoped>

</style>
