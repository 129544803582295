<template>
  <panel
    :back-route="getBackRoute"
    title="Remplacer mon panier">
    <div class="panel-section">
      <p class="panel-section__p mb-8 bp640:mb-4">
        Votre panier "<b>{{ getSubcriptionBoxName }}</b>" de cette semaine ne vous convient pas ? Vous pouvez à tout
        moment
        l’annuler ou le remplacer par un autre panier de votre choix.
      </p>

      <p class="panel-section__title">
        Choisissez un panier de remplacement
      </p>

      <potager-row
        class="panel-section__line"
        tag="ul">
        <potager-col
          v-for="(box, key) in boxesAvailable"
          :key="key"
          col="6"
          tag="li">
          <card-product
            :box="box"
            :order="order"
            :product-id-to-replace="productIdToReplace"
            replace
            small>
            <template #footer>
              <potager-button
                :is-disabled="isBoxDisabled || box.isSoldOut || !order || !order.isEditable"
                :is-loading="isBoxLoading(box)"
                :theme="box.isSoldOut ? undefined : 'go-green'"
                :tooltip="!box.loading ? replaceStatus(box) : undefined"
                full-width
                prevent
                size="small"
                @onClick="replaceSubscriptionOrder(box.id)">
                Remplacer
              </potager-button>
            </template>
          </card-product>
        </potager-col>
      </potager-row>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { REPLACE_SUBSCRIPTION_PRODUCT, CONFIRM_SHADOW_BASKET_ACTION } from 'Stores/types/shadowBasketActionsTypes';

import ShadowBasketActionMixin from 'Mixins/ShadowBasketActionMixin';

import Panel from 'Components/panel/Panel';
import CardProduct from 'Components/cards/CardProduct';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { SHADOW_BASKET_TYPE } from 'Classes/Constants';

export default {

  mixins: [
    ShadowBasketActionMixin,
  ],

  components: {
    Panel,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    CardProduct,
  },

  data: () => ({
    boxesAvailable: [
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
      { loading: true },
    ],
  }),

  computed: {
    ...mapGetters('user', [
      'getOrderById',
      'getNextEditableSubscriptionOrder',
      'getSubscription',
    ]),
    order() {
      return this.getOrderById(this.$potagerRoute.params.orderId) || this.getNextEditableSubscriptionOrder;
    },
    getSubcriptionBoxName() {
      return this.getSubscription?.product?.name;
    },
    getBackRoute() {
      return this.order?.id ? {
        name: 'mon-compte_orders_detail',
        params: { orderId: this.order?.id },
      } : null;
    },
    isBoxDisabled() {
      return this.$wait.is(REPLACE_SUBSCRIPTION_PRODUCT);
    },
    productIdToReplace() {
      const typeFormuleBox = this.order?.boxes.find((box) => box.isTypeFormule);
      const notComplementaryBox = this.order?.boxes.find((box) => !box.isComplementary);
      const defaultOrderBox = typeFormuleBox || notComplementaryBox;
      return parseInt(this.$potagerRoute.params.productIdToReplace, 10) || defaultOrderBox?.id;
    },
  },

  methods: {
    getBoxesAvailable() {
      this.$api.box.findAllByBasket(SHADOW_BASKET_TYPE)
        .then(({ data }) => {
          this.boxesAvailable = data.data.results
            .filter((e) => !e.isComplementary && !e.isTypeFormule && e.id !== this.productIdToReplace)
            .sort((a) => (a.isTypeFormule ? -1 : 1));
        });
    },
    replaceSubscriptionOrder(productId) {
      if (this.order?.isEditable) {
        this.replaceSubscriptionProduct(this.productIdToReplace, productId, this.order?.id);
      } else {
        this.$notify({
          type: 'error',
          title: 'Erreur de remplacement',
          message: 'Le remplacement de votre panier n\'a pas pu être effectué. Veuillez réessayer plus tard ou contacter le service client.',
        });
      }
    },
    replaceStatus(box) {
      return !this.order?.isEditable ? 'Votre commande n\'est plus modifiable' : box.isSoldOut ? 'Ce panier est en rupture de stock' : undefined;
    },
    isBoxLoading(box) {
      return this.$wait.is([
        `${REPLACE_SUBSCRIPTION_PRODUCT}_${box.id}`,
        CONFIRM_SHADOW_BASKET_ACTION,
      ]);
    },
  },

  created() {
    this.getBoxesAvailable();
  },

  head: MetaInfosService.generate({
    title: 'Remplacer mon panier',
  }),

};
</script>
