<template>
  <div
    v-if="items?.length > 0"
    class="relative potager-slider">
    <swiper
      :key="`slider-${name}`"
      ref="slider"
      :modules="modules"
      class="h-full"
      v-bind="options"
      @init="onInit"
      @slideChange="onSlideChange"
      @update="checkNavigation">
      <swiper-slide
        v-for="(item, index) in items"
        :key="`slide-${name}-${index}`"
        :class="[
          'swiper-slide h-auto',
          {
            'w-auto': getNbVisibleSlides === 'auto',
            'flex-1': equalWidth,
          }
        ]">
        <slot
          :index="index"
          :item="item" />
      </swiper-slide>
    </swiper>

    <slot
      v-if="($slots.navigation || navigation) && hasEnoughSlides"
      :is-beginning="swiperInstance?.isBeginning"
      :is-end="swiperInstance?.isEnd"
      :slider="swiperInstance"
      name="navigation">
      <potager-button
        :class="[
          `${name}-nav-prev`,
          'swiper-button-prev swiper-button shadow-xl',
          'absolute left-0 transform -translate-x-1/2 top-1/2 -translate-y-1/2 z-20'
        ]"
        :is-disabled="swiperInstance?.isBeginning"
        aria-label="Précédent"
        is-circle
        shadow
        theme="white">
        <template #icon>
          <icon-arrow-left1 />
        </template>
      </potager-button>

      <potager-button
        :class="[
          `${name}-nav-next`,
          'swiper-button-next swiper-button shadow-xl',
          'absolute right-0 transform translate-x-1/2 top-1/2 -translate-y-1/2 z-20'
        ]"
        :is-disabled="swiperInstance?.isEnd"
        aria-label="Suivant"
        is-circle
        shadow
        theme="white">
        <template #icon>
          <icon-arrow-right1 />
        </template>
      </potager-button>
    </slot>

    <slot
      :is-beginning="swiperInstance?.isBeginning"
      :is-end="swiperInstance?.isEnd"
      name="pagination">
      <potager-row
        ref="pagination"
        class="swiper-pagination potager-slider__pagination" />
    </slot>
  </div>
</template>

<script>
import {
  Navigation,
  Pagination,
  Controller,
  Autoplay,
  FreeMode,
  EffectCreative,
  EffectFade,
  EffectCards,
  EffectCoverflow,
  EffectCube,
  EffectFlip,
  Virtual,
} from 'swiper/modules';
import 'swiper/css/bundle'; // needed for fade animation (for exemple)
import { Swiper, SwiperSlide } from 'swiper/vue';

import isEqual from 'lodash/isEqual';

import IconArrowLeft1 from 'Icons/regular/IconArrowLeft1';
import IconArrowRight1 from 'Icons/regular/IconArrowRight1';

import PotagerRow from 'UI/PotagerRow';
import PotagerButton from 'UI/PotagerButton';

import { propsBuilder } from 'UI/Tools';

import TYPOS from 'PotagerLogic/Enums/Typos';
import BREAKPOINTS from 'PotagerLogic/Enums/Breakpoints';
import { toRaw } from 'vue';

const { baseFs } = TYPOS;
const {
  bp768,
  bp1024
} = BREAKPOINTS;

export const props = {
  name: {
    type: String,
    required: true, // to avoid multiple sliders with same name
  },
  slidesPerView: {
    type: [Object, Number, String],
    required: false,
    default: {
      default: 1,
      768: 3,
      1024: 4,
    }
  },
  slidesPerGroup: {
    type: [Object, Number, String],
    required: false,
    default: null,
  },
  items: {
    type: Array,
    required: true,
  },
  settings: {
    type: Object,
    required: false,
    default: () => {
    }, // Override default settings
  },
  pagination: {
    type: Boolean,
    required: false,
    default: false,
  },
  navigation: {
    type: Boolean,
    required: false,
    default: false,
  },
  controller: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  overflowVisible: {
    type: Boolean,
    required: false,
    default: false,
  },
  loop: {
    type: Boolean,
    required: false,
    default: false,
  },
  autoplay: {
    type: [Boolean, Number],
    required: false,
    default: false,
  },
  align: {
    type: String,
    required: false,
    default: null,
    values: ['start', 'center', 'end'],
  },
  // if is 'false', wait for contentLoaded to be 'true' before init slider
  contentLoaded: {
    type: Boolean,
    required: false,
    default: true,
  },
  // useful when there is a lot of slides to avoir huge DOM
  // https://swiperjs.com/swiper-api#virtual-slides
  virtual: {
    type: Boolean,
    required: false,
    default: false,
  },
  equalWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
};

export default {
  props: propsBuilder(props),

  components: {
    PotagerRow,
    IconArrowLeft1,
    IconArrowRight1,
    PotagerButton,
    Swiper,
    SwiperSlide,
  },

  emits: ['onInit', 'onSlideChange'],

  data() {
    return {
      swiperInstance: undefined,
    };
  },

  computed: {
    hasNavigation() {
      return !!this.navigation || !!this.options.navigation || !!this.$slots.navigation;
    },
    breakpoints() {
      // get all breakpoints from BREAKPOINTS object
      const breakpoints = ['default', ...Object.keys(BREAKPOINTS)
        .map((bp) => bp.replace('bp', ''))];
      // return a objet of breakpoints with the number of slidesPerView and slidesPerGroup for each breakpoint
      // if no breakpoint is defined in the props, return the value of the previous breakpoint
      let result = {};
      breakpoints.forEach((bp, index) => {
        result[bp] = {
          slidesPerView: typeof this.slidesPerView === 'object'
            ? this.slidesPerView[bp] || result[breakpoints[index - 1]]?.slidesPerView
            : this.slidesPerView,
        };

        if (this.slidesPerGroup) {
          result[bp].slidesPerGroup = typeof this.slidesPerGroup === 'object' ? this.slidesPerGroup[bp] || result[breakpoints[index - 1]].slidesPerGroup : this.slidesPerGroup;
        } else {
          result[bp].slidesPerGroup = result[bp].slidesPerView === 'auto' ? 1 : Math.floor(result[bp].slidesPerView);
        }
      });

      return result;
    },
    getNbVisibleSlides() {
      const breakpoints = Object.keys(BREAKPOINTS)
        .reverse();
      const currentBreakpoint = breakpoints.find((bp) => !this.$mq[bp]);
      if (currentBreakpoint) {
        return this.breakpoints[currentBreakpoint.replace('bp', '')].slidesPerView;
      } else {
        return this.breakpoints.default.slidesPerView;
      }
    },
    hasEnoughSlides() {
      return this.getNbVisibleSlides === 'auto' || this.items.length > this.getNbVisibleSlides;
    },
    options() {
      const baseSettings = {
        autoplay: this.autoplay ? {
          delay: typeof this.autoplay === 'number' ? this.autoplay : 7000,
          disableOnInteraction: false,
        } : false,
        loop: this.loop,
        centeredSlidesBounds: this.align === 'center',
        spaceBetween: parseFloat(this.$mq.bp768 ? '.75rem' : '1rem', 10) * parseFloat(baseFs, 10),
        breakpoints: {
          ...this.breakpoints,
          ...this.settings?.breakpoints,
        },
        ...this.breakpoints?.default,
      };

      if (this.navigation && !this.disabled) {
        baseSettings.navigation = {
          prevEl: `.${this.name}-nav-prev`,
          nextEl: `.${this.name}-nav-next`,
          disabledClass: 'potager-slider__nav--disabled',
        };
      }

      if (this.virtual) {
        baseSettings.virtual = {
          enabled: true,
          // add one slide before to avoid blank slide when 1.5 slidesPerView for exemple
          addSlidesBefore: 1,
          addSlidesAfter: 1,
        };
      }

      if (this.pagination) {
        baseSettings.pagination = {
          type: 'bullets',
          bulletClass: 'potager-slider__pagination__bullet',
          bulletActiveClass: 'potager-slider__pagination__bullet--active',
          modifierClass: 'potager-slider__pagination--',
          hiddenClass: 'potager-slider__pagination--hidden',
          clickableClass: 'potager-slider__pagination--clickable',
          lockClass: 'potager-slider__pagination--lock',
          clickable: true,
        };
      }

      return { ...baseSettings, ...this.settings };
    },
    watchingValues() {
      const {
        slidesPerView,
        settings,
        hasNavigation,
        pagination,
        disabled,
        overflowVisible,
        align,
        hasEnoughSlides,
        $mq,
      } = this;

      return {
        slidesPerView,
        settings,
        hasNavigation,
        pagination,
        disabled,
        overflowVisible,
        centered: align === 'center',
        align,
        hasEnoughSlides,
        $mq,
      };
    },
    modules() {
      const modules = [];
      if (this.hasNavigation) {
        modules.push(Navigation);
      }

      if (this.options.pagination) {
        modules.push(Pagination);
      }

      if (this.controller) {
        modules.push(Controller);
      }

      if (this.autoplay || this.options.autoplay) {
        modules.push(Autoplay);
      }

      if (this.options.freeMode) {
        modules.push(FreeMode);
      }

      if (this.options.virtual) {
        modules.push(Virtual);
      }

      if (this.options.effect) {
        switch (this.options.effect) {
          case 'fade':
            modules.push(EffectFade);
            break;
          case 'coverflow':
            modules.push(EffectCoverflow);
            break;
          case 'flip':
            modules.push(EffectFlip);
            break;
          case 'cube':
            modules.push(EffectCube);
            break;
          case 'cards':
            modules.push(EffectCards);
            break;
          case 'creative':
            modules.push(EffectCreative);
            break;
          default:
            break;
        }
      }

      return modules;
    },
  },

  watch: {
    watchingValues: {
      handler(val, oldValue) {
        if (!isEqual(val, oldValue)) {
          this.swiperInstance?.update();
        }
      },
      deep: true,
    },
  },

  methods: {
    async destroy(cleanStyles = true) {
      if (this.swiperInstance) {
        await this.swiperInstance.destroy(true, cleanStyles);
      }
    },
    onSlideChange(slider) {
      if (slider) {
        this.$emit('onSlideChange', {
          slideIndex: slider.activeIndex,
          slide: this.items[slider.activeIndex],
          slider,
        });
      }
    },
    onInit(swiper) {
      if (swiper) {
        this.swiperInstance = swiper;
        this.$emit('onInit', {
          slideIndex: swiper.activeIndex,
          slide: toRaw(this.items[swiper.activeIndex]),
          slider: toRaw(swiper),
        });
      }
    },
    checkNavigation() {
      if (this.swiperInstance?.navigation) {
        if (this.swiperInstance?.isBeginning && this.swiperInstance?.isEnd) {
          this.swiperInstance.navigation?.prevEl?.classList?.add('hidden');
          this.swiperInstance.navigation?.nextEl?.classList?.add('hidden');
        } else {
          this.swiperInstance.navigation?.prevEl?.classList?.remove('hidden');
          this.swiperInstance.navigation?.nextEl?.classList?.remove('hidden');
        }
      }
    }
  },

  beforeUnmount() {
    this.destroy(false);
  },
};
</script>

<style lang="scss">
.swiper {
  overflow: visible;
}

.swiper-button-prev, .swiper-button-next {
  color: $charcoal;

  &:before,
  &:after {
    display: none;
  }
}
</style>
