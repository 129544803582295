<template>
  <div class="py-12 -mb-12 bp768:-mb-6 bg-british-racing">
    <potager-container>
      <div class="max-w-6/12 w-full mx-auto">
        <local-title-cpt>
          Pssst ! C’est ici qu’on répond à vos questions&nbsp;<span class="font-normal">🔎</span>
        </local-title-cpt>
        
        <collapsible-faq
          v-for="(item, i) in SUBSCRIPTION_FAQ"
          :key="i"
          :item="item" />
      </div>
    </potager-container>
  </div>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import CollapsibleFaq from 'Components/collapsible/CollapsibleFaq';
import { SUBSCRIPTION_FAQ } from 'Classes/Contents';

const localTitleCpt = {
  template: `
    <p class="text-xl font-bold text-white mb-8 text-center">
      <slot />
    </p>
  `,
};

export default {
  components: {
    PotagerContainer,
    CollapsibleFaq,
    localTitleCpt,
  },

  data: () => ({
    SUBSCRIPTION_FAQ,
  }),
};
</script>
