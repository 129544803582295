<template>
  <div class="h-full w-full flex flex-col">
    <woos-map
      :custom-options="{ gestureHandling: 'none', disableDefaultUI: true }"
      hide-overlay />
  </div>
</template>

<script>
import WoosMap from 'Components/map/WoosMap';

export default {
  components: { WoosMap },
};
</script>

<style lang="scss">
.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-bottom-enter-from,
.slide-bottom-leave-to {
  opacity: 0;
  transform: translateY(-15%);
}
</style>
