<template>
  <potager-container
    class="py-4 bg-white"
    size="wide">
    <p class="text-center">
      <template v-if="!$mq.bp768">
        <span class="text-charcoal inline-block align-middle">
          Payer avec
        </span>
      </template>

      <payment-methods-icons-list
        :types="[PaymentMethodType.BankCard, PaymentMethodType.RestaurantCard]"
        alternative-icons
        class="text-lg" />
    </p>
  </potager-container>
</template>

<script>
import COLORS from 'PotagerLogic/Enums/Colors';

import PotagerContainer from 'UI/PotagerContainer';

import PaymentMethodsIconsList from 'Components/paymentMethods/PaymentMethodsIconsList';

import { PaymentMethodType } from 'PotagerLogic/Enums/PaymentMethods';

const { bianca } = COLORS;

export default {

  components: {
    PaymentMethodsIconsList,
    PotagerContainer,
  },

  data: () => ({
    colors: { bianca },
    PaymentMethodType,
  }),

};
</script>
