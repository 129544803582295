import { OAuthProvider } from 'firebase/auth';
import Provider from 'Classes/social-providers';
import IconOsSystemApple from 'Icons/bold/IconOsSystemApple';

export default new Provider({
  name: 'apple',
  icon: IconOsSystemApple,
  color: '#000000',

  getProviderInstance: () => new OAuthProvider('apple.com'),

  getLoginData: (data) => {
    return data.platform ? {
      platform: data.platform,
      code: data.code,
    } : {
      accessToken: data._tokenResponse.oauthAccessToken,
      idToken: data._tokenResponse.oauthIdToken,
      refreshToken: data._tokenResponse.refreshToken,
      email: data._tokenResponse.email,
      tokenType: 'Bearer',
    };
  },
});
