import { mapGetters } from 'vuex';
import { FINALIZE_STRIPE_SETUP_INTENT, GET_STRIPE_SECRET_CLIENT, } from 'Stores/types/userActionsTypes';

import { STRIPE_LOADERS, VARIOUS_PAYMENT_LOADERS, } from 'Classes/Loaders';

import GtmMixin from 'Mixins/GtmMixin';
import ModalStripeError from 'Modals/ModalStripeError';
import { getPaymentError } from 'PotagerLogic/Utils/PaymentErrors';

export const STRIPE_PROCESSING_LOADER = 'STRIPE_PROCESSING_LOADER';

export default {
  mixins: [GtmMixin],

  data: () => ({
    stripe: null,
    clientSecret: null,
  }),

  computed: {
    ...mapGetters('user', ['getBankCards', 'getPaymentGateway',]),
    isStripeLoading() {
      return this.$wait.is([STRIPE_PROCESSING_LOADER, STRIPE_LOADERS, VARIOUS_PAYMENT_LOADERS,]);
    },
  },

  methods: {
    // Pose le Script de Stripe dans la page s'il n'est pas déjà présent
    addStripeScript() {
      return new Promise((resolve, reject) => {
        if (!window.Stripe) {
          const stripeScript = document.createElement('script');
          stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
          document.head.appendChild(stripeScript);

          stripeScript.addEventListener('load', () => {
            if (window.Stripe) {
              resolve();
            } else {
              reject(new Error('Une erreur est survenue'));
            }
          });
        } else {
          resolve();
        }
      });
    },
    initStripe(order) {
      return new Promise((resolve, reject) => {
        this.setStripeSecretClient(order)
          .then(() => {
            this.addStripeScript()
              .then(() => {
                this.stripe = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
                resolve();
              })
              .catch((error) => {
                this.onStripeError(error, false);
                reject(error);
              });
          })
          .catch((error) => reject(error));
      });
    },
    // Récupère le secret_client depuis le backend ou depuis une order
    setStripeSecretClient(order) {
      return new Promise((resolve, reject) => {
        if (order) {
          const orderClientSecret = order?.stripePayment.currentStripePayment.clientSecret;
          if (orderClientSecret) {
            this.clientSecret = orderClientSecret;
            resolve(orderClientSecret);
          } else {
            reject(new Error('Une erreur est survenue (no order client secret)'));
          }
        } else {
          this.$store.dispatch(`user/${GET_STRIPE_SECRET_CLIENT}`)
            .then((response) => {
              this.clientSecret = response.data.client_secret;
              resolve(response);
            })
            .catch((error) => {
              this.onStripeError(error, false);
              reject(error);
            });
        }
      });
    },
    // Retourne un objet payment_method
    createPaymentMethod(cardNumber, cardName) {
      return {
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: cardName,
        },
      };
    },
    // Effectue un paimentIntent (Ajout d'une CB)
    confirmCardSetup(payment_method) {
      this.$wait.start(STRIPE_PROCESSING_LOADER);

      return new Promise((resolve, reject) => {
        this.stripe.confirmCardSetup(this.clientSecret, { payment_method })
          .then((response) => {
            // Une erreur est survenue, on reject la promesse et affiche la modal d'erreur
            if (response.error) {
              this.$wait.end(STRIPE_PROCESSING_LOADER);
              this.onStripeError(response.error);
              reject(response.error);
              // Si Stripe réclame un 3ds et qu'on a décidé d'intercepter l'action
              // Alors on affiche une modal à l'utilisateur, puis on relance le
              // confirmCardSetup() en demandant à Stripe de gérer le next_action
            } else {
              resolve(response);
            }
          })
          .catch((err) => reject(err));
      });
    },
    // Effectue un paimentIntent (Confirmation du paiement commande via 3DS)
    confirmStripeCardPayment(payment_method) {
      this.$wait.start(STRIPE_PROCESSING_LOADER);

      return new Promise((resolve, reject) => {
        this.stripe.confirmCardPayment(this.clientSecret, { payment_method })
          .then((response) => {
            if (response.error) {
              reject(response.error);
            } else {
              resolve(response);
            }
          })
          .catch((err) => reject(err))
          .finally(() => {
            this.$wait.end(STRIPE_PROCESSING_LOADER);
          });
      });
    },
    stripePayment() {
      this.$wait.start(STRIPE_PROCESSING_LOADER);
      this.$emit('submit');
      this.tracking('confirm');

      const paymentMethod = this.createPaymentMethod(this.cardNumber, this.cardName);

      this.confirmCardSetup(paymentMethod)
        .then((resp) => {
          const { setupIntent } = resp;
          return this.$store.dispatch(`user/${FINALIZE_STRIPE_SETUP_INTENT}`, {
            setupIntentId: setupIntent.id,
            setPrimaryBlueCard: this.setPrimary,
          })
            .then(() => {
              this.trackPaymentInfo({ payment_type: 'bluecard' });
              const newCard = this.getBankCards
                .find(card => card.stripe_id === setupIntent.payment_method);
              this.$emit('success', newCard);
            })
            .catch((err) => {
              this.$emit('error', err);
              this.onStripeError(err);
            });
        })
        .catch(err => {
          this.$emit('error', err);
          this.onStripeError(err);
        })
        .finally(() => {
          this.$wait.end(STRIPE_PROCESSING_LOADER);
        });
    },
    // Supprime le sercret_client et en récupère un autre en cas d'erreur
    resetStripeSecretClient() {
      this.clientSecret = null;
      this.setStripeSecretClient();
    }, // Appelé lors d'une erreur Stripe
    // Affichage d'une modal
    // Reset du secret_client si possible
    onStripeError(err, reset = true) {
      this.$wait.end(STRIPE_PROCESSING_LOADER);
      this.$modal.open(ModalStripeError, {
        message: getPaymentError(err),
        error: err
      });
      if (reset) this.resetStripeSecretClient();
    },
    // Lance le 3DS lié à la commande
    launchStripeOrder3DSAction(order, context) {
      return new Promise((resolve, reject) => {
        if (typeof this.setHeight === 'function') this.setHeight();

        const { paymentMethod } = order.stripePayment.currentStripePayment;
        this.$wait.start(STRIPE_PROCESSING_LOADER);

        const onEnd = (action) => {
          this.$wait.end(STRIPE_PROCESSING_LOADER);
          if (typeof this.resetHeight === 'function') this.resetHeight();
          if (typeof action === 'function') action();
        };

        this.initStripe(order)
          .then(() => this.confirmStripeCardPayment(paymentMethod))
          .then(({ paymentIntent }) => onEnd(() => resolve(paymentIntent)))
          .catch((err) => {
            this.onStripeError(err);
            onEnd(() => reject(err));
          });
      });
    }
  },
};
