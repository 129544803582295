<template>
  <div class="concept-how-it-works__boxes">
    <potager-row centered>
      <potager-col
        bp1024="5"
        bp1280="2.5"
        bp640="12"
        class="concept-how-it-works__boxes__item"
        col="3.5">
        <potager-picture
          class="concept-how-it-works__boxes__item__illu concept-how-it-works__boxes__item__illu--01 mx-auto"
          src="how-it-works-upselling"
          title="Nos fruits et légumes en circuit-court dans le petit marché" />
        <h3 class="concept-how-it-works__boxes__item__title">
          <potager-spotlight :fill="colors.bianca">
            Le petit marché
          </potager-spotlight>
        </h3>
        <p class="concept-how-it-works__boxes__item__subtitle">
          Le rayon des fruits et légumes à la pièce pour compléter votre panier hebdomadaire.
        </p>
        <p>
          Hop, ajoutez une barquette de framboises, un lot d’avocats BIO, ou renflouez votre placard d’essentiels en
          ajoutant des oignons rouges !
        </p>
      </potager-col>
      <potager-col
        bp1024="5"
        bp1280="2.5"
        bp640="12"
        class="concept-how-it-works__boxes__item"
        col="3.5"
        offset="0.5">
        <potager-picture
          class="concept-how-it-works__boxes__item__illu concept-how-it-works__boxes__item__illu--02 mx-auto"
          src="how-it-works-store"
          title="Nos fruits et légumes en circuit-court dans l'épicerie" />
        <h3 class="concept-how-it-works__boxes__item__title">
          <potager-spotlight :fill="colors.bianca">
            L'épicerie
          </potager-spotlight>
        </h3>
        <p class="concept-how-it-works__boxes__item__subtitle">
          Le rayon des produits secs et autres gourmandises&nbsp;!
        </p>
        <p>
          Farine BIO pour vos gâteaux, pots de confitures artisanales, huile d’olives AOP de Nyons ou paquet de granolas
          BIO, ajoutez à votre commande de la semaine les pépites de nos producteurs et artisans partenaires&nbsp;!
        </p>
      </potager-col>
      <potager-col
        bp1024="5"
        bp1280="2.5"
        bp640="12"
        class="concept-how-it-works__boxes__item"
        col="3.5"
        offset="0.5"
        offset-bp1024="0">
        <potager-picture
          class="concept-how-it-works__boxes__item__illu concept-how-it-works__boxes__item__illu--03 mx-auto"
          src="how-it-works-feature"
          title="Nos fruits et légumes en circuit-court dans les paniers coup de coeur" />
        <h3 class="concept-how-it-works__boxes__item__title">
          <potager-spotlight :fill="colors.bianca">
            Les paniers coups de coeur
          </potager-spotlight>
        </h3>
        <p class="concept-how-it-works__boxes__item__subtitle">
          Chaque semaine, des paniers éphémères préparés par l’équipe&nbsp;!
        </p>
        <p>
          Panier de fruits de fêtes, un panier spécial soupes ou un paniers de fruits rouges, complétez votre commande
          par ces sélections thématiques.
        </p>
      </potager-col>
    </potager-row>
  </div>
</template>

<script>
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerSpotlight from 'UI/PotagerSpotlight';

import COLORS from 'PotagerLogic/Enums/Colors';

const { bianca } = COLORS;

export default {

  components: {
    PotagerRow,
    PotagerCol,
    PotagerPicture,
    PotagerSpotlight,
  },

  data: () => ({
    colors: { bianca },
  }),

};
</script>

<style lang="scss">
.concept-how-it-works__boxes {
  margin-top: 3.25rem;
  @apply text-xxs;
  line-height: $baseLh;

  &__item {
    text-align: center;

    @include bp768() {
      margin-bottom: 3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__illu {
      margin-bottom: 2rem;
      display: inline-block;
      width: 100%;
      min-height: 6.5rem;

      @include bp640() {
        min-height: 0;
      }

      &--01 {
        max-width: 11.5rem;
      }

      &--02 {
        max-width: 13.125rem;
      }

      &--03 {
        max-width: 7.125rem;
      }
    }

    &__title {
      @apply text-base;
      line-height: $baseLh;
      font-weight: $weightExtrabold;
      margin-bottom: 1.25rem;
      white-space: nowrap;

      .potager-spotlight {
        padding: .125em .5em;
      }
    }

    &__subtitle {
      @apply text-xs.5;
      line-height: $baseLh;
      font-weight: $weightBold;
      margin-bottom: .5rem;
    }

  }
}
</style>
