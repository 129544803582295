<template>
  <panel
    :back-route="{ name: 'basket' }"
    title="Ajouter un code promo">
    <template #header>
      <coupon-input :context="Context.Basket" />
    </template>

    <div class="panel-section panel-section--transparent mt-0 mb-10">
      <div class="panel-section__separator m-0" />
    </div>

    <coupon-selector :context="Context.Basket" />

    <template #footer>
      <div class="flex items-center">
        <span
          v-if="showCouponTotal"
          class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ getValue }}</b>
        </span>

        <potager-button
          :is-loading="$wait.is(COUPONS_LOADERS)"
          :label="getSimulatedOrderCoupons?.length ? 'Appliquer' : 'Continuer'"
          :to="{ name: 'basket' }"
          full-width
          theme="go-green" />
      </div>
    </template>
  </panel>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import CouponInput from 'Components/coupons/CouponInput';
import CouponSelector from 'Components/coupons/CouponSelector.vue';

import { mapGetters } from 'vuex';
import { COUPONS_LOADERS } from 'Stores/subStores/User/UserCouponStore';

import MetaInfosService from 'Classes/services/MetaInfoService';

import { formatPrice } from 'PotagerLogic/Formatting';
import { Context } from 'PotagerLogic/Enums/Context';
import { CouponValueType } from 'PotagerLogic/Enums/Coupons';

export default {
  components: {
    CouponSelector,
    CouponInput,
    Panel,
    PotagerButton,
  },

  data() {
    return {
      Context,
      COUPONS_LOADERS,
    };
  },

  computed: {
    ...mapGetters('basket', [
      'getSimulatedOrderCoupons',
      'getSimulatedOrderCouponsTotal',
    ]),
    showCouponTotal() {
      const haveProductTypeCoupon = this.getSimulatedOrderCoupons?.some((coupon) => coupon.couponValueType === CouponValueType.Product);
      return !haveProductTypeCoupon && this.getSimulatedOrderCoupons?.length;
    },
    getValue() {
      return `-${formatPrice(this.getSimulatedOrderCouponsTotal)}`;
    },
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),
};
</script>
