<template>
  <panel
    :back-route="backRoute"
    title="Application automatique">
    <coupon-automated
      ref="couponAutomated"
      @configUpdated="onCouponAutomatedUpdate" />

    <template #footer>
      <div class="flex gap-4">
        <potager-button
          :is-loading="$wait.is('UPDATE_COUPON_AUTOMATED_CONFIGURATION_ACTION')"
          full-width
          label="Valider"
          @onClick="valid" />

        <potager-button
          :to="backRoute"
          full-width
          label="Annuler"
          theme="stroke" />
      </div>
    </template>
  </panel>
</template>

<script>
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import CouponAutomated from 'Components/coupons/CouponAutomated';

import { mapGetters } from 'vuex';
import MetaInfosService from 'Classes/services/MetaInfoService';
import ModalInfo from 'Modals/ModalInfo';

export default {

  components: {
    Panel,
    PotagerButton,
    CouponAutomated,
  },

  data() {
    return {
      backRoute: { name: 'mon-compte_coupons' },
    };
  },

  computed: {
    ...mapGetters('user', [
      'hasSubscriptionEnterpriseDiscount',
      'getSubscriptionEnterpriseDiscountValue',
    ]),
    enterpriseDiscount() {
      if (this.hasSubscriptionEnterpriseDiscount) {
        return {
          couponValue: this.getSubscriptionEnterpriseDiscountValue,
          couponValueType: 'VALUE',
          couponCode: 'Avantage entreprise',
        };
      }
      return null;
    },
  },

  methods: {
    onCouponAutomatedUpdate(resp) {
      if (resp.data.data.coupons.length > 0) {
        this.openConfirmAutoCoupon();
      } else {
        this.$notify({
          type: 'success',
          title: 'Coupons mis à jour',
          text: 'Vos coupons ont bien été mis à jour.',
        });
      }
      this.$router.push(this.backRoute)
        .catch(() => {
        });
    },
    openConfirmAutoCoupon() {
      this.$modal.open(ModalInfo, {
        title: 'Commandes en cours',
        text: `Notre robot a détecté que vous aviez déjà une ou plusieurs
          commande(s) en cours. Il y a appliqué au moins un coupon. Vous
          pouvez l’apercevoir dans le détail de votre commande. Vous avez
          jusqu'à l’avant-veille du jour de livraison pour modifier les
          coupons appliqués par le robot si son choix ne vous convient pas.`,
        closeBtnText: 'Mes commandes',
        cancelBtnText: 'Fermer',
        showCancel: true,
        onCloseRedirect: { name: 'mon-compte_orders' },
      });
    },
    valid() {
      this.$refs.couponAutomated.onConfigOk();
    },
  },

  head: MetaInfosService.generate({
    title: 'Coupons',
  }),

};
</script>
