export const UPDATE_WOOS_SCRIPT_LOADED = 'UPDATE_WOOS_SCRIPT_LOADED';
export const UPDATE_WOOS_MAP = 'UPDATE_WOOS_MAP';
export const UPDATE_WOOS_STORE_OVERLAY = 'UPDATE_WOOS_STORE_OVERLAY';
export const UPDATE_WOOS_DATA = 'UPDATE_WOOS_DATA';
export const UPDATE_WOOS_SEARCH_RESULT = 'UPDATE_WOOS_SEARCH_RESULT';
export const UPDATE_USER_SEARCH = 'UPDATE_USER_SEARCH';
export const RESET_USER_SEARCH = 'RESET_USER_SEARCH';
export const UPDATE_USER_POSITION = 'UPDATE_USER_POSITION';
export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT';
export const UPDATE_SELECTED_DELIVERY_POINT = 'UPDATE_SELECTED_DELIVERY_POINT';
export const UPDATE_PUBLIC_SEARCH_RESULT = 'UPDATE_PUBLIC_SEARCH_RESULT';
export const UPDATE_PRIVATE_SEARCH_RESULT = 'UPDATE_PRIVATE_SEARCH_RESULT';
export const UPDATE_PUBLIC_DELIVERY_POINTS = 'UPDATE_PUBLIC_DELIVERY_POINTS';
export const TOGGLE_LIST_VISIBILITY = 'TOGGLE_LIST_VISIBILITY';
export const SET_FILTERS = 'SET_FILTERS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_USER_MARKER = 'UPDATE_USER_MARKER';
export const UPDATE_STORE_MARKER = 'UPDATE_STORE_MARKER';
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const UPDATE_MAP_ZOOM = 'UPDATE_MAP_ZOOM';
