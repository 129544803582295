<template>
  <div class="text-left">
    <template v-if="!isLoading">
      <dlp-card
        v-for="(dlp, j) in getPrivateSearchResult"
        :key="j"
        :context="context"
        :delivery-point="dlp"
        :disabled="isLoading"
        show-gdds
        with-icon
        @onClick="onCardClick" />
    </template>

    <template v-else-if="isLoading">
      <div class="flex items-center justify-center">
        <icon-ptcy-loader-spinner class="text-4xl" />
      </div>
    </template>

    <dlp-search-no-result looking-for="privates" />

    <template v-if="showPrivateForm">
      <p
        v-if="getPrivateSearchResult || !getUserSearch"
        class="mt-8 mb-2 text-xs.5 font-extrabold">
        Vous ne trouvez pas votre entreprise&nbsp;?
      </p>

      <p :class="['text-xs.5 mb-4 max-w-1280', { 'mt-4': !getPrivateSearchResult }]">
        Suggérez-nous votre entreprise et nous pourrons créer un point de retrait associé
        pour effectuer les livraisons directement sur votre lieu de travail. Sympa, non&nbsp;?&nbsp;😀
      </p>

      <p class="mb-8">
        <potager-button
          :to="{ name: 'b2b-box' }"
          size="small"
          theme="stroke">
          Suggérer mon entreprise
        </potager-button>

        <potager-tooltip
          :disable="$mq.bp768"
          :spacing="4"
          :width="256"
          theme="dark">
          <template #message>
            En savoir plus sur le traitement de vos données personnelles
          </template>

          <potager-button
            is-square
            size="small"
            theme="transparent"
            @onClick="showCgv">
            <template #icon>
              <icon-information-circle class="text-lg" />
            </template>
          </potager-button>
        </potager-tooltip>
      </p>
    </template>

    <slot name="footer" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  UPDATE_USER_SEARCH,
  UPDATE_USER_POSITION,
  RESET_USER_SEARCH,
} from 'Stores/types/mapMutationsTypes';

import PotagerButton from 'UI/PotagerButton';
import PotagerTooltip from 'UI/PotagerTooltip';

import IconInformationCircle from 'Icons/regular/IconInformationCircle';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

import DlpSearchNoResult from 'Components/deliveryPoint/DlpSearchNoResult';
import DlpCard from 'Components/deliveryPoint/DlpCard';

import {
  SEARCH_PRIVATES_DELIVERY_POINTS,
} from 'Stores/types/mapActionsTypes';
import ModalCgvForm from 'Modals/ModalCgvForm';
import { Context } from 'PotagerLogic/Enums/Context';

export default {

  components: {
    IconInformationCircle,
    IconPtcyLoaderSpinner,
    PotagerButton,
    PotagerTooltip,
    DlpCard,
    DlpSearchNoResult,
  },

  emits: ['onSelect'],

  props: {
    context: {
      type: String,
      required: false,
      default: Context.Basket,
    },
    showPrivateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getUserSearch',
      'getPrivateSearchResult',
    ]),
    isLoading() {
      return this.$wait.is(SEARCH_PRIVATES_DELIVERY_POINTS);
    },
  },

  methods: {
    ...mapMutations('map', {
      RESET_USER_SEARCH,
      UPDATE_USER_SEARCH,
      UPDATE_USER_POSITION,
    }),
    retry() {
      this.RESET_USER_SEARCH();
    },
    showCgv() {
      this.$modal.open(ModalCgvForm);
    },
    onCardClick(deliveryPoint) {
      if (deliveryPoint?.type === 'gmap') {
        this.$nextTick(() => {
          this.UPDATE_USER_SEARCH({ search: deliveryPoint.name.replace(', France', '') });
        });
      }
      this.$emit('onSelect', deliveryPoint);
    },
  },
};
</script>
