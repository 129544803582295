<template>
  <potager-modal>
    <template #title>
      Suppression du compte
    </template>

    <template #body>
      <p>
        Pour confirmer votre souhait de supprimer votre compte Potager City, entrez votre mot de passe ici ⬇️
      </p>

      <potager-form
        ref="form"
        id="delete-account"
        @onSubmit="deleteAccount">
        <potager-input
          ref="password"
          v-model="password"
          :constraints="[
            { constraint: 'required', field: 'password' },
          ]"
          class="-mb-0"
          name="password"
          no-password-validator
          placeholder="Mot de passe"
          type="password" />
      </potager-form>

      <potager-info
        v-if="error && error.message"
        size="small"
        status="warning">
        <template #icon>
          <icon-road-sign-warning />
        </template>

        {{ error.message }}
      </potager-info>
    </template>

    <template #footer>
      <div class="flex flex-col">
        <potager-button
          :is-disabled="!password"
          :is-loading="loading"
          full-width
          label="Supprimer mon compte"
          theme="carnation"
          @onClick="submitForm" />

        <potager-button
          :is-disabled="loading"
          class="ml-0 mt-4"
          full-width
          label="Annuler"
          theme="stroke"
          @onClick="close" />
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerForm from 'UI/Form/PotagerForm';

import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';
import { mapActions } from 'vuex';

import { LOGOUT_USER_ACTION, RESET_SESSION_ACTION } from 'Stores/types/sessionActionsTypes';

export default {

  components: {
    PotagerForm,
    PotagerInfo,
    PotagerInput,
    PotagerModal,
    PotagerButton,
    IconRoadSignWarning,
  },
  data() {
    return {
      loading: false,
      error: undefined,
      password: undefined,
    };
  },

  watch: {
    password() {
      this.error = undefined;
    },
  },

  methods: {
    ...mapActions('session', {
      logout: LOGOUT_USER_ACTION,
      resetSession: RESET_SESSION_ACTION,
    }),
    submitForm() {
      this.$refs.form.onSubmit();
    },
    deleteAccount() {
      this.loading = true;

      this.$api.user.deactivateAccount(this.password)
        .then(() => this.onSuccess())
        .catch((err) => {
          const statusCode = err.response.status;
          let message = 'Une erreur est survenue. Veuillez réessayer.';

          if (statusCode === 403) {
            message = 'Le mot de passe est incorrect.';
          } else if (statusCode === 400) {
            message = 'Vous avez une commande livrée dans moins de 48h. Vous ne pouvez pas supprimer votre compte client avant cette livraison.';
          }

          this.error = {
            statusCode,
            message,
          };
        })
        .finally(() => this.loading = false);
    },
    onSuccess() {
      this.close();

      this.logout();
      this.resetSession();

      this.$notify({
        type: 'success',
        title: 'À bientôt !',
        text: 'Votre compte Potager City et vos données personnelles ont été supprimés définitivement.',
      });

      this.$router.push({ name: 'home' });
    },
    close() {
      this.$modal.close();
      if (typeof this.callback === 'function') this.callback();
    },
  },
};
</script>
