<template>
  <card-product
    :box="product"
    :context="context"
    price-category="subscription"
    read-only>
    <template #layout-after>
      <potager-button
        :is-loading="isLoading"
        class="mt-4"
        theme="go-green"
        @onClick="$emit('onSubmit', product)">
        Choisir ce panier
      </potager-button>

      <div class="text-center">
        <potager-button
          class="mt-4 text-xs.5 font-semibold underline"
          theme="reset"
          @onClick="onDetails">
          Composition de la semaine
        </potager-button>
      </div>
    </template>
  </card-product>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_SUBSCRIPTION_PRODUCT_ACTION } from 'Stores/types/userActionsTypes';
import { UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import { formatPrice } from 'PotagerLogic/Formatting';
import { getProductPackagingMinimalWeight } from 'PotagerLogic/Utils/Box/ProductPackaging';
import { Context } from 'PotagerLogic/Enums/Context';

import PotagerButton from 'UI/PotagerButton';

import CardProduct from 'Components/cards/CardProduct';

import ModalProductsList from 'Modals/ModalProductsList';

export default {
  components: {
    CardProduct,
    PotagerButton,
  },
  emits: ['onSubmit'],

  data: () => ({
    categorySelectedId: -1,
  }),

  props: {
    product: {
      type: Object,
      required: true,
    },
    selectedRef: {
      type: String,
      required: false,
      default: undefined,
    },
    context: {
      type: String,
      default: undefined,
      validator: (value) => Object.values(Context)
        .includes(value),
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    ...mapGetters('session', [
      'getOacRecommendedRef',
    ]),
    isLoading() {
      const id = this.product?.id;
      return this.$wait.is([
        `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}_${id}`,
        `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}_${id}`,
      ]);
    },
    isDisabled() {
      return this.$wait.is([
        `${UPDATE_SUBSCRIPTION_PRODUCT_ACTION}`,
        `${UPDATE_SUBSCRIPTION_BASKET_CUSTOMER_SUBSCRIPTION_ACTION}`,
      ]);
    },
    modalTitle() {
      return `<span class="font-normal">Cette semaine, retrouvez dans le </span><br>${this.product.name.toLowerCase()}`;
    },
  },

  methods: {
    formatPrice,
    getProductPackagingMinimalWeight,
    onClick() {
      if (this.selectedRef) this.$events.emit('request:tunnelBoxOpenComposition');
    },
    onDetails() {
      this.$modal.open(ModalProductsList, {
        title: this.modalTitle,
        items: this.product?.products,
        btnLabel: 'Choisir ce panier',
        btnTheme: 'go-green',
        btnCallback: () => this.$emit('onSubmit', this.product),
      });
    },
  },
};
</script>
