<template>
  <tunnel-body
    :is-ready="ready"
    size="wide">
    <potager-container class="max-w-6/12">
      <tunnel-box-message />
    </potager-container>

    <tunnel-box-slider
      :categories="enhancedCategories"
      :initial-slide="getInitialSlide"
      class="mb-8"
      @onSubmit="onSubmit"
      @setSelectedRef="setSelectedRef" />

    <tunnel-box-reassurance />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_PRODUCT_SELECTORS_V2_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import GtmMixin from 'Mixins/GtmMixin';
import SubscriptionModalMixin from 'Mixins/SubscriptionModalMixin';
import TunnelSubscriptionMixin from 'Mixins/TunnelSubscriptionMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelBoxMessage from 'Components/tunnel/TunnelBoxMessage';
import TunnelBoxSlider from 'Components/tunnel/TunnelBoxSlider';
import TunnelBoxReassurance from 'Components/tunnel/TunnelBoxReassurance';

import {
  DEFAULT_PRODUCT_REFERENCE,
  DEFAULT_REGION_ID,
  PMEL,
  MEL,
  SPMEL, MELBIO, PTMEL, PLEG, FRU, GMELN,
} from 'Classes/Constants';

import MetaInfoService from 'Classes/services/MetaInfoService';

import PotagerContainer from 'UI/PotagerContainer';

export default {

  mixins: [
    GtmMixin,
    SubscriptionModalMixin,
    TunnelSubscriptionMixin,
  ],

  components: {
    PotagerContainer,
    TunnelBody,
    TunnelBoxMessage,
    TunnelBoxSlider,
    TunnelBoxReassurance,
  },

  data: () => ({
    ready: false,
    initRef: undefined,
    categories: [],
  }),

  computed: {
    ...mapGetters('session', [
      'getRegionId',
    ]),
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    regionId() {
      return this.getRegionId || this.$route.params.regionId || DEFAULT_REGION_ID;
    },
    getRef() {
      return this.$route.params.ref === MEL ? PMEL : this.$route.params.ref;
    },
    getCatRef() {
      return this.categories
        .find((cat) => cat.variants
          .find((format) => format.product.productReference === (this.getRef || DEFAULT_PRODUCT_REFERENCE)))?.ref;
    },
    getCurrentCategory() {
      return this.categories
        .find((cat) => cat.ref === this.getCatRef);
    },
    getInitialSlide() {
      return this.initRef ? this.categories?.findIndex((cat) => cat.ref === this.initRef) : undefined;
    },
    enhancedCategories() {
      if (!this.categories) return [];

      const productReferenceOrder = [PMEL, MELBIO, PTMEL, PLEG, FRU, GMELN, SPMEL];

      return this.categories
        .flatMap(category => category.variants
          .map(variant => variant.product))
        .sort((a, b) => productReferenceOrder.indexOf(a.productReference) - productReferenceOrder.indexOf(b.productReference));
    },
  },

  watch: {
    regionId: {
      handler(val) {
        if (val) {
          // change route regionId param to match regionId
          // and fetch categories
          // tunnelBoxGuard is supposed to manage this,
          // BUT in webview, we don't have the regionId soon enough
          this.$router.replace({
            ...this.$route,
            params: {
              ...this.$route.params,
              regionId: val,
            },
          });

          this.fetchCategories();
        }
      },
      immediate: true,
    },
  },

  methods: {
    fetchCategories() {
      this.$store.dispatch(`subscriptionBasket/${FETCH_PRODUCT_SELECTORS_V2_ACTION}`, {
        regionId: this.regionId,
      })
        .then(({ data }) => {
          this.categories = data
            .map((cat) => {
              let selectedRef;
              if (cat.variants.length === 1) {
                selectedRef = cat.variants[0].product.productReference;
              } else if (this.getRef && cat.variants
                .find((v) => v.product.productReference === this.getRef)) {
                selectedRef = this.getRef;
              } else {
                selectedRef = cat.variants[0].product.productReference;
              }

              return {
                ...cat,
                selectedRef,
              };
            });

          this.initRef = this.getRef || this.getCatRef;
          this.ready = true;
        });
    },
    setSelectedRef(ref, catRef) {
      this.categories = this.categories
        .map((cat) => {
          if (cat.ref === catRef) {
            return {
              ...cat,
              selectedRef: ref,
            };
          }
          return cat;
        });

      const newRoute = {
        name: this.$route.name,
        params: {},
      };

      if (ref) newRoute.params.ref = ref;
      if (this.$route.params.ref !== ref) this.$router.replace(newRoute);
    },
    findCatByProductRef(ref) {
      return this.categories
        .find((c) => c.variants.find((format) => format.product.productReference === ref));
    },
    onSubmit(box) {
      this.setSelectedRef(box.productReference);
      this.submitNewSubscription(box, this.findCatByProductRef(box.productReference)?.productOptions.length > 0);
    },
  },

  head() {
    return this.getCurrentCategory ? MetaInfoService.generate({
      title: this.getCurrentCategory.name,
      description: this.getCurrentCategory.description,
    }) : {};
  },
};
</script>
