import { Context } from 'PotagerLogic/Enums/Context';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  const subscriptionBasketAddress = rootGetters['session/getAddress'](Context.SubscriptionBasket);
  const subscriptionAddress = rootGetters['session/getAddress'](Context.Subscription);
  const address = subscriptionBasketAddress || subscriptionAddress;

  return !!address === expectedValue;
};

export default {
  type: 'hasHomeDeliveryAddress',
  values: [true, false],
  test,
};
