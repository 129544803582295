<template>
  <potager-background
    :src="background"
    class="push-download-app"
    seo
    title="Paniers de fruits et légumes en direct des producteurs">
    <potager-container>
      <potager-row>
        <potager-col
          bp768="12"
          col="4"
          offset="1"
          offset-bp768="0">
          <p class="push-download-app__title">
            Service approuvé par plus de 100&nbsp;000 clients et 2&nbsp;000 entreprises
          </p>
        </potager-col>

        <potager-col
          bp768="12"
          class="push-download-app__store-wrapper"
          col="5"
          offset="1"
          offset-bp768="0">
          <div class="push-download-app__store">
            <potager-app-button store="google">
              <potager-ratings :score="4.7">
                Moyenne de notre<br>
                app sur Google Play
              </potager-ratings>
            </potager-app-button>
          </div>
          <div class="push-download-app__store">
            <potager-app-button store="apple">
              <potager-ratings :score="4.7">
                Moyenne de notre<br>
                app sur l'App Store
              </potager-ratings>
            </potager-app-button>
          </div>
        </potager-col>
      </potager-row>
    </potager-container>

    <potager-wave
      :fill="colors.bianca"
      :height="['1rem', '.75rem']"
      :index="13"
      :modifiers="['on-top', 'invert-xy']" />
  </potager-background>
</template>

<script>
import PotagerAppButton from 'UI/PotagerAppButton';
import PotagerBackground from 'UI/PotagerBackground';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerWave from 'UI/PotagerWave';
import PotagerRatings from 'UI/PotagerRatings';

import COLORS from 'PotagerLogic/Enums/Colors';

const { bianca } = COLORS;

export default {

  components: {
    PotagerAppButton,
    PotagerBackground,
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    PotagerWave,
    PotagerRatings,
  },

  data: () => ({
    colors: { bianca },
    background: 'background-download-app',
  }),

};
</script>
