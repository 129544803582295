/**
 * Convert an address object to a parameter object.
 *
 * @param {Object} address - The address object.
 * @param {string} address.city - The city of the address.
 * @param {string} address.comment - Additional comments for the address.
 * @param {string} address.company - The company name associated with the address.
 * @param {string} address.firstname - The first name of the person associated with the address.
 * @param {string} address.lastname - The last name of the person associated with the address.
 * @param {string} address.note - Additional notes for the address.
 * @param {string} address.phone - The phone number associated with the address.
 * @param {string} address.postalCode - The postal code of the address.
 * @param {string} address.streetName - The street name of the address.
 * @returns {Object|undefined} - The parameter object or undefined if the address is not provided.
 */
export const convertAddressToParameter = (address) => (address ? {
  addressCity: address.city || address.addressCity,
  addressComment: address.comment || address.addressComment,
  addressCompany: address.company || address.addressCompany,
  addressFirstname: address.firstname || address.addressFirstname,
  addressLastname: address.lastname || address.addressLastname,
  addressNote: address.note || address.addressNote,
  phone: address.phone || address.phone,
  addressPostcode: address.postalCode || address.addressPostcode, // addressStreetNumber: address.streetNumber || address.addressStreetNumber,  <--- will be used after BO update to split street and number
  addressStreetName: address.streetName || address.addressStreetName,
} : undefined);
