<template>
  <tunnel-body>
    <dlp-search-private
      :context="context"
      class="mb-4 pt-8 border-porcelain border-0 border-b border-solid pb-4 mt-4 bp1024:mt-2" />

    <dlp-private-list
      :context="context"
      show-private-form
      @onSelect="goNextStep" />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import TunnelBody from 'Components/tunnel/TunnelBody';
import DlpPrivateList from 'Components/deliveryPoint/step3/DlpPrivateList';
import DlpSearchPrivate from 'Components/deliveryPoint/step2/DlpSearchPrivate';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  components: {
    DlpSearchPrivate,
    DlpPrivateList,
    TunnelBody,
  },

  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  computed: {
    ...mapGetters('tunnel', ['getNextStepRoute']),
  },

  methods: {
    goNextStep(dlp) {
      this.$router.push(this.getNextStepRoute({
        params: {
          deliveryPointRegionId: dlp.regionId,
          deliveryPointId: dlp.id,
        },
      }));
    },
  },
};
</script>
