<template>
  <template v-if="dlpList.length">
    <dlp-card
      v-for="(deliveryPoint, index) in dlpList"
      :key="index"
      :context="context"
      :delivery-point="deliveryPoint"
      show-gdds
      @onClick="$emit('onSelect', deliveryPoint)" />

    <div
      v-intersect="onBottom"
      class="bottom-trigger h-1 w-full" />
  </template>

  <div
    v-else
    class="flex flex-col items-center justify-center text-center h-full">
    <h3 class="text-lg font-bold text-charcoal">
      Chou blanc !
    </h3>

    <p class="text-sm text-charcoal">
      Aucun point relais n'a été trouvé. Veuillez modifier votre recherche.
    </p>
    <br>
    <dlp-suggest />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DlpCard from 'Components/deliveryPoint/DlpCard';
import DlpSuggest from 'Components/deliveryPoint/DlpSuggest.vue';

export default {
  components: {
    DlpSuggest,
    DlpCard
  },

  emits: ['onSelect', 'onBottom'],

  data() {
    return {
      dlpPerPage: 10,
      page: 1,
    };
  },

  props: {
    context: {
      type: String,
      required: true,
    },
  },

  watch: {
    getMapPublicSearchResult: {
      handler() {
        this.page = 1;
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getMapPublicSearchResult',
    ]),
    dlpList() {
      if (this.page < this.getMapPublicSearchResult?.length / this.dlpPerPage) {
        return this.getMapPublicSearchResult
          .slice(0, this.dlpPerPage * this.page);
      }
      return this.getMapPublicSearchResult;
    },
  },

  methods: {
    onBottom(isVisible) {
      if (isVisible && !this.isLoading && this.page * this.dlpPerPage < this.getMapPublicSearchResult.length) {
        this.page += 1;
      }

      this.$emit('onBottom', this.page);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
