<template>
  <div class="order-resume">
    <div
      :class="{
        'order-resume__status-item': true,
        'order-resume__status-item--warning': isWarning,
      }">
      <template v-if="isCanceled">
        <icon-delete1 />
        <span class="order-resume__status-item__label">Annulée</span>
      </template>

      <template v-else-if="isRefunded">
        <icon-saving-piggy-coins-alternate />
        <span class="order-resume__status-item__label">Remboursée</span>
      </template>

      <template v-else-if="isUnpayed">
        <icon-credit-card-remove />
        <span class="order-resume__status-item__label">Non payée</span>
      </template>

      <template v-else-if="isWaiting">
        <icon-alert-triangle />
        <span class="order-resume__status-item__label">
          <template v-if="order.stripePayment.neededAction === '3ds'">
            En attente d'authentification
          </template>
          <template v-else>
            En attente d'autorisation
          </template>
        </span>
      </template>

      <template v-else-if="isValidated">
        <icon-task-list-clock />
        <span class="order-resume__status-item__label">En cours</span>
      </template>

      <template v-else-if="isPreparation">
        <icon-shopping-bag-download />
        <span class="order-resume__status-item__label">
          <template v-if="isPreparated">
            Préparée
          </template>
          <template v-else>
            En attente de préparation
          </template>
        </span>
      </template>

      <template v-else-if="isPayed">
        <icon-credit-card-check />
        <span class="order-resume__status-item__label">Payée</span>
      </template>

      <template v-else-if="isDelivered">
        <icon-delivery-truck2 />
        <span class="order-resume__status-item__label">
          Livrée
          <template v-if="getSendingDate">
            le {{ getSendingDate }}
          </template>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { fromUnixTime, isToday } from 'date-fns';

import {
  VALIDATED,
  PAYED,
  PREPARATION,
  DELIVERED,
  REFUNDED,
  UNPAYED,
} from 'Classes/OrderStatus';
import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

import IconDelete1 from 'Icons/regular/IconDelete1';
import IconCreditCardCheck from 'Icons/regular/IconCreditCardCheck';
import IconSavingPiggyCoinsAlternate from 'Icons/regular/IconSavingPiggyCoinsAlternate';
import IconCreditCardRemove from 'Icons/regular/IconCreditCardRemove';
import IconShoppingBagDownload from 'Icons/regular/IconShoppingBagDownload';
import IconDeliveryTruck2 from 'Icons/regular/IconDeliveryTruck2';
import IconAlertTriangle from 'Icons/regular/IconAlertTriangle';
import IconTaskListClock from 'Icons/regular/IconTaskListClock';

import { isOrderCanceledStatus } from 'PotagerLogic/Utils/Order/OrderStatus';

export default {

  components: {
    IconDelete1,
    IconCreditCardCheck,
    IconSavingPiggyCoinsAlternate,
    IconCreditCardRemove,
    IconShoppingBagDownload,
    IconDeliveryTruck2,
    IconAlertTriangle,
    IconTaskListClock,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isCanceled() {
      return isOrderCanceledStatus(this.order);
    },
    isRefunded() {
      return this.order.frontStatus === REFUNDED;
    },
    isUnpayed() {
      return this.order.frontStatus === UNPAYED;
    },
    isWaiting() {
      return this.order.stripePayment
        && this.order.stripePayment.isPayable
        && !this.order.stripePayment.canCustomerWaitForBatch;
    },
    isValidated() {
      return this.order.frontStatus === VALIDATED;
    },
    isPreparation() {
      return this.order.frontStatus === PREPARATION;
    },
    isPreparated() {
      return this.isPreparation && isToday(fromUnixTime(this.order.timeSlot.date));
    },
    isPayed() {
      return this.order.frontStatus === PAYED;
    },
    isDelivered() {
      return this.order.frontStatus === DELIVERED;
    },
    getSendingDate() {
      if (!this.order || !this.order.timeSlot || !this.order.timeSlot.date) return null;
      return tmsToFormat(this.order.timeSlot.date, 'iiii d MMMM yyyy');
    },
    isWarning() {
      return this.isUnpayed || this.isWaiting;
    },
  },

  methods: {
    total(order) {
      return getOrderPrice(order)?.total || 0;
    },
  },

};

</script>
