import { inAppWebView } from 'Classes/ConfigurationManager';

export const postMessage = (options) => {
  if (inAppWebView()) {
    const data = typeof options === 'object' ? {
      ...options,
      ...(localStorage.getItem('token') && { token: localStorage.getItem('token') })
    } : options;

    const mockData = typeof data === 'object'
      ? data.token ? {
        ...data,
        token: '***'
      } : data
      : data;

    if (window.ReactNativeWebView?.postMessage && typeof window.ReactNativeWebView.postMessage === 'function') {
      console.info('ReactNativeWebView postMessage', mockData);
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else if (window.postMessage && typeof window.postMessage === 'function') {
      console.info('postMessage', mockData);
      window.postMessage(JSON.stringify(data), '*');
    } else {
      console.error('postMessage not available');
    }
  }
};

export const redirectWebApp = (options) => {
  if (options) postMessage(options);
  postMessage('closeWebView');
};

export const loginInApp = () => {
  postMessage({});
};

export default {
  computed: {
    isInAppWebView() {
      return inAppWebView();
    },
    isInFacebookApp() {
      const ua = navigator.userAgent || navigator.vendor;
      return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
    },
  },

  methods: {
    postMessage,
    redirectWebApp,
    loginInApp,
  },
};
