import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('user', [
      'getSubscriptionOrder',
      'getNextDeliveredTimeslot',
      'isSubscriptionActive',
      'getNextGenerationDate',
    ]),
    isSubscriptionOrderNotYetCreated() {
      return this.getNextGenerationDate && !this.getSubscriptionOrder;
    },
    getNextSubscriptionOrderDeliveryDate() {
      return (this.getSubscriptionOrder?.timeSlot || this.getNextDeliveredTimeslot)?.date;
    },
  },
};
