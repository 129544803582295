<template>
  <section
    v-if="showSection || !isLoaded"
    class="overflow-hidden">
    <potager-container class="py-10">
      <div class="flex justify-between items-center mb-9">
        <p
          v-if="isLoaded"
          :class="[
            'font-bold text-3xl bp1024:text-2xl.5',
            {
              'h-8 w-1/3 skeleton mb-9': !isLoaded,
            }
          ]"
          v-html="getHomepageSection.title" />

        <div
          v-else
          class="h-8 w-1/3 skeleton" />

        <div
          v-if="!$mq.bp768"
          class="flex items-center">
          <potager-button
            :class="[
              'mr-3',
              `hidden home-page-highlighted-products__nav-prev--${sectionIndex}`
            ]"
            is-circle
            theme="stroke">
            <icon-arrow-left class="text-xs" />
          </potager-button>

          <potager-button
            :class="[
              `hidden home-page-highlighted-products__nav-next--${sectionIndex}`
            ]"
            is-circle
            theme="stroke">
            <icon-arrow-right class="text-xs" />
          </potager-button>
        </div>
      </div>

      <div class="w-full rounded-md overflow-hidden bp840:overflow-visible">
        <potager-slider
          ref="slider"
          :items="sliderItems"
          :settings="{
            spaceBetween: 16,
            navigation: {
              nextEl: `.home-page-highlighted-products__nav-next--${sectionIndex}`,
              prevEl: `.home-page-highlighted-products__nav-prev--${sectionIndex}`,
            },
          }"
          :slides-per-view="{
            default: 'auto',
            768: 3,
            840: 4,
            1168: 5,
          }"
          name="homepage-highlighted-products"
          overflow-visible>
          <template #default="{item}">
            <dlp-selection-card
              v-if="item.dlpCard && !getDeliveryPoint && sectionIndex === 0"
              :skeleton="!isLoaded"
              class="bp768:w-[229px]" />

            <card-thumbnail
              v-else-if="item.itemType === DashboardItemType.Thumbnail"
              :article="item"
              small />

            <card-product
              v-else
              :box="item"
              :skeleton="item.loading"
              class="h-full bp768:w-[229px]"
              no-lazy-load />
          </template>
        </potager-slider>
      </div>
    </potager-container>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerButton from 'UI/PotagerButton';

import CardProduct from 'Components/cards/CardProduct';
import DlpSelectionCard from 'Components/deliveryPoint/DlpSelectionCard';
import CardThumbnail from 'Components/cards/CardThumbnail';

import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconArrowRight from 'Icons/bold/IconArrowRight';

import { DashboardItemType } from 'potagerlogic/src/Enums/Dashboard';

export default {
  props: {
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },

  components: {
    CardThumbnail,
    PotagerButton,
    DlpSelectionCard,
    CardProduct,
    PotagerContainer,
    PotagerSlider,
    IconArrowLeft,
    IconArrowRight,
  },

  data() {
    return {
      DashboardItemType,
    };
  },

  computed: {
    ...mapGetters('dashboard', [
      'getHomepageSections',
      'isLoaded',
    ]),
    ...mapGetters('basket', [
      'getDeliveryPoint',
    ]),
    getHomepageSection() {
      return this.getHomepageSections[this.sectionIndex];
    },
    sliderItems() {
      const array = [];
      const loadingCard = { loading: true };
      const minNbOfItems = this.getDeliveryPoint ? 5 : 4;

      if (!this.getDeliveryPoint && !this.$mq.bp768 && this.sectionIndex === 0) array.push({ dlpCard: true });

      if (this.getHomepageSection?.items) {
        array.push(...this.getHomepageSection.items);
        while (array.length < minNbOfItems) array.push(loadingCard);
      } else {
        array.push(...Array(minNbOfItems + 1)
          .fill(loadingCard));
      }

      return array;
    },
    showSection() {
      return this.getHomepageSection && this.sliderItems.filter((item) => !item.loading).length > 0;
    },
  },
};
</script>
