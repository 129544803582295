import { mapGetters } from 'vuex';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
      'getEditableOrders',
    ]),
    getParams() {
      return this.$potagerRoute?.params || this.$route.params;
    },
    getEditableFilteredOrders() {
      return this.getEditableOrders.filter((order) => order.id.toString() !== this.getParams.orderId);
    },
    canUpdateSubscription() {
      return this.isSubscriptionActive && this.context !== Context.Subscription && !this.newGroupDeliveryDay?.isFullForSubscription;
    },
    canUpdateBasket() {
      return [Context.Subscription, Context.Basket, Context.User, Context.UserRegistration].indexOf(this.context) === -1;
    },
    canUpdateOrders() {
      return this.getEditableFilteredOrders.length > 0;
    },
    canUpdateSomething() {
      return this.canUpdateSubscription || this.canUpdateOrders || this.canUpdateBasket;
    },
  },
};
