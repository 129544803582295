<template>
  <panel
    :title="getFirstName ? `${capitalize(getFirstName)} ${capitalize(getLastName)}.` : 'Mon compte'"
    theme="white">
    <account-sponsorship-section />

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_orders' }">
      <template #icon>
        <span class="relative">
          <icon-shopping-bag-check class="text-go-green" />
          <potager-badge
            v-if="nbActiveOrders"
            class="absolute top-0 -right-2"
            rounded
            size="mini"
            theme="british-racing">
            {{ nbActiveOrders }}
          </potager-badge>
        </span>
      </template>
      Mes commandes
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_subscription' }">
      <template #icon>
        <icon-calendar-sync class="text-go-green" />
      </template>
      Mon abonnement
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_recipes' }">
      <template #icon>
        <icon-love-it class="text-go-green" />
      </template>
      Mes recettes enregistrées
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_info_perso' }">
      <template #icon>
        <icon-single-neutral-circle class="text-go-green" />
      </template>
      Mes informations
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_payment-methods' }">
      <template #icon>
        <icon-credit-card class="text-go-green" />
      </template>
      Mes moyens de paiement
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_coupons' }">
      <template #icon>
        <span class="relative">
          <icon-ticket class="text-go-green" />

          <potager-badge
            v-if="getUserCouponsExceptLuncheon.length"
            class="absolute top-0 -right-2"
            rounded
            size="mini"
            theme="british-racing">
            {{ getUserCouponsExceptLuncheon.length }}
          </potager-badge>
        </span>
      </template>

      Mes codes promo
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_notif' }">
      <template #icon>
        <icon-alarm-bell class="text-go-green" />
      </template>
      Mes notifications
    </potager-navigator>

    <potager-navigator
      v-gtm
      :to="{ name: 'mon-compte_settings' }">
      <template #icon>
        <icon-cog class="text-go-green" />
      </template>
      Paramètres & confidentialité
    </potager-navigator>

    <div class="px-6 text-sm.5">
      <div class="flex gap-3 mt-4 mb-8">
        <potager-link
          v-gtm
          class="flex flex-1 items-center justify-center flex-col bg-bianca hover:bg-white-rock transition rounded-lg p-6 font-semibold text-center"
          target="_blank"
          to="https://potagercity.my.site.com/serviceclient/s/contactsupport">
          <icon-email-action-unread class="text-go-green text-2xl mb-2 " />

          Contacter le service client
        </potager-link>

        <potager-link
          v-gtm
          class="flex flex-1 items-center justify-center flex-col bg-bianca hover:bg-white-rock transition rounded-lg p-6 font-semibold text-center"
          target="_blank"
          to="https://potagercity.my.site.com/serviceclient/s/">
          <icon-question-circle class="text-go-green text-2xl mb-2 " />

          Questions fréquentes
        </potager-link>
      </div>

      <potager-button
        class="mx-auto mb-8 flex"
        is-rounded
        theme="british-racing"
        @onClick="logout">
        Se déconnecter
      </potager-button>

      <p class="panel-section__p text-center font-normal text-xs.5 mb-8">
        Potager City v{{ version }}
      </p>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT_USER_ACTION } from 'Stores/types/sessionActionsTypes';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { CANCELLED } from 'Classes/OrderStatus';

import AccountSponsorshipSection from 'Pages/account/components/AccountSponsorshipSection';

import Panel from 'Components/panel/Panel';

import PotagerNavigator from 'UI/PotagerNavigator';
import PotagerButton from 'UI/PotagerButton';

import IconShoppingBagCheck from 'Icons/regular/IconShoppingBagCheck';
import IconCog from 'Icons/regular/IconCog';
import IconSingleNeutralCircle from 'Icons/regular/IconSingleNeutralCircle';
import IconCalendarSync from 'Icons/regular/IconCalendarSync';
import IconCreditCard from 'Icons/regular/IconCreditCard';
import IconAlarmBell from 'Icons/regular/IconAlarmBell';
import IconLoveIt from 'Icons/regular/IconLoveIt';
import IconTicket from 'Icons/regular/IconTicket';
import IconEmailActionUnread from 'Icons/regular/IconEmailActionUnread';
import IconQuestionCircle from 'Icons/regular/IconQuestionCircle';

import { capitalize } from 'PotagerLogic/Formatting';

import { version } from '../../../package.json';
import PotagerBadge from 'UI/PotagerBadge.vue';

export default {
  components: {
    PotagerBadge,
    AccountSponsorshipSection,
    PotagerButton,
    Panel,
    PotagerNavigator,
    IconShoppingBagCheck,
    IconCog,
    IconSingleNeutralCircle,
    IconCalendarSync,
    IconCreditCard,
    IconAlarmBell,
    IconLoveIt,
    IconTicket,
    IconEmailActionUnread,
    IconQuestionCircle,
  },

  data() {
    return {
      version,
    };
  },

  computed: {
    ...mapGetters('user', [
      'getActiveOrders',
      'getFirstName',
      'getLastName',
      'getSubscription',
      'getUserCouponsExceptLuncheon',
    ]),
    nbActiveOrders() {
      const orders = [...this.getActiveOrders, this.getSubscription?.temporaryOrder]
        .filter((order) => order);
      return orders
        .filter((order) => order.status !== CANCELLED).length || undefined;
    },
  },

  methods: {
    capitalize,
    logout() {
      this.$store.dispatch(`session/${LOGOUT_USER_ACTION}`);
      const query = { ...this.$route.query };
      delete query.panel;

      this.$router.push({
        name: 'home',
        query,
      });
    },
  },

  head: MetaInfosService.generate({
    title: 'Mon compte',
  }),
};
</script>
