<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    :title="haveRecipes ? 'Recettes enregistrées' : 'Carnet de recettes'">
    <div
      v-if="!haveRecipes"
      class="panel-section">
      <p class="panel-section__p">
        Vous n'avez encore rien mis de côté ? 🌱 Pas de souci, retrouvez ici toutes vos recettes favorites une fois que
        vous les aurez enregistrées.
      </p>
    </div>

    <div
      v-else
      class="panel-section">
      <p class="panel-section__title">
        {{ pluralize(getRecipes.length, 'recette') }}
      </p>

      <template
        v-for="(recipe, key) in myRecipes"
        :key="key">
        <div class="panel-section__separator" />

        <card-content
          :content="recipe"
          class="panel-section__line" />
      </template>
    </div>

    <div
      class="panel-section">
      <p class="panel-section__p">
        Pour ajouter une recette à vos favoris, cliquez sur le petit ❤️ en bas de sa fiche.
      </p>

      <div class="panel-section__line">
        <potager-picture
          alt="Sans recette"
          class="mb-4 min-h-64 bg-white-smoke"
          placeholder
          src="carnet-de-recette-empty" />

        <potager-button
          :to="{ name: 'recettes-list' }"
          full-width
          label="Voir nos recettes"
          theme="go-green" />
      </div>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import Panel from 'Components/panel/Panel';
import CardContent from 'Components/cards/CardContent';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import MetaInfosService from 'Classes/services/MetaInfoService';
import { pluralize } from 'PotagerLogic/Formatting';

export default {

  components: {
    Panel,
    CardContent,
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    myRecipes: [],
  }),

  computed: {
    ...mapGetters('user', [
      'getRecipes',
    ]),
    haveRecipes() {
      return this.getRecipes && this.getRecipes.length;
    },
  },

  watch: {
    getRecipes() {
      this.fetchRecipes();
    },
  },

  methods: {
    pluralize,
    fetchRecipes() {
      // SHOULD BE A CALL API THAT RETREIVE ONLY USER RECIPES
      const recipesFetch = this.getRecipes.map((currentRecipe) => this.$api.recipe.findOne(currentRecipe.id));

      Promise.all(recipesFetch)
        .then((contents) => {
          const newRecipes = [];

          contents.forEach((resp) => {
            newRecipes.push(resp.data);
          });

          this.myRecipes = newRecipes;
        });
    },
  },

  created() {
    this.fetchRecipes();
  },

  head: MetaInfosService.generate({
    title: 'Carnet de recettes',
  }),

};
</script>
