import GtmMixin from 'Mixins/GtmMixin';
import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

import { CANCEL_ORDER_ACTION, RESUME_ORDER_ACTION } from 'Stores/types/userActionsTypes';

import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { ABONNEMENT, ONESHOT } from 'Classes/OrderStatus';

import ModalOrderResumeOutOfStock from 'Modals/ModalOrderResumeOutOfStock';
import ModalInfo from 'Modals/ModalInfo';
import ModalDeleteOrder from 'Modals/ModalDeleteOrder';

export default {
  mixins: [PaymentMethodsMixin, GtmMixin],

  methods: {
    cancelOrder(orderId, success = null, errorCallBack = null) {
      this.$store.dispatch(`user/${CANCEL_ORDER_ACTION}`, { orderId })
        .then((orders) => {
          if (success) {
            const orderCanceled = orders.find((o) => o.id === orderId);
            this.trackRefundTransaction(orderCanceled);

            if (typeof success === 'function') success(orderCanceled);
          }
        })
        .catch((errors) => {
          if (errorCallBack) errorCallBack(errors);
        });
    },
    showCancelOrderModal(order) {
      if (order.canal === ONESHOT) {
        this.showCancelOneShotOrderModal(order);
      } else if (order.canal === ABONNEMENT) {
        if (order.boxes.length === 1 && order.containsSubscriptionBox) {
          this.$router.push({
            name: 'mon-compte_order_suspend_form',
            params: {
              orderId: order.id,
            },
          });
        } else {
          this.showCancelSubscriptionOrderModal(order);
        }
      }
    },
    showCancelOneShotOrderModal({ id }) {
      this.$modal.open(ModalDeleteOrder, {
        title: 'Êtes-vous sûr ?',
        text: `Si vous avez payé cette commande avec
          des coupons, ils vous seront rendus. Si vous
          avez effectué un paiement par carte bancaire,
          vous serez remboursé sous forme de coupon.`,
        yesCallback: (callback) => {
          this.cancelOrder(id, () => {
            this.$modal.open(ModalInfo, {
              title: 'C\'est tout bon !',
              text: `L'annulation a bien été prise en compte. Si vous aviez payé cette commande
                avec des coupons, ils vous ont été rendus. Si vous aviez effectué un paiement par
                carte bancaire, vous avez été remboursé sous forme de coupon.`,
              closeBtnText: 'Ok',
              showCancel: true,
              cancelBtnText: 'Voir mes coupons',
              onCancelRedirect: { name: 'mon-compte_coupons' },
            });
            if (typeof callback === 'function') callback();
          }, () => {
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Nous avons rencontré une erreur lors de l'annulation de votre commande. Vous pouvez
          ré-essayer. Si cela ne fonctionne toujours pas, contactez le service client.`,
            });
          });
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    showCancelSubscriptionOrderModal(order, config = {}) {
      const text = config.text || 'Êtes-vous sûr de vouloir annuler cette commande ?';
      const buttonYesText = config.confirm || 'Oui';
      const buttonNoText = config.cancel || 'Non';

      this.$modal.open(ModalDeleteOrder, {
        title: 'Avertissement',
        text,
        buttonYesText,
        buttonNoText,
        yesCallback: (callback) => {
          this.cancelOrder(order.id, () => {
            this.$notify({
              type: 'success',
              title: 'Commande annulée',
              text: `L'annulation a bien été prise en compte. Si vous aviez appliqué des coupons, ils vous ont été rendus.`,
            });
            if (typeof callback === 'function') callback();
          }, () => {
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Nous avons rencontré une erreur lors de l'annulation de votre commande. Vous pouvez
          ré-essayer. Si cela ne fonctionne toujours pas, contactez le service client.`,
            });
          });
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    resumeOrder(orderId, simulation = true, response = null) {
      if (simulation && response) {
        const errors = response.warning;

        if (errors.length > 0 && errors[0].code === 'BT018') {
          const products = errors.map(warn => warn.code === 'BT018' ? warn.metadata : null);

          this.$events.emit(POPIN_ERROR_EVENT, {
            component: ModalOrderResumeOutOfStock,
            products,
            orderId,
          });

          return Promise.resolve({
            orderId,
            response
          });
        } else {
          return this.resumeOrder(orderId, false);
        }
      }

      return this.$store.dispatch(`user/${RESUME_ORDER_ACTION}`, {
        id: orderId,
        simulation
      })
        .then(resp => {
          if (!resp.data.success) throw resp.data.errors;

          if (!simulation) {
            const order = resp.data.data.orders.find(o => o.id === orderId);
            if (order) this.notifyOrderStatus(order);
          }

          if (simulation) {
            // nextTick to avoid the no-simulation wait/start ito be called before the simulation wait/end
            this.$nextTick(() => {
              return this.resumeOrder(orderId, true, resp.data);
            });
          }

          return {
            orderId,
            response: resp.data
          };
        })
        .catch(err => {
          if (!simulation) {
            console.error(err);
            this.$modal.open(ModalInfo, {
              title: 'Oups !',
              text: `Une erreur s'est produite lors de la reprise de votre commande. Vous pouvez réessayer et contacter le service client si le problème persiste`,
            });
          }
          throw err;
        });
    }
  },
};
