export const RASSURANCES_ITEMS = [{
  image: 'rassurance-saison',
  text: 'Produits de saison',
  link: '#',
  alt: 'Produits de saison',
}, {
  image: 'rassurance-bio-europe',
  text: 'En priorité issus de l’agriculture locale',
  link: '#',
  alt: 'En priorité issus de l’agriculture locale',
}, {
  image: 'rassurance-fresh',
  text: 'Si possible biologiques',
  link: '#',
  alt: 'Si possible biologiques',
}, {
  image: 'rassurance-local',
  text: 'Toujours frais et en privilégiant le circuit court',
  link: '#',
  alt: 'Toujours frais et en privilégiant le circuit court',
}];

export const USERS_REVIEWS = [{
  name: 'Allison Vittoz',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s110002529307465413945!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Je commande depuis quelques années mon panier 2 personnes chez Potager City et je suis toujours aussi satisfaite! Les fruits et légumes sont super bons et de saison. C’est varié et sur l’appli on a même des recettes ! Le service client est super efficace et rapide à répondre. Franchement y’a rien à redire c’est parfait 👍',
}, {
  name: 'Bertrand Petit',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109461307003414780643!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Mange des légumes, mange des fruits" ... grâce à POTAGER CITY, c\'est devenu un vrai plaisir ... avec eux on découvre la diversité des produits, des saveurs et des goûts ... un grand merci et continuez comme ça !',
}, {
  name: 'Amélie Rouard',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s116483956151638740811!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Jamais déçue par les produits du panier depuis que je suis abonnée. Frais et variés. L\'abonnement peut être suspendu pendant nos absences ou si le contenu proposé ne nous plaît pas. Bonne formule !',
}, {
  name: 'Stéphane Deniaud',
  score: 5,
  link: 'https://www.google.com/maps/reviews/@45.739099,4.814566,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s107633188415195609131!2s0x0:0x2c655937cc7fb38?hl=fr-FR',
  comment: 'Abonnés depuis quelques mois, nous trouvons les légumes et fruits de très bonne qualité, très savoureux. Nous sommes adeptes des circuits courts et la livraison se fait chez un commerçant de notre quartier juste à côté de la maison! Et les idées de recettes sur le site sont un vrai plus ! Nous continuons l\'expérience!',
}, {
  name: 'Nadia Delli',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s104125996673832180406!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Abonnée depuis +2ans. Légumes et fruits de saisons de très bons goûts. Je ne mange que ceux-là et surtout les fruits. Aller les chercher me fais sortir de chez moi, travaillant à la maison, et je découvre d\'autres commerces. Le + : pouvoir suspendre son abonnement ! J\'aime bcp le panier que je convertis en corbeille à déchets papier, je trie !',
}, {
  name: 'Christine Verdon',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s100594609644877137047!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Tout est parfait variété et qualité des produits Le site et l’application sont très bien fait Très contente',
}, {
  name: 'Muriel Jauffroy',
  score: 5,
  link: 'https://www.google.com/maps/reviews/@45.739099,4.814566,17z/data=!3m1!4b1!4m5!14m4!1m3!1m2!1s117083710385104894865!2s0x0:0x2c655937cc7fb38?hl=fr-FR',
  comment: 'Vraiment enchantée par ce service, des fruits et légumes hyper frais, goûteux, de saisons. Cela permet de varier les menus et de se faire plaisir 😊',
}, {
  name: 'Anne Malinge',
  score: 5,
  link: 'https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s102602457455963727120!2s0x0:0x2c655937cc7fb38?hl=fr-FR&shorturl=1',
  comment: 'Nous avons découvert ce site récemment et sommes accros 🤗 Produits de très bonnes qualités (agrumes une tuerie !), toute la famille se régale et petits font le plein de vitamines avec plaisir. Hâte de découvrir les différents prochains paniers. Continuez! Merci 😃',
}];

export const SUBSCRIPTION_FAQ = [{
  question: 'Est-ce que je peux remplacer mon panier&nbsp;?',
  answer: `
      Votre frigo est encore bien rempli ? Vous n'aimez pas la composition de votre panier de la semaine ?
Pas de panique ! Vous pouvez remplacer votre panier en abonnement à tout moment.
    `,
}, {
  question: 'Est-il possible de sauter mes prochaines commandes&nbsp;?',
  answer: `
      Vous prévoyez de partir en vacances ? C'est simple, vous pouvez sauter vos commandes à venir en 1 clic, jusqu'à 8 semaines en avance.
    `,
}, {
  question: 'Comment un abonnement peut-il être sans engagement&nbsp;?',
  answer: `
      Le seul engagement de Potager City : vous proposer des fruits et légumes qui sont vraiment bons !
      Pour le reste, il n’y a aucun engagement. Vous pouvez suspendre temporairement ou définitivement
      votre abonnement à tout moment et sans aucun frais.
    `,
}, {
  question: 'Quand vais-je recevoir mon panier&nbsp;?',
  answer: `
      Une fois votre panier choisi, vous allez choisir votre lieu de livraison. Un commerçant point relais
      proche de chez vous ? Sur votre lieu de travail si votre entreprise est partenaire de Potager City ?
      C’est vous qui voyez ! Chaque point relais (commerçant ou entreprise) a un/des jours de livraison
      dédié(s). Votre commande sera livrée chaque semaine sur le créneau que vous allez choisir. Vous pourrez
      à tout moment changer votre jour de livraison, soit pour une prochaine commande de façon occasionnelle,
      soit de façon permanente en modifiant le jour de livraison de votre abonnement.
    `,
}, {
  question: 'À quel moment vais-je connaître la composition de mon panier&nbsp;?',
  answer: `
      Votre jour favori va devenir le jeudi ! C’est tous les jeudis que l’équipe Potager City vous dévoile
      la composition de votre panier de la semaine suivante (par mail ou directement sur le site). C’est
      aussi le moment de compléter votre commande avec les produits à l’unité disponibles sur la boutique
      en ligne.
    `,
}];

export const B2B_FAQ = [{
  question: 'Puis-je modifier la quantité ou la fréquence de mes livraisons&nbsp;?',
  answer: `
      Rien de plus simple, un seul contact chez Potager City et une demande avant les mercredis 12h00
      pour intervenir sur vos commandes de la semaine d'après. Vous pouvez suspendre votre livraison
      sur des périodes de congés par exemple ou bien adapter la quantité à vos effectifs.
    `,
}, {
  question: 'Combien dois-je commander de corbeilles&nbsp;?',
  answer: `
      Nous considérons qu’une corbeille de fruits Potager City est idéale pour 10/15 collaborateurs,
      et pour une semaine. Cas particuliers, nombreux sites, budgets serrés ? Dans tous les cas,
      contactez-nous afin d'évaluer vos besoins en fonction de vos effectifs, votre organisation et
      votre budget&nbsp;!
    `,
}, {
  question: 'Quelles sont les modalités de paiement possibles&nbsp;?',
  answer: `
      Les corbeilles sont principalement payées sous forme de prélèvement SEPA avec facturation au mois
      des corbeilles reçues. Vous n’avez rien à faire&nbsp;: pratique&nbsp;! Pour les autres modes de règlement,
      n'hésitez pas à nous solliciter (dans le cadre de contrats cadre par exemple).
    `,
}, {
  question: 'Quel jour pour mes livraisons de corbeilles&nbsp;?',
  answer: `
      Nous portons une grande attention à l'optimisation de nos tournées pour une démarche écoresponsable.
      Nous déterminons ensemble le jour le plus adapté&nbsp;!
    `,
}];

export const TALLY_URL = 'https://tally.so/r/w7vo23';
