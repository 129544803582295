import { mapGetters } from 'vuex';
import { AUTHORIZE_ORDER, UPDATE_ORDER_3DS_STATUS } from 'Stores/types/userActionsTypes';
import { PAYMENT_LOADERS, SUBSCRIPTION_BASKET_LOADERS } from 'Classes/Loaders';

import { PaymentMethodIntegration } from 'PotagerLogic/Enums/PaymentMethods';
import { getPaymentMethodByCard } from 'Classes/payment-methods';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

import StripeMixin, { STRIPE_PROCESSING_LOADER } from 'Mixins/payments/StripeMixin';
import PaygreenMixin, { PAYGREEN_PROCESSING_LOADER } from 'Mixins/payments/PaygreenMixin';
import { Context } from 'PotagerLogic/Enums/Context';
import { getPaymentError } from 'PotagerLogic/Utils/PaymentErrors';
import { isOrderNeedUserAction } from 'PotagerLogic/Utils/Order/OrderStatus';

export const DO_ORDER_REQUIRES_ACTION_LOADER = 'DO_ORDER_REQUIRES_ACTION_LOADER';

export default {
  mixins: [PaygreenMixin, StripeMixin,],
  data() {
    return {
      paymentMethods: {
        primary: null,
        secondary: null,
      },
    };
  },

  computed: {
    ...mapGetters('user', ['getCardById', 'getBankCards', 'getDefaultBankCard', 'getDefaultRestaurantPM', 'getPaymentGateway',]),
    isPaymentLoading() {
      return this.$wait.is([
        ...PAYMENT_LOADERS,
        ...SUBSCRIPTION_BASKET_LOADERS,
        STRIPE_PROCESSING_LOADER,
        PAYGREEN_PROCESSING_LOADER,
        DO_ORDER_REQUIRES_ACTION_LOADER,
      ]);
    },
    defaultPrimaryCard() {
      if (this.$potagerRoute && this.$potagerRoute.params.cardId && this.getCardById(this.$potagerRoute.params.cardId)) {
        const { cardId } = this.$potagerRoute.params;
        return this.getCardById(cardId);
      }
      if (this.getDefaultBankCard) {
        return this.getDefaultBankCard;
      }
      if (this.getBankCards.length) {
        return this.getBankCards[0];
      }
      return null;
    },
    getFlattenPaymentMethods() {
      return [...[this.paymentMethods.primary], ...[this.paymentMethods.secondary],].filter((e) => e);
    },
    isPrimaryCardValid() {
      return !!(this.paymentMethods.primary?.payment_id && this.getCardById(this.paymentMethods.primary.payment_id));
    },
  },

  methods: {
    getCurrentCardId(order) {
      return order?.stripePayment?.currentStripePayment?.blueCard;
    },
    checkIfCanRetryPayment(order) {
      return order?.stripePayment?.currentStripePayment?.blueCard && order?.stripePayment?.neededAction === 'other';
    },
    checkIfNeed3DSecure(order) {
      return order?.stripePayment?.neededAction === '3ds';
    },
    checkIfNeedFrontPaymentValidation(order) {
      return order?.stripePayment?.neededAction === 'front_validation';
    },
    doOrderAuthorizationByCardId(order, cardId, context) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`user/${AUTHORIZE_ORDER}`, {
          cardId,
          orderId: order.id,
        })
          .then((response) => {
            this.order3DSAction(response.data.data, context)
              .then((paymentIntent) => {
                this.updateUser(paymentIntent.id, context)
                  .then(() => resolve(response))
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      });
    },
    doOrderRequiresAction(order, context) {
      this.$wait.start(DO_ORDER_REQUIRES_ACTION_LOADER);

      return new Promise((resolve, reject) => {
        if (order && isOrderNeedUserAction(order)) {
          if (this.checkIfNeed3DSecure(order) || this.checkIfNeedFrontPaymentValidation(order)) {
            this.order3DSAction(order, context)
              .then((paymentIntent) => {
                this.updateUser(paymentIntent.id, context)
                  .then(() => resolve())
                  .catch((error) => reject(error))
                  .finally(() => this.$wait.end(DO_ORDER_REQUIRES_ACTION_LOADER));
              })
              .catch((error) => reject(error))
              .finally(() => this.$wait.end(DO_ORDER_REQUIRES_ACTION_LOADER));
          } else if (order?.id && this.checkIfCanRetryPayment(order) && this.getCurrentCardId(order)) {
            // Renouvelle l'autorisation de paiement d'une commande
            this.doOrderAuthorizationByCardId(order, this.getCurrentCardId(order), context)
              .then((response) => resolve(response))
              .catch((error) => reject(error))
              .finally(() => this.$wait.end(DO_ORDER_REQUIRES_ACTION_LOADER));
          } else {
            this.$wait.end(DO_ORDER_REQUIRES_ACTION_LOADER);
            resolve();
          }
        } else {
          this.$wait.end(DO_ORDER_REQUIRES_ACTION_LOADER);
          resolve();
        }
      });
    },
    /**
     * Generates a message indicating the status of a subscription switch.
     *
     * @param {Object} order - The order object containing details about the order.
     * @returns {string} - The message indicating the status of the subscription switch.
     */
    getSwitchSubscriptionMessage(order) {
      let message = 'Le changement de votre formule d’abonnement a bien été pris en compte. ';

      if (order) {
        const deliveryDate = tmsToFormat(order.timeSlot.date);
        message += `Le contenu de votre commande, dont la livraison est prévue le ${deliveryDate}, a été modifié en conséquence`;

        if (order.isLimiteDateExpired) {
          message = `La modification de formule d'abonnement a bien été prise en compte.
Il est trop tard pour modifier votre commande abonné (prévue en livraison le ${deliveryDate}).
La modification sera donc effective lors de la création de la commande suivante.`;
        }
      } else {
        message += `Si vous aviez appliqué des coupons pour la première commande, vous devriez les réappliquer lors de la création de votre prochaine commande.`;
      }

      return message;
    },

    getMessage(order, context) {
      if (context === Context.SubscriptionBasket) {
        return `Votre abonnement a bien été activé. Cependant, une action de votre part est requise sur votre prochaine commande.`;
      } else if (context === Context.SwitchSubscription) {
        return this.getSwitchSubscriptionMessage(order);
      } else {
        return this.getPaymentError(order);
      }
    },
    getNotificationConfig(order, context) {
      return {
        type: !order || !order?.stripePayment?.isPayable || !!order?.stripePayment?.canCustomerWaitForBatch ? 'success' : 'warning',
        title: !order || !order?.stripePayment?.isPayable || !!order?.stripePayment?.canCustomerWaitForBatch ? 'C’est tout bon !' : 'Avertissement',
        text: !order || !order?.stripePayment?.isPayable || !!order?.stripePayment?.canCustomerWaitForBatch ? 'Votre commande a bien été validée.' : this.getMessage(order, context),
        buttons: context === Context.SwitchSubscription ? [{
          label: order ? 'Voir ma commande' : 'Voir mes commandes',
          redirect: this.getRedirectOrder(order),
        }] : [{
          label: 'Voir ma commande',
          redirect: this.getRedirectOrder(order),
        }],
      };
    },
    getPaymentError,
    getRedirectOrder(order) {
      return order ? {
        name: 'mon-compte_orders_detail',
        params: { orderId: order.id }
      } : { name: 'mon-compte_orders' };
    },
    notifyOrderStatus(order, context, autoRedirect = true) {
      if (this.isInAppWebView) return false;
      this.$notify(this.getNotificationConfig(order, context));
      if (autoRedirect) this.$router.push(typeof autoRedirect !== 'boolean' ? autoRedirect : this.getRedirectOrder(order));
    },
    order3DSAction(order, context) {
      const gateway = order?.stripePayment?.currentStripePayment?.gateway || this.getPaymentGateway;
      return gateway === PaymentMethodIntegration.Paygreen
        ? this.launchPaygreenOrder3DSAction(order, context)
        : this.launchStripeOrder3DSAction(order, context);
    },
    updateUser(paymentIntentId, context) {
      return new Promise((resolve, reject) => {
        if (paymentIntentId) {
          this.$store.dispatch(`user/${UPDATE_ORDER_3DS_STATUS}`, paymentIntentId)
            .then((response) => {
              this.notifyOrderStatus(response.data.data, context);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error('PaymentIntentId is missing'));
        }
      });
    },
  },

  watch: {
    // Quand la CB par default de l'utilisateur change ou quand le composant s'initialise,
    // on sélectionne cette CB comme payment primaire par défaut.
    defaultPrimaryCard: {
      handler(val) {
        if (val) {
          this.paymentMethods.primary = {
            payment_type: 'blueCard',
            payment_id: val.id,
          };
        }
      },
      deep: true,
      immediate: true,
    },
    // Même fonctionnement avec le mode de paiement secondaire
    getDefaultRestaurantPM: {
      handler(val) {
        if (val?.usableForSubscription) {
          const pm = getPaymentMethodByCard(val);
          this.paymentMethods.secondary = {
            payment_type: pm.integration === PaymentMethodIntegration.Paygreen ? pm.integration : pm.name,
            payment_id: val.id,
          };
        } else {
          this.paymentMethods.secondary = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
