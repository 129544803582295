<template>
  <ul class="flex flex-col gap-2">
    <li
      v-for="(region, index) in regions"
      :key="index">
      <potager-button
        :is-disabled="isLoading"
        class="px-5 py-3 text-left text-xs.5"
        full-width
        theme="beryl-light"
        @onClick="setRegion(region.id)">
        <span class="flex justify-between items-center w-full">
          {{ region.shortName }}
          <component
            :is="getIcon(region.slug)"
            class="ml-2 mr-2 text-2xl leading-none" />
        </span>
      </potager-button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION } from 'Stores/types/temporaryCustomerActionsTypes';

import IconBusinessClimbTop from 'Icons/regular/IconBusinessClimbTop';
import IconFarmingWheat from 'Icons/regular/IconFarmingWheat';
import IconLandmarkEiffelTower from 'Icons/regular/IconLandmarkEiffelTower';
import IconFruitGrapes from 'Icons/regular/IconFruitGrapes';
import IconHistoricalBuildingCastle1 from 'Icons/regular/IconHistoricalBuildingCastle1';
import IconVegetablesBeet1 from 'Icons/regular/IconVegetablesBeet1';
import IconFastFoodPretzel from 'Icons/regular/IconFastFoodPretzel';
import IconAnimalProductsCheese from 'Icons/regular/IconAnimalProductsCheese';

import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerButton,
  },

  data: () => ({
    regions: [],
  }),

  computed: {
    ...mapGetters('session', [
      'isClosedRegionByRegionId',
    ]),
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    isLoading() {
      return this.$wait.is(UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION);
    },
  },

  methods: {
    getRegions() {
      this.$api.region.findAll()
        .then(({ data }) => {
          this.regions = [
            ...data.filter((region) => !this.isClosedRegionByRegionId(region.id)),
            {
              id: 1, // RA
              name: 'Région Bourgogne-Franche-Comté',
              shortName: 'Bourgogne-Franche-Comté',
              slug: 'region-bourgogne-franche-comte',
            },
          ];
          this.$emit('onReady');
        });
    },
    setRegion(id) {
      this.$store.dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_REGION_ACTION}`, id)
        .then(() => {
          this.$router.push(this.getNextStepRoute());
        });
    },
    getIcon(slug) {
      switch (slug) {
        case 'region-paca-languedoc':
          return IconFarmingWheat;
        case 'region-ile-de-france':
          return IconLandmarkEiffelTower;
        case 'region-aquitaine-midi-pyrenees':
          return IconFruitGrapes;
        case 'region-nantes':
          return IconHistoricalBuildingCastle1;
        case 'region-hauts-de-france':
          return IconVegetablesBeet1;
        case 'region-grand-est':
          return IconFastFoodPretzel;
        case 'region-bourgogne-franche-comte':
          return IconAnimalProductsCheese;
        case 'region-rhone-alpes':
        default:
          return IconBusinessClimbTop;
      }
    },
  },

  mounted() {
    this.getRegions();
  },
};
</script>
