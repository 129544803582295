<template>
  <section
    v-if="order"
    class="subscription-detail__section bg-bianca">
    <h2 class="subscription-detail__subtitle">
      Commande
    </h2>

    <p class="subscription-detail__thirdtitle">
      {{ baseline }}
      <template v-if="order?.isEditable || order?.isResumable">
        Vous pouvez la modifier ou la compléter jusque <b>{{ getOrderResumableDate(order) }}</b>.
      </template>
    </p>

    <potager-row>
      <potager-col>
        <subscriber-bar-item
          :redirect="routes.subscriptionOrder"
          class="subscription-detail__order">
          <template #icon>
            <icon-shopping-bag-check />
          </template>
          Commande n° {{ order.number || order.id }}
          - {{ formatPrice(getOrderPrice(order)?.total) }}
          <ul
            v-if="order.boxes?.length > 1"
            class="subscription-detail__order__boxes">
            <li
              v-for="(orderBox, index) in groupedOrderBoxes"
              :key="index">
              {{ orderBox.length }}x {{ orderBox[0].name }}
            </li>
          </ul>

          <order-status
            :order="order"
            class="subscription-detail__order__status" />
        </subscriber-bar-item>
      </potager-col>

      <template
        v-if="order && !hideDeliveryPointInformations && !isOrderCanceledStatus(order) && !getSubscription?.temporaryOrder">
        <potager-col
          bp1024="12"
          col="6">
          <subscriber-bar-item
            :legend="order.deliveryPoint.groupDeliveryAddress"
            :redirect="order.isEditable ? routes.deliveryPointOrderConfirm : null"
            :text="order.deliveryPoint.groupName"
            vertical-stretch>
            <template #icon>
              <icon-shop />
            </template>
          </subscriber-bar-item>
        </potager-col>

        <potager-col
          bp1024="12"
          col="6">
          <subscriber-bar-item
            :legend="getOrderNextDeliveryHours(order)"
            :redirect="order.isEditable ? routes.deliveryPointOrder : null"
            :text="getNextOrderDeliveryDate"
            vertical-stretch>
            <template #icon>
              <icon-time-clock-circle />
            </template>
          </subscriber-bar-item>
        </potager-col>

        <potager-col v-if="orderNote">
          <subscriber-bar-item
            :is-warning="order.timeSlot.hasSubstitute"
            :legend="orderNote"
            :redirect="order.isEditable ? routes.deliveryPointOrder : null"
            ellipsis
            text="Remarque">
            <template #icon>
              <icon-information-circle />
            </template>
          </subscriber-bar-item>
        </potager-col>
      </template>
    </potager-row>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import IconShoppingBagCheck from 'Icons/regular/IconShoppingBagCheck';
import IconShop from 'Icons/regular/IconShop';
import IconTimeClockCircle from 'Icons/regular/IconTimeClockCircle';
import IconInformationCircle from 'Icons/regular/IconInformationCircle';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import SubscriberBarItem from 'Components/subscriberBar/SubscriberBarItem';
import OrderStatus from 'Components/order/OrderStatus';
import groupBy from 'lodash/groupBy';

import { formatPrice } from 'PotagerLogic/Formatting';

import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { getOrderNextDeliveryHours, getOrderResumableDate } from 'PotagerLogic/Utils/Order/OrderDate';
import { getGddNote } from 'PotagerLogic/Utils/DeliveryPoint/GroupDeliveryDay';
import { isOrderCanceledStatus } from 'PotagerLogic/Utils/Order/OrderStatus';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';
import { getOrderTimeSlot } from 'PotagerLogic/Utils/Order/OrderTimeSlot';

export default {
  components: {
    IconShoppingBagCheck,
    IconShop,
    IconTimeClockCircle,
    IconInformationCircle,
    PotagerRow,
    PotagerCol,
    SubscriberBarItem,
    OrderStatus,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    hideDeliveryPointInformations: {
      type: Boolean,
      required: false,
      default: false,
    },
    baseline: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getNextDeliveredTimeslot',
    ]),
    groupedOrderBoxes() {
      if (this.order) return groupBy(this.order.boxes, 'id');
      return null;
    },
    routes() {
      const routes = {};
      if (!this.order?.id) return routes;

      routes.subscriptionOrder = {
        name: 'mon-compte_orders_detail',
        params: { orderId: this.order.id },
      };

      if (this.order.deliveryPoint) {
        routes.deliveryPointOrderConfirm = {
          name: 'mon-compte_orders_detail',
          params: { orderId: this.order.id },
        };
        routes.deliveryPointOrder = {
          name: 'mon-compte_orders_detail',
          params: { orderId: this.order.id },
        };
      }
      return routes;
    },
    orderNote() {
      return getGddNote(this.order.groupDeliveryDay, { deliveryPoint: this.order.deliveryPoint });
    },
    getNextOrderDeliveryDate() {
      const ts = this.order?.groupDeliveryDay
        ? getOrderTimeSlot(this.order)
        : this.getNextDeliveredTimeslot;
      return ts ? tmsToFormat(ts.date) : null;
    },
  },

  methods: {
    getOrderNextDeliveryHours,
    getOrderResumableDate,
    isOrderCanceledStatus,
    formatPrice,
    getOrderPrice,
  },
};
</script>
