<template>
  <div class="bg-white-smoke -mb-8">
    <heading
      :modifiers="
        ['v2']"
      background="background-salad">
      <template #title>
        Notre histoire
      </template>

      <template #baseline>
        Dénicheurs de bon depuis 2007
      </template>
    </heading>

    <potager-container>
      <div class="static-page__block-shadow" />
      <section class="static-page__block static-page__block--centered">
        <h2 class="static-page__block__title">
          Une histoire de famille
        </h2>
        <p class="static-page__block__p">
          Potager City a été créé par deux frères (Damien & Yoann) qui ont grandi en famille à la campagne, avec un
          grand potager à la maison. Petits, leur maman a attendu de longues heures pour qu'ils finissent leurs
          assiettes de légumes mais elle n'a jamais abandonné ;) Comme tous les enfants, l'éducation alimentaire a pris
          du temps.
        </p>
        <p class="static-page__block__p">
          20 ans plus tard, les efforts de leur Maman ont commencé à payer. A la fin de leurs études, les deux frères
          qui boudaient les légumes créent Potager City, un concept de distribution de paniers de fruits et légumes
          extrafrais, sans stock et avec un seul objectif : Refaire découvrir aux gens le goût et la fraicheur.
        </p>
        <potager-picture
          class="static-page__block__image mx-auto"
          src="who-are-we-01"
          title="Au début, Potager City livrait en vélo" />
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2007, les débuts
        </h2>
        <p class="static-page__timeline__p">
          Amoureux des produits locaux, frais et de qualité, les deux frères créent Potager City à l'été 2007, avec
          l'aide de copains (Florent, Sebastien, Guillaume) de leur famille et 25000€ d'économies en poche. Les premiers
          producteurs approchés sont convaincus du concept et ils partagent leurs objectifs et leurs valeurs : goût et
          qualité des fruits et légumes, respect des saisons et de l’environnement, fraicheur.
        </p>
        <p class="static-page__timeline__p">
          Les premières livraisons commencent à l'hiver 2007, à Lyon, à domicile et en vélo triporteur. Début des
          journées à 2h ou 3h du matin, fin des livraisons à 22H. Les deux frères étaient à la fois entrepreneurs,
          cyclistes, comptables, informaticiens, livreurs, graphistes, préparateurs de commande, ramasseurs de fruits.
        </p>
        <p class="static-page__timeline__p">
          Les journées étaient bien remplies, les discussions entre frères bien animées :)
        </p>
        <potager-picture
          class="static-page__timeline__image"
          src="who-are-we-02"
          title="Les premiers paniers de fruits et légumes de Potager City" />
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2009
        </h2>
        <p class="static-page__timeline__p">
          La livraison est transférée vers les points-relais et les entreprises en mode livraison groupée, car les deux
          frères ne peuvent plus répondre à toutes les demandes de livraison, ils sont totalement débordés, ne vivent
          pas de leur travail et pensent même à tout arrêter. Christian, Alain, Philippe, Yann, Thierry, Jean-Pierre,
          Mathieu et beaucoup d'autres redonnent du jus aux deux frères. Peu de temps après, Yoann & Damien déménagent à
          Saint-Priest, dans le Rhône (69). Le bouche à oreille continue d'apporter la majorité des clients, et les
          semaines sont toujours bien chargées (70h). Ils embauchent un ami, Jérémy, qui grâce à son talent, accélère le
          développement.
        </p>
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2010, premier salaire
        </h2>
        <p class="static-page__timeline__p">
          Début 2010, ils se versent enfin un premier salaire de 100€, après 2 ans et demi de travail sans rémunération
          afin de payer toutes les charges de l'entreprise. Les deux frères doublent leur salaire dès le mois suivant
          pour passer à 200€ <i>(rires)</i>. L'équipe s'agrandit, de nouvelles têtes aident Potager City à se
          développer, la livraison en entreprise prend une ampleur importante.
        </p>
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2012-2019, on sème ...
        </h2>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2012</b>, Potager City déménage à nouveau à Toussieu (69) dans des locaux plus grands et le développement
          continue. Potager City emploie une quinzaine de personnes.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2014</b>, 28 personnes sont dans l'équipe et c'est le lancement de Potager City à Paris.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2015</b>, c'est le lancement de Potager City à Bordeaux et Toulouse, 35 personnes composent l'équipe
          Potager City.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2016</b>, pas de nouveaux lancements, mais Potager City se structure, nous passons à 52 personnes.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2017</b>, Potager City réalise une nouvelle levée de fonds, l’effectif s’étoffe.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2018</b>, Potager City continue à aller à la rencontre de ses clients, et se développe en Pays de la Loire.
        </p>
        <p class="static-page__timeline__p static-page__timeline__p--step">
          <b>2019</b>, c’est au tour de la région Grand Est de découvrir les services de Potager City ! Nous sommes
          désormais présents dans 6 régions en France.
        </p>
        <p class="static-page__timeline__p">
          Notre idée reste la même : proposer un produit BON, SIMPLE et de bonne QUALITÉ.
        </p>
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2020, la pandémie.
        </h2>
        <p class="static-page__timeline__p">
          Elle est arrivée sans prévenir, et a chamboulé le quotidien du monde entier. Dans cette période où la planète
          entière se voit confinée, beaucoup de français redécouvrent le plaisir de la cuisine maison ! Et au début d’un
          bon plat, il y a toujours de bons produits… On redécouvre les joies du travail des produits bruts, les liens
          familiaux se renforcent autour des casseroles, des passions naissent… Cette période n’a fait que renforcer nos
          convictions et notre mission : proposer au plus grand nombre des produits accessibles, sans faire de compromis
          sur la qualité.
        </p>
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          2022, on innove&nbsp;!
        </h2>
        <p class="static-page__timeline__p">
          L’année 2022 est placée sous le signe du changement ! En commençant par le déménagement du siège dans de
          nouveaux locaux. S’ensuit la sortie de deux fonctionnalités majeures, attendues depuis longtemps par nos
          clients. La première, c’est l’apparition de la livraison à domicile à vélo chez nos clients lyonnais,
          parisiens et marseillais. La seconde, c’est la possibilité de composer son panier de zéro, et sans abonnement
          : il est maintenant possible de sélectionner vos produits, au gré de vos envies, sans limite de poids ou de
          quantité !
        </p>
      </section>

      <section class="static-page__timeline">
        <h2 class="static-page__timeline__title">
          Et maintenant&nbsp;?
        </h2>
        <p class="static-page__timeline__p">
          Cette année, on a décidé de donner un coup de frais à notre logo, qui datait de la création de Potager City.
          Et 15 ans, ça commençait à faire beaucoup… On change de coupe de cheveux, mais pas de caractère, nos
          convictions, celles qui font qu’on se lève tous les matins, restent et resteront toujours les mêmes : vous
          dénicher les meilleurs produits.

          <potager-picture
            class="static-page__timeline__image"
            src="who-are-we-2022"
            title="Une partie de l'équipe de Potager City" />
        </p>
      </section>
    </potager-container>
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerSpotlight from 'UI/PotagerSpotlight';
import PotagerPicture from 'UI/PotagerPicture';

import Heading from 'Components/heading/Heading';

import COLORS from 'PotagerLogic/Enums/Colors';

const { wildWillow } = COLORS;

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    PotagerContainer,
    PotagerSpotlight,
    PotagerPicture,
    Heading,
  },

  data: () => ({
    wildWillow,
  }),

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Notre histoire',
      description: 'Créé en 2007, Potager City a bien grandi depuis le temps. On vous raconte toute notre histoire sur cette page.',
      image: 'https://potagercity.fr/images/meta_landing_page_particuliers_1200_628.png',
    });
  },
};
</script>
