<template>
  <div class="text-center">
    <div
      :class="[
        'account-order-status',
        'bg-white rounded-full inline-flex px-4 py-3.5',
        'font-semibold text-xs.5 text-warm-grey'
      ]">
      <template v-if="isCanceled">
        <span class="account-order-status__canceled text-cinnabar flex gap-2 items-center">
          <icon-delete2 /> Annulée
        </span>
      </template>

      <template v-else>
        <span
          :class="[
            'account-order-status__payed',
            'pr-2 mr-2 border-r border-white-rock flex gap-2 items-center',
            { 'text-go-green': isPayed }
          ]">
          <component
            :is="isPayed ? 'icon-check-circle1' : 'icon-credit-card1'" /> Payée&nbsp;-&nbsp;{{
            tmsToFormat(order.paymentDate, 'dd/MM')
          }}
        </span>

        <span
          :class="[
            'account-order-status__delivered flex gap-2 items-center',
            { 'text-go-green': isDelivered }
          ]">
          <icon-style-two-pin-marker /> Livrée - {{ tmsToFormat(order.timeSlot.date, 'dd/MM') }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import {
  isOrderCanceledStatus,
  isOrderPayedStatus,
} from 'PotagerLogic/Utils/Order/OrderStatus';
import { OrderFrontStatus } from 'PotagerLogic/Enums/Order';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

import IconDelete2 from 'Icons/bold/IconDelete2';
import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';
import IconStyleTwoPinMarker from 'Icons/bold/IconStyleTwoPinMarker';
import IconCreditCard1 from 'Icons/bold/IconCreditCard1';

export default {
  name: 'AccountOrderStatus',
  components: {
    IconDelete2,
    IconCheckCircle1,
    IconCreditCard1,
    IconStyleTwoPinMarker,
  },
  methods: {
    tmsToFormat,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCanceled() {
      return isOrderCanceledStatus(this.order);
    },
    isPayed() {
      return isOrderPayedStatus(this.order);
    },
    isDelivered() {
      return this.order.frontStatus === OrderFrontStatus.delivered;
    },
  },
};
</script>
