import { generic_decline } from 'Classes/StripeErrors';

import { getPaymentError } from 'PotagerLogic/Utils/PaymentErrors';

export default {
  default: {
    text: generic_decline,
    handle(app, response, error) {
      error.text = getPaymentError(error);
    },
  },
};
