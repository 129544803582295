<template>
  <div class="nps-layout text-center flex flex-col items-center justify-center ">
    <potager-picture
      class="w-full mb-12 h-[250px]"
      src="poire" />

    <div class="max-w-[600px] mx-auto">
      <div class="nps-survey w-full">
        <h1
          class="font-black text-2xl text-british-racing mb-6"
          v-html="step.title" />

        <p
          class="mb-6"
          v-html="step.description" />

        <transition
          mode="out-in"
          name="slide-right-to-left-25pc">
          <component
            :is="step.component"
            v-if="!step.condition || step.condition()"
            @onSubmit="onSubmit" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import NpsNotes from 'Pages/static/nps/npsSteps/NpsNotes';
import NpsReason from 'Pages/static/nps/npsSteps/NpsReason';
import NpsThanks from 'Pages/static/nps/npsSteps/NpsThanks';

import PotagerPicture from 'UI/PotagerPicture';

export const SEND_NPS = 'SEND_NPS';

export default {
  components: {
    PotagerPicture
  },
  data() {
    return {
      // params
      nps: undefined,
      reason: undefined,
      // steps
      stepIndex: 0,
      steps: [
        {
          title: 'Quelle est la probabilité que vous recommandiez Potager City&nbsp;?',
          description: 'Votre avis est essentiel pour améliorer continuellement notre service et mieux répondre à vos besoins.',
          component: NpsNotes,
          next: () => this.stepIndex++,
        },
        {
          title: 'Dites-nous en plus ! ',
          description: 'Aidez-nous à comprendre votre note et à améliorer le service Potager City ! En quelques mots, qu’avez-vous pensé de votre livraison et de votre commande&nbsp;?',
          component: NpsReason,
          next: () => this.sendNps()
        },
        {
          title: 'Merci&nbsp;!',
          description: 'Merci d\'avoir pris un peu de votre temps pour répondre. Cela va nous permettre d\'améliorer encore plus notre service.',
          component: NpsThanks,
          condition: () => this.nps >= 7,
        }
      ]
    };
  },

  computed: {
    token() {
      return this.$potagerRoute.params.token;
    },
    step() {
      return this.steps[this.stepIndex];
    },
  },

  methods: {
    onSubmit(values) {
      const attributes = Object.keys(values);
      attributes.forEach((attribute) => {
        this[attribute] = values[attribute];
      });

      this.step.next();
    },
    sendNps() {
      this.$wait.start(SEND_NPS);
      this.$api.app.sendNpsScore(this.token, this.nps, { reason: this.reason })
        .then(() => {
          this.stepIndex += 1;

          this.$notify({
            type: 'success',
            title: 'Merci pour votre retour !',
            text: this.nps >= 7
              ? 'Nous sommes ravis que vous soyez satisfait de nos services. À très bientôt !'
              : 'Nous sommes désolés que vous n\'ayez pas été satisfait de nos services. N\'hésitez pas à <a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">nous contacter</a> pour nous en dire plus.',
          });
        })
        .catch((err) => {
          const message = err?.globals?.[0]?.message || 'Une erreur est survenue, veuillez réessayer plus tard ou contacter <a href="https://potagercity.force.com/serviceclient/s/contactsupport" class="underline">notre service client</a>';
          this.$notify({
            type: 'error',
            title: 'Houspomme ! On a un problème',
            text: `${message}<br><small>Code erreur : ${err?.code || err?.message || 'N/A'}</small>`,
          });
        })
        .finally(() => this.$wait.end(SEND_NPS));
    },
  },
};
</script>

<style lang="scss">
.nps-survey {
  max-width: calc(100vw - (24px * 2));
}
</style>
