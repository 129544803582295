<template>
  <potager-form
    v-if="currentQuestion"
    id="survey-form"
    class="mb-4 last:mb-0 panel-section">
    <p
      v-if="currentQuestion.title"
      class="panel-section__title">
      {{ currentQuestion.title }}
    </p>

    <p
      v-if="currentQuestion.desc"
      class="panel-section__p">
      {{ currentQuestion.desc }}
    </p>

    <survey-did-you-know
      v-if="!!survey.getDidYouKnowByCurrentQuestion()"
      :did-you-know="survey.getDidYouKnowByCurrentQuestion()" />

    <template
      v-for="(choice, index) in currentQuestion.choices"
      :key="`choice-${index}`">
      <potager-checkbox
        :id="`choice-${index}`"
        :model-value="survey.isChoiceSelected(choice)"
        :name="`choice-${index}`"
        :type="currentQuestion.type"
        class="mb-0 panel-section__line"
        @update:modelValue="(value) => onChoiceToggle(value, choice)">
        <potager-input
          v-if="choice.comment && choice.comment.type === 'input'"
          :label="choice.label"
          :model-value="survey.getCurrentCommentValue(choice)"
          @onFocus="survey.saveChoice(choice)"
          @update:modelValue="(value) => survey.saveComment(choice, value)" />
        <template v-else>
          {{ choice.label }}
        </template>
      </potager-checkbox>

      <potager-input
        v-if="choice.comment && choice.comment.type === 'select' && survey.isChoiceSelected(choice)"
        :label="choice.comment.label"
        :model-value="survey.getCurrentCommentValue(choice)"
        :options="survey.selectOptionsAdapter(choice.comment.choices)"
        :placeholder="choice.label"
        class="panel-section__line"
        name="comment"
        type="select"
        @update:modelValue="(value) => survey.saveComment(choice, value)" />

      <survey-did-you-know
        v-if="survey.getDidYouKnowBySelectedChoice(choice)"
        :did-you-know="survey.getDidYouKnowBySelectedChoice(choice)"
        class="panel-section__line" />
    </template>
  </potager-form>

  <div
    v-else
    class="text-center">
    <icon-ptcy-loader-spinner class="text-british-racing text-4xl" />
  </div>
</template>

<script>
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';
import PotagerInput from 'UI/Form/PotagerInput';
import SurveyDidYouKnow from 'Components/surveys/SurveyDidYouKnow';
import PotagerForm from 'UI/Form/PotagerForm.vue';
import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {

  components: {
    PotagerForm,
    PotagerCheckbox,
    PotagerInput,
    SurveyDidYouKnow,
    IconPtcyLoaderSpinner,
  },

  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  computed: {
    currentQuestion() {
      return this.survey.getCurrentQuestion();
    },
    currentQuestionId() {
      return this.survey.getCurrentQuestionId();
    },
  },

  methods: {
    onChoiceToggle(value, choice) {
      const { type } = this.currentQuestion;
      if (type === 'radio') this.survey.saveChoice(choice);
      if (type === 'checkbox') this.survey.toggleChoice(choice, value);
    },
  },
};
</script>
