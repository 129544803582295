<template>
  <panel-section-navigator
    v-if="subscriberReduction !== 0 && !isOrderCanceledStatus(order)"
    :class="[
      'subscriber-benefits-navigator',
      'py-5 mt-3 text-xs.5',
      {
        'bg-sandy-beach': !isSubscriber,
        'bg-beryl-green': isSubscriber,
      }
    ]"
    :to="noNavigate ? undefined : { name: 'basket_subscription' }">
    <template v-if="isSubscriber">
      <b>{{ formatPrice(subscriberReduction) }} d’économisés</b> sur la commande grâce à votre abonnement.
    </template>

    <template v-else-if="order?.isEditable || !isOrderPayedStatus(order)">
      Économisez <b>{{ formatPrice(subscriberReduction) }}</b> sur votre commande grâce à l’abonnement.
    </template>

    <template v-else>
      Vous auriez économisé <b>
        {{ formatPrice(subscriberReduction) }}
      </b> sur votre commande grâce à l’abonnement.
    </template>
  </panel-section-navigator>
</template>

<script>
import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { formatPrice } from 'PotagerLogic/Formatting';

import { isOrderCanceledStatus, isOrderPayedStatus } from 'PotagerLogic/Utils/Order/OrderStatus';
import { PriceCategory } from 'PotagerLogic/Enums/Order';

import PanelSectionNavigator from 'Components/panel/section/PanelSectionNavigator';

export default {
  name: 'SubscriberBenefitsNavigator',
  components: {
    PanelSectionNavigator,
  },
  methods: {
    isOrderCanceledStatus,
    isOrderPayedStatus,
    formatPrice
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    noNavigate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isSubscriber() {
      return [PriceCategory.priceSubscription, PriceCategory.priceSubscriptionEnterprise]
        .includes(this.order?.priceCategory);
    },
    subscriberReduction() {
      const publicPrice = getOrderPrice(this.order, PriceCategory.priceOneShot).subTotal;
      const subscriberPrice = getOrderPrice(this.order, PriceCategory.priceSubscription).subTotal;
      return publicPrice - subscriberPrice;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriber-benefits-navigator {
  + :deep().panel-section__separator {
    margin-top: -$panel__section_vertical_small-padding;
  }
}
</style>
