<template>
  <div class="potager-container potager-container--medium">
    <div class="relative">
      <potager-slider
        :align="$mq.bp768 ? 'center': undefined"
        :items="categories"
        :settings="getSliderSettings"
        :slides-per-view="{
          default: 'auto',
          768: 3,
          840: 4,
          1168: 5,
        }"
        name="tunnel-box-slider"
        overflow-visible
        @onInit="ready = true"
        @onSlideChange="onSlideChange">
        <template #default="vProps">
          <card-product-selector
            :class="[
              'bp768:w-[229px]'
            ]"
            :context="context"
            :product="vProps.item"
            :selected-ref="vProps.item.selectedRef"
            @onSelectRef="setSelectedRef($event, vProps.item.ref)"
            @onSubmit="onSubmit" />
        </template>
      </potager-slider>
    </div>

    <div class="tunnel-box-slider__navigation mx-auto gap-4 flex justify-between items-center mt-8 w-[304px]">
      <potager-button
        class="tunnel-box-slider__navigation-prev"
        is-circle
        theme="stroke">
        <template #icon>
          <icon-arrow-left class="text-xs" />
        </template>
      </potager-button>

      <div
        ref="pagination"
        class="flex justify-center gap-2" />

      <potager-button
        class="tunnel-box-slider__navigation-next"
        is-circle
        theme="stroke">
        <template #icon>
          <icon-arrow-right class="text-xs" />
        </template>
      </potager-button>
    </div>
  </div>
</template>

<script>
import PotagerSlider from 'UI/PotagerSlider';
import PotagerButton from 'UI/PotagerButton';
import CardProductSelector from 'Components/cards/CardProductSelector';
import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconArrowRight from 'Icons/bold/IconArrowRight';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  name: 'TunnelBoxSlider',

  components: {
    PotagerSlider,
    PotagerButton,
    CardProductSelector,
    IconArrowLeft,
    IconArrowRight,
  },

  emits: ['setSelectedRef', 'onSlideChange', 'onSubmit'],

  data: () => ({
    ready: false,
    context: Context.Subscription,
  }),

  props: {
    categories: {
      type: Array,
      required: true,
    },
    initialSlide: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  computed: {
    getSliderSettings() {
      return {
        navigation: {
          nextEl: '.tunnel-box-slider__navigation-next',
          prevEl: '.tunnel-box-slider__navigation-prev',
        },
        pagination: {
          el: this.$refs.pagination,
          bulletClass: 'tunnel-box-slider__pagination-bullet',
          bulletActiveClass: 'tunnel-box-slider__pagination-bullet--active',
          clickable: true,
        },
        initialSlide: this.initialSlide,
        preloadImages: true,
        spaceBetween: 16,
      };
    },
  },

  methods: {
    setSelectedRef(categoryRef, ref) {
      this.$emit('setSelectedRef', categoryRef, ref);
    },
    onSlideChange({ slideIndex }) {
      this.$emit('onSlideChange', slideIndex);
    },
    onSubmit(box) {
      this.$emit('onSubmit', box);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .swiper {
  .card-product-selector {
    max-width: 80vw;
  }
}

:deep() .tunnel-box-slider__pagination-bullet {
  @apply cursor-pointer;
  @apply block w-full max-w-8 h-[3px] rounded;
  @apply bg-white-rock;

  &--active {
    @apply bg-british-racing;
  }
}
</style>
