<template>
  <li
    :class="[
      'coupon-checkbox panel-section p-5 mb-3',
      {
        'bg-white-rock animate-pulse h-[112px]' : coupon.skeleton,
      }
    ]">
    <component
      v-if="!coupon.skeleton"
      :id="coupon.couponCode"
      :is="checkable ? PotagerCheckbox : 'div'"
      :disabled="isDisabled"
      :is-loading="isLoading"
      :model-value="isChecked"
      class="coupon-checkbox__container"
      @update:modelValue="onInput">
      <div class="flex justify-between gap-4 mb-3">
        <p
          class="font-bold text-sm.5"
          v-html="coupon.couponCode" />

        <p
          class="text-sm.5 font-bold text-go-green text-right"
          v-html="getCouponUnit(coupon, { showMinus: true })" />
      </div>

      <div class="coupon-checkbox__details text-sm flex flex-col gap-1">
        <p
          class="coupons-checkbox__long-value font-semibold"
          v-html="getCouponUnit(coupon, { long: true })" />

        <div
          v-if="getCouponDetails(coupon)"
          class="coupons-checkbox__details text-xs.5 italic font-normal"
          v-html="getCouponDetails(coupon)" />

        <div
          v-if="getCouponWarning(coupon)"
          class="coupons-checkbox__warning text-mango-tango text-xs.5 italic font-semibold"
          v-html="getCouponWarning(coupon)" />
      </div>
    </component>
  </li>
</template>

<script>
import PotagerCheckbox from 'UI/Form/PotagerCheckbox';

import { formatPrice } from 'PotagerLogic/Formatting';

import { getCouponDetails, getCouponUnit, getCouponWarning } from 'potagerlogic/src/Utils/Coupon/CouponWordings';

export default {
  props: {
    checkable: {
      type: Boolean,
      required: false,
      default: true,
    },
    coupon: {
      type: Object,
      required: false,
      default: null,
    },
    isChecked: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      PotagerCheckbox,
    };
  },

  emits: ['onChecked'],

  methods: {
    getCouponWarning,
    getCouponDetails,
    getCouponUnit,
    formatPrice,
    onInput(isChecked) {
      const { coupon } = this;
      this.$emit('onChecked', {
        isChecked,
        coupon
      });
    },
  },
};
</script>
