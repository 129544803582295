import {
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
} from 'Classes/Constants';
import { DELIVERY_PRIVATE_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { getRouteParams } from 'Classes/utils/RouteUtils';
import { Context } from 'PotagerLogic/Enums/Context';

/**
 * Generates the route for the delivery point search.
 *
 * @param {Object} options - The options for generating the route.
 * @param {string} [options.context=Context.Basket] - The context of the route (e.g., basket, order).
 * @param {string} options.type - The type of delivery point.
 * @param {Object} options.params - The parameters to include in the route.
 * @param {Object} options.query - The query parameters to include in the route.
 * @param {string} options.orderId - The order ID to include in the route.
 * @param {Object} currentRoute - The current route object.
 * @returns {Object} The route object for the delivery point search.
 */
export const getDlpSearchRoute = ({
  context = Context.Basket,
  type,
  params,
  query,
  orderId,
  currentRoute,
} = {}) => {
  const oId = orderId || getRouteParams(currentRoute)?.orderId;
  const ctx = context === Context.Order && !oId ? Context.Basket : context;
  const typeParams = type === DELIVERY_PRIVATE_TYPE ? DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH : DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH;

  return {
    name: `account-dlp-search-step1-${ctx}${type ? `-${type}` : ''}`,
    params: {
      type: typeParams,
      ...(oId && { orderId: oId }),
      ...params,
    },
    query,
  };
};

/**
 * Generates the route for the delivery point list.
 *
 * @param {Object} options - The options for generating the route.
 * @param {string} [options.context=Context.Basket] - The context of the route (e.g., basket, order).
 * @param {string} options.type - The type of delivery point.
 * @param {Object} [options.params={}] - The parameters to include in the route.
 * @param {Object} options.query - The query parameters to include in the route.
 * @param {string} options.orderId - The order ID to include in the route.
 * @param {Object} currentRoute - The current route object.
 * @returns {Object} The route object for the delivery point list.
 */
export const getDlpListRoute = ({
  context = Context.Basket,
  type,
  params = {},
  query,
  orderId,
  currentRoute,
} = {}) => {
  const oId = orderId || getRouteParams(currentRoute)?.orderId;
  const ctx = context === Context.Order && !oId ? Context.Basket : context;

  if (type === DELIVERY_PRIVATE_TYPE && !params.search) {
    return getDlpSearchRoute({
      context: ctx,
      params: {
        ...params,
        type: DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
      },
      query,
      orderId: oId,
      currentRoute,
    });
  }

  return {
    name: `account-dlp-list-${type}-step3-${ctx}`,
    params: {
      ...(oId ? { orderId: oId } : {}),
      ...params,
    },
    query,
  };
};

/**
 * Generates the route for delivery point details.
 *
 * @param {Object} options - The options for generating the route.
 * @param {string} [options.context=Context.Basket] - The context of the route (e.g., basket, order).
 * @param {string} options.type - The type of delivery point.
 * @param {Object} options.params - The parameters to include in the route.
 * @param {Object} options.query - The query parameters to include in the route.
 * @param {Object} options.deliveryPoint - The delivery point object.
 * @param {string} options.orderId - The order ID to include in the route.
 * @param {Object} currentRoute - The current route object.
 * @returns {Object} The route object for delivery point details.
 */
export const getDlpDetailsRoute = ({
  context = Context.Basket,
  type,
  params,
  query,
  deliveryPoint,
  orderId,
  currentRoute,
} = {}) => {
  const oId = orderId || getRouteParams(currentRoute)?.orderId;
  const dlpParams = deliveryPoint ? {
    deliveryPointRegionId: deliveryPoint.regionId,
    deliveryPointId: deliveryPoint.id,
  } : {};
  const ctx = context === Context.Order && !oId ? Context.Basket : context;

  return {
    name: `account-dlp-details-${type}-step4-${ctx || Context.Basket}`,
    params: {
      ...(oId ? { orderId: oId } : {}),
      ...params,
      ...dlpParams,
    },
    query,
  };
};

/**
 * Generates the route for updating a delivery point.
 *
 * @param {Object} options - The options for generating the route.
 * @param {string} [options.context=Context.Basket] - The context of the route (e.g., basket, order).
 * @param {Object} options.params - The parameters to include in the route.
 * @param {Object} options.query - The query parameters to include in the route.
 * @param {string} options.dlpId - The delivery point ID to include in the route.
 * @param {string} options.gddId - The GDD ID to include in the route.
 * @param {string} options.orderId - The order ID to include in the route.
 * @param {Object} currentRoute - The current route object.
 * @returns {Object} The route object for updating a delivery point.
 */
export const getDlpUpdateRoute = ({
  context = Context.Basket,
  params,
  query,
  dlpId,
  gddId,
  orderId,
  currentRoute,
} = {}) => {
  const oId = orderId || getRouteParams(currentRoute)?.orderId;
  const ctx = context === Context.Order && !oId ? Context.Basket : context;

  return {
    name: `account-dlp-update-step5-${ctx}`,
    params: {
      ...(oId ? { orderId: oId } : {}),
      dlpId,
      gddId,
      ...params,
    },
    query,
  };
};

/**
 * Generates the route for navigating back from a delivery point.
 *
 * @param {Object} options - The options for generating the route.
 * @param {string} options.context - The context of the route (e.g., basket, order, subscription, user registration).
 * @param {Object} options.params - The parameters to include in the route.
 * @param {Object} options.query - The query parameters to include in the route.
 * @param {string} options.orderId - The order ID to include in the route.
 * @param {Object} currentRoute - The current route object.
 * @returns {Object} The route object for navigating back.
 */
export const getDlpBackRoute = ({
  context,
  params,
  query,
  orderId,
  currentRoute,
} = {}) => {
  const oId = orderId || getRouteParams(currentRoute)?.orderId;
  const ctx = context === Context.Order && !oId ? Context.Basket : context;

  return {
    params: {
      ...(oId ? { orderId: oId } : {}),
      ...params,
    },
    query,
    name: (() => {
      switch (ctx) {
        case Context.Basket:
          return 'basket';
        case Context.Order:
          return 'mon-compte_orders_detail';
        case Context.Subscription:
          return 'mon-compte_subscription';
        case Context.UserRegistration:
          return 'register';
        default:
          return 'dashboard';
      }
    })(),
  };
};
