<template>
  <transition name="appear-from-bottom">
    <potager-button
      v-if="$slots.default"
      :ripple="false"
      :theme="buttonTheme"
      :to="to"
      class="py-4 px-6 font-medium text-xs.5 rounded-none cursor-default select-text potager-button--no-hover"
      multi-line>
      <slot />
    </potager-button>
  </transition>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import { MessageType } from 'PotagerLogic/Enums/Messages';

export default {
  name: 'StatusBanner',
  components: { PotagerButton },

  props: {
    type: {
      type: String,
      default: MessageType.Success,
      values: [MessageType.Warning, MessageType.Success, MessageType.Error],
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },

  computed: {
    buttonTheme() {
      switch (this.type) {
        case MessageType.Error:
          return 'rossabel';
        case MessageType.Warning:
          return 'sandy-beach';
        case MessageType.Success:
        default:
          return 'beryl-green';
      }
    }
  },
};
</script>
