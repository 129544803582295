<template>
  <div>
    <potager-form
      v-if="allowSearch"
      id="content-list"
      class="content-list__search print:hidden"
      @onSubmit="onSubmit">
      <potager-row
        condensed
        nowrap>
        <potager-col col="stretch">
          <potager-input
            v-model="value"
            :is-loading="isLoading"
            inline-submit
            placeholder="Vous cherchez quelque chose ?"
            reset
            @onReset="onReset" />
        </potager-col>
      </potager-row>
    </potager-form>

    <div
      v-if="!$slots.default || !items.length"
      class="content-list">
      <template v-if="allowSearch">
        <template v-if="!isLoading && search">
          <p
            v-if="items.length && !$slots.default"
            class="content-list__has-search">
            <template v-if="!nbResults">
              Résultat(s)
            </template>
            <template v-else>
              {{ pluralize(nbResults, 'résultat') }}
            </template>
            avec
            «&nbsp;<span class="content-list__has-search__query">{{ search }}</span>&nbsp;»
          </p>
          <p
            v-else-if="!items.length"
            class="content-list__no-results">
            <icon-plant-sad class="content-list__no-results__icon" />
            Désolé, nous n’avons trouvé aucun résultat pour votre recherche
            « <span class="content-list__no-results__query">{{ search }}</span> »
          </p>
        </template>
        <p
          v-else-if="subtitle"
          class="content-list__has-search">
          {{ subtitle }}
        </p>
      </template>

      <potager-row
        v-if="items.length && !$slots.default"
        class="content-list__content">
        <potager-col
          v-for="(item, i) in items"
          :key="i"
          :col="item.type === 'hub' ? 6 : 4"
          bp1024="6"
          bp640="12"
          class="content-list-page__item bp640:mb-0">
          <card-content
            :content="item"
            class="bp640:pb-4" />
        </potager-col>
      </potager-row>
    </div>

    <slot />
  </div>
</template>

<script>
import { pluralize } from 'PotagerLogic/Formatting';

import IconPlantSad from 'Icons/regular/IconPlantSad';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerForm from 'UI/Form/PotagerForm';
import CardContent from 'Components/cards/CardContent';

export default {
  components: {
    IconPlantSad,
    PotagerRow,
    PotagerCol,
    PotagerInput,
    PotagerForm,
    CardContent,
  },

  data: () => ({
    value: '',
  }),

  computed: {
    search() {
      return this.$potagerRoute.params.search;
    },
    isLoading() {
      return this.$wait.is('FETCH_CONTENT_LIST');
    },
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    nbResults: {
      type: Number,
      required: false,
      default: null,
    },
    allowSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    subtitle: {
      type: [String, Boolean],
      required: false,
      default: 'Les dernières publications',
    },
  },

  methods: {
    pluralize,
    onSubmit() {
      this.$router.push({
        name: this.$route.name,
        params: { search: this.value },
      });
    },
    onReset() {
      this.onSubmit();
    },
  },

  mounted() {
    this.value = this.search || '';
  },
};
</script>
