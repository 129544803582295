<template>
  <potager-button
    v-bind="$props"
    :is-loading="$wait.is(LOGIN_REGISTER_LOADERS)"
    class="potager-social-button font-semibold mb-4"
    full-width
    @mouseout="isHover = false"
    @mouseover="isHover = true"
    @onClick="$emit('onClick')">
    <span
      :style="getIconStyle"
      class="potager-social-button__icon">
      <slot name="icon" />
    </span>
    <slot />
  </potager-button>
</template>

<script>
import PotagerButton, { props as potagerButtonProps } from 'UI/PotagerButton';

import { propsBuilder } from './Tools';

import { LOGIN_REGISTER_LOADERS } from 'Stores/SessionStore';

export default {
  components: {
    PotagerButton,
  },
  emits: ['onClick'],

  data: () => ({
    isHover: false,
    LOGIN_REGISTER_LOADERS,
  }),

  props: propsBuilder({
    ...potagerButtonProps,
    color: {
      type: String,
      required: false,
      default: null,
    },
  }),

  computed: {
    getIconStyle() {
      return this.color ? { color: this.color } : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.potager-social-button {
  padding-left: 2.625rem;
  box-shadow: $boxshadow;

  &__icon {
    @apply text-xl;
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    width: 2.625rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    background: $whiteSmoke;
    transition: background .3s ease;
  }

  @include hover() {
    box-shadow: none;

    .potager-social-button__icon {
      background: color-adjust($white, $lightness: $hover-darken);
    }
  }

}
</style>
