import { Context } from 'PotagerLogic/Enums/Context';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  const subscriptionBasketGroupDeliveryDay = rootGetters['session/getGroupDeliveryDay'](Context.SubscriptionBasket);
  const subscriptionGroupDeliveryDay = rootGetters['session/getGroupDeliveryDay'](Context.Subscription);
  const groupDeliveryDay = subscriptionBasketGroupDeliveryDay || subscriptionGroupDeliveryDay;

  return (!!groupDeliveryDay?.timeSlot) === expectedValue;
};
export default {
  type: 'hasGroupDeliveryDay',
  values: [true, false],
  test,
};
