<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Votre entreprise
    </h1>

    <p class="mb-10">
      📍&nbsp;Indiquez moi le nom de votre entreprise, pour que je trouve rapidement où vous livrer !
    </p>

    <dlp-search-private
      :context="context"
      class="mb-8"
      @onSearch="onSearch" />
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import TunnelBody from 'Components/tunnel/TunnelBody';
import DlpSearchPrivate from 'Components/deliveryPoint/step2/DlpSearchPrivate';
import { Context } from 'PotagerLogic/Enums/Context';

export default {
  components: {
    DlpSearchPrivate,
    TunnelBody,
  },

  props: {
    context: {
      type: String,
      default: Context.SubscriptionBasket,
    },
  },

  data() {
    return {
      address: undefined,
    };
  },

  computed: {
    ...mapGetters('tunnel', ['getNextStepRoute']),
    ...mapGetters('map', ['getUserSearchSuggestions']),
  },

  methods: {
    onSearch({ search }) {
      this.$router.push(this.getNextStepRoute({ params: { search } }));
    },
  },
};
</script>
