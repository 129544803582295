<template>
  <div class="blog blog--list">
    <potager-container size="medium">
      <heading
        :back="{ name: 'content-list' }"
        modifiers="blog-list">
        <template #title>
          Les
          <potager-spotlight
            :fill="wildWillow"
            inline-block
            type="scribble">
            {{ $route.meta.typeLabel }}
          </potager-spotlight>
          <br class="print:hidden">
          de Potager City
        </template>
      </heading>
    </potager-container>

    <blog-body>
      <content-list
        :key="`${$route.name}-${search}`"
        :items="items"
        :nb-results="nbResults" />

      <template #pager>
        <pager
          :response="response"
          class="print:hidden" />
      </template>
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerSpotlight from 'UI/PotagerSpotlight';

import ContentList from 'Components/content/ContentList';
import Pager from 'Components/pager/Pager';
import Heading from 'Components/heading/Heading';
import PushSubscription from 'Components/pushs/PushSubscription';
import BlogBody from 'Components/blog/BlogBody';

import { PLACEHOLDER_BONCOING_ITEMS } from 'Classes/Placeholders';
import COLORS from 'PotagerLogic/Enums/Colors';

const { wildWillow } = COLORS;

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    PushSubscription,
    PotagerContainer,
    PotagerSpotlight,
    ContentList,
    Pager,
    Heading,
    BlogBody,
  },

  data: () => ({
    wildWillow,
    items: PLACEHOLDER_BONCOING_ITEMS,
    response: null,
    maxPerPage: 24,
  }),

  props: {
    search: {
      type: String,
      required: false,
      default: null,
    },
    page: {
      type: Number,
      required: false,
      default: 1,
    },
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    nbResults() {
      return this.pagerState ? this.pagerState.nbResults : null;
    },
  },

  watch: {
    context() {
      this.fetchData();
    },
    search() {
      this.fetchData();
    },
    page() {
      this.fetchData();
    },
  },

  methods: {
    fetchData() {
      if (this.context) {
        this.items = PLACEHOLDER_BONCOING_ITEMS;
        this.$wait.start('FETCH_CONTENT_LIST');

        const {
          search: research,
          page,
          maxPerPage
        } = this;
        this.$api[this.context].findAll({
          research,
          page,
          maxPerPage
        })
          .then((resp) => {
            this.response = null;
            if (resp.data.data) {
              this.response = resp?.data?.data || {};
              this.items = resp?.data?.data?.results || [];
            }
          })
          .catch(() => {
            this.$events.emit('404');
          })
          .finally(() => {
            this.$wait.end('FETCH_CONTENT_LIST');
          });
      }
    },
  },

  mounted() {
    this.fetchData();
  },

  head() {
    return this.generateMetaIfPanelClosed(this.$route.meta);
  },
};
</script>
