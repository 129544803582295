<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    bianca
    class="sponsorship-account"
    title="Parrainage">
    <potager-background
      class="bg-lavender-blush px-8 py-12 text-center"
      src="sponsorship-banner-account">
      <p class="text-2xl.5 font-extrabold mb-5 text-byzantine bp640:text-xl.5">
        Parrainez vos proches&nbsp;!
      </p>

      <p
        class="font-lore-alternates text-2xl font-bold whitespace-nowrap tracking-tight bp640:text-xl.5">
        Gagnez 10€
      </p>

      <p class="text-sm font-medium">
        à chaque proche<br>parrainé
      </p>
    </potager-background>

    <account-coupons-share-my-code />

    <div class="panel-section rounded-b-none mb-0">
      <div class="sponsorship-account__conditions panel-section__line">
        <p class="text-lg text-center font-extrabold pb-8">
          Comment ça marche&nbsp;?
        </p>

        <div class="flex">
          <div class="sponsorship-account__vertical-dash" />

          <div>
            <potager-row
              :align="$mq.bp768 ? 'start' : 'center'"
              class="sponsorship-account__step">
              <potager-col col="2">
                <icon-ptcy-sketch-circled-one class="sponsorship-account__step__num" />
              </potager-col>

              <potager-col col="10">
                <p>
                  Parrainez vos proches en leur <b>partageant votre code parrain</b>
                </p>
              </potager-col>
            </potager-row>

            <potager-row
              :align="$mq.bp768 ? 'start' : 'center'"
              class="sponsorship-account__step">
              <potager-col col="2">
                <icon-ptcy-sketch-circled-two class="sponsorship-account__step__num" />
              </potager-col>

              <potager-col col="10">
                <p>
                  <b>Vos proches utilisent votre code</b> lors de leur 1ère commande <b class="text-byzantine">
                    et reçoivent 18€
                  </b> sous forme de codes promos*, soit&nbsp;:
                  <br><br>
                </p>

                <ul>
                  <li>
                    -&nbsp;<b class="text-byzantine">
                      10€
                    </b> sur leur 1ère commande,
                  </li>

                  <li>
                    -&nbsp;<b class="text-byzantine">
                      5€
                    </b> sur leur 2ème commande,
                  </li>

                  <li>
                    -&nbsp;<b class="text-byzantine">
                      3€
                    </b> sur leur 3ème commande.
                  </li>
                </ul>
              </potager-col>
            </potager-row>
          </div>
        </div>

        <potager-row
          :align="$mq.bp768 ? 'start' : 'center'"
          class="sponsorship-account__step">
          <potager-col col="2">
            <icon-ptcy-sketch-circled-three class="sponsorship-account__step__num" />
          </potager-col>

          <potager-col col="10">
            <p>
              À chaque fois que l’un de vos proches passe et reçoit sa 1ère commande, <b class="text-byzantine">
                vous recevez automatiquement 10€
              </b> sous forme de code promo**.
            </p>
          </potager-col>
        </potager-row>
      </div>
    </div>

    <div class="panel-section bg-white-rock rounded-t-none">
      <div class="panel-section__line">
        <potager-info size="small">
          *{{ getSponsorshipGodSonValue }} de remise valable sur la 1ère commande : minimum d'achat de
          {{ getSponsorshipMinGodSonValue }}. 5€ de remise valable 15 jours après la livraison de la 1ère
          commande : minimum d'achat de 15€. 3€ de remise valable 15 jours après la livraison de la 2ème commande :
          minimum d'achat de 13€.
        </potager-info>

        <potager-info size="small">
          ** {{ getSponsorshipGodFatherValue }} de remise sur une commande dès
          {{ getSponsorshipMinGodFatherValue }} d'achat. Code promo non
          cumulable et valable 4 mois après réception.
        </potager-info>
      </div>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import ConfigurationManager from 'Classes/ConfigurationManager';

import MetaInfosService from 'Classes/services/MetaInfoService';

import Panel from 'Components/panel/Panel';
import AccountCouponsShareMyCode from 'Pages/account/components/AccountCouponsShareMyCode';
import PotagerBackground from 'UI/PotagerBackground';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInfo from 'UI/PotagerInfo';

import IconPtcySketchCircledOne from 'Icons/graphics/IconPtcySketchCircledOne';
import IconPtcySketchCircledTwo from 'Icons/graphics/IconPtcySketchCircledTwo';
import IconPtcySketchCircledThree from 'Icons/graphics/IconPtcySketchCircledThree';

import ModalCouponPolicy from 'Modals/ModalCouponPolicy';
import { CouponType } from 'PotagerLogic/Enums/Coupons';

export default {

  components: {
    Panel,
    PotagerBackground,
    PotagerRow,
    PotagerCol,
    PotagerInfo,
    IconPtcySketchCircledOne,
    IconPtcySketchCircledTwo,
    IconPtcySketchCircledThree,
    AccountCouponsShareMyCode,
  },

  computed: {
    ...mapGetters('app', [
      'getSponsorshipGodFatherValue',
      'getSponsorshipGodSonValue',
      'getSponsorshipMinGodSonValue',
      'getSponsorshipMinGodFatherValue',
      'getSponsorshipPromotedText',
    ]),
    isMobileTablet() {
      return ConfigurationManager.getByName('isMobileTablet') && navigator.share;
    },
  },

  methods: {
    modalConditions() {
      this.$modal.open(ModalCouponPolicy, {
        promotedCoupon: {
          couponType: CouponType.Sponsorship,
          ...this.getSponsorshipPromotedText,
        },
      });
    },
  },

  head: MetaInfosService.generate({
    title: 'Parrainage',
  }),
};
</script>

<style lang="scss">
.sponsorship-account {

  &__vertical-dash {
    border-left: 2px $porcelain dashed;
    left: 1.125rem;
    position: relative;
    margin: 1.25rem 0 -1.25rem;
  }

  &__step {
    margin-bottom: 2.75rem;

    &__num {
      margin: 0 auto;
      position: relative;
      @apply text-3xl.5;
      background-color: $white;
    }
  }
}
</style>
