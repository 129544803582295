<template>
  <potager-modal>
    <template #header>
      <p class="mb-2">
        Saisissez et sélectionnez les codes promos que vous souhaitez ajouter à votre commande.
      </p>

      <coupon-input :context="context" />
    </template>

    <template #body>
      <div class="modal-subscription-coupons__body">
        <coupon-selector
          :context="context"
          class="text-left" />
      </div>
    </template>

    <template #footer="{ close }">
      <potager-button
        :is-loading="$wait.is(COUPONS_LOADERS)"
        :label="getSubscriptionBasketCoupons.length ? 'Appliquer' : 'Continuer'"
        full-width
        @onClick="close" />
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { COUPONS_LOADERS } from 'Stores/subStores/User/UserCouponStore';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

import CouponSelector from 'Components/coupons/CouponSelector.vue';
import CouponInput from 'Components/coupons/CouponInput';

import { Context } from 'PotagerLogic/Enums/Context';
import { tmsToFormat } from 'PotagerLogic/Utils/Dates/DateFormat';

export default {
  methods: { tmsToFormat },

  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerModal,
    PotagerButton,
    CouponInput,
    CouponSelector,
  },

  data: () => ({
    context: Context.Subscription,
    COUPONS_LOADERS,
  }),

  computed: {
    ...mapGetters('subscriptionBasket', [
      'getSubscriptionBasketCoupons',
      'getLimitDate'
    ]),
  },
};
</script>

<style lang="scss" scoped>
:deep().modal-subscription-coupons__body {
  text-align: left;

  .coupon-checkbox {
    background: $bianca;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
