<template>
  <potager-modal :can-be-closed="canBeClosed">
    <template #title>
      <span v-html="title" />
    </template>

    <template #body>
      <span v-html="text" />
    </template>

    <template #footer>
      <potager-button
        v-if="showCancel"
        full-width
        theme="stroke"
        @onClick="cancel">
        {{ cancelBtnText }}
      </potager-button>

      <potager-button
        :is-loading="computedIsLoading"
        full-width
        :theme="closeBtnTheme"
        @onClick="close">
        {{ closeBtnText }}
      </potager-button>
    </template>
  </potager-modal>
</template>

<script>
import GtmMixin from 'Mixins/GtmMixin';
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';
import { BUTTON_THEMES } from 'Classes/Constants.js';

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    closeBtnText: {
      type: String,
      required: false,
      default: 'OK, j\'ai compris',
    },
    closeBtnTheme: {
      type: String,
      required: false,
      default: undefined,
      values: BUTTON_THEMES,
    },
    onCloseRedirect: {
      type: [Object, String],
      required: false,
      default: null,
    },
    onClose: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelBtnText: {
      type: String,
      required: false,
      default: 'Annuler',
    },
    onCancelRedirect: {
      type: Object,
      required: false,
      default: null,
    },
    onCancel: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    immediateClosure: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLoading: {
      type: [String, Array],
      required: false,
      default: '',
    },
    canBeClosed: {
      type: Boolean,
      required: false,
      default: true,
    },
    tracking: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    computedIsLoading() {
      return this.$wait && this.$wait.is(this.isLoading);
    },
  },

  methods: {
    close() {
      const params = { type: 'close' };

      if (this.immediateClosure) this.finalizeClosure(params);

      Promise.resolve(this.onClose.bind(this)())
        .then(() => {
          if (!this.immediateClosure) this.finalizeClosure(params);
        });

      if (this.onCloseRedirect) {
        this.track(params);
        if (typeof this.onCloseRedirect === 'object') {
          this.$router.push(this.onCloseRedirect)
            .catch(() => {
            });
        } else {
          window.open(this.onCloseRedirect);
        }
      }
    },
    cancel() {
      const params = { type: 'cancel' };

      if (this.immediateClosure) this.finalizeClosure(params);

      Promise.resolve(this.onCancel.bind(this)())
        .then(() => {
          if (!this.immediateClosure) this.finalizeClosure(params);
        });

      if (this.onCancelRedirect) {
        this.track(params);
        this.$router.push(this.onCancelRedirect);
      }
    },
    finalizeClosure(params) {
      this.track(params);
      this.$modal.close(params);
    },
    track(params) {
      this.trackAction({
        event: 'Modal',
        value: params.type === 'close' ? 'success' : 'dismiss',
        ...this.tracking,
      });
      this.$modal.close(params);
    },
  },
};
</script>
