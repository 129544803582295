<template>
  <section class="tunnel-layout flex flex-col tracking-normal">
    <collapsible-content
      :is-open="hasBanner"
      :name="getBannerName"
      class="bp1024:sticky bp1024:top-0 z-[110]">
      <router-view name="banner" />
    </collapsible-content>

    <div class="h-full flex flex-col relative bg-bianca pt-4">
      <tunnel-header
        class="absolute top-0 left-0 right-0"
        @onExit="areyousure" />

      <div
        :class="[
          'flex-1 flex relative w-full self-center transition-all mt-8',
          {
            'mt-[84px]': topMargin
          }
        ]"
        :style="{ maxWidth: `${hasAsideAfterTransition && !$mq.bp1024 ? '1024': $mqw.width}px` }">
        <router-view @onExit="areyousure" />

        <transition
          appear
          mode="out-in"
          name="tunnel-layout__aside--transition">
          <div
            v-if="hasAsideAfterTransition"
            class="w-96">
            <div class="w-96 h-full relative">
              <router-view
                is-aside
                name="aside" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { RESET_TUNNEL } from 'Stores/types/tunnelMutationsTypes';
import { UPDATE_OAC_FLOW, UPDATE_OAC_RECOMMENDED_REF, } from 'Stores/types/sessionMutationsTypes';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import CollapsibleContent from 'Components/collapsible/CollapsibleContent';
import ModalNewsletterLeaveTunnel from 'Components/modals/ModalNewsletterLeaveTunnel';
import TunnelHeader from 'Components/tunnel/TunnelHeader';

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    TunnelHeader,
    CollapsibleContent,
  },

  data: () => ({
    hasAsideAfterTransition: false,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('tunnel', [
      'getLastStepRoute',
    ]),
    preventExit() {
      return this.$route.meta.preventExit || (this.$route.meta.preventExitIfNotLogged && !this.isLoggedIn);
    },
    hasBanner() {
      const { banner } = this.$route.matched?.[1].components;
      if (banner) {
        const fn = banner.computed?.isVisible || banner.methods?.isVisible;
        // On accède à la computed / methods "isVisible" du composant qui va être
        // monté dans le routerView "banner" afin de l'éxecuter et vérifier que la
        // bannière est effectivement visible. Cela nous oblige cependant à passer
        // notre "this" actuel à cette fonction. Elle doit donc avoir la capacité de
        // s'executer correction dans le contexte de "TunnelLayout"...
        if (typeof fn === 'function') return fn.bind(this)();
        return !!banner;
      }
      return !!banner;
    },
    getBannerName() {
      return this.hasBanner ? this.$route.matched?.[1].components.banner.__file : null;
    },
    hasAside() {
      const { aside } = this.$route.matched?.[1].components;
      if (aside) {
        const fn = aside.computed.isVisible || aside.methods.isVisible;
        // Voir "hasBanner"
        if (typeof fn === 'function') return fn.bind(this)();
        return !!aside;
      }
      return !!aside;
    },
    topMargin() {
      const isDesktopGddRoute = this.$potagerRoute.name.includes('-gdd-step4') && !this.$mq.bp768;
      return isDesktopGddRoute || this.hasAside;
    },
  },

  watch: {
    hasAside: {
      handler(val) {
        setTimeout(() => {
          this.hasAsideAfterTransition = val;
        }, 150);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations('session', [
      UPDATE_OAC_FLOW,
      UPDATE_OAC_RECOMMENDED_REF,
    ]),
    ...mapMutations('tunnel', [
      RESET_TUNNEL,
    ]),
    areyousure() {
      if (this.preventExit) {
        this.$modal.open(ModalNewsletterLeaveTunnel);
      } else {
        this.$router.push(this.getLastStepRoute());
      }
    },
    setVhForMobile() {
      if (!this.isInAppWebView) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
    },
  },

  mounted() {
    this.setVhForMobile();
    window.addEventListener('resize', this.setVhForMobile);
  },

  beforeUnmount() {
    this.$nextTick(() => {
      this.UPDATE_OAC_FLOW(null);
      this.UPDATE_OAC_RECOMMENDED_REF(null);
      this.RESET_TUNNEL();
    });
    window.removeEventListener('resize', this.setVhForMobile);
  },

};
</script>

<style lang="scss">
.app--tunnel {
  background-color: $bianca;
}

.tunnel-layout {
  min-height: 100vh;

  .framboise-message {
    @apply mt-0;
    @apply transition-all;
  }

  .panel-dlp-gdd-select {
    @apply bp768:-mt-6 #{'!important'};
  }
}

@include define-transition(leave, tunnel-layout__content--transition) {
  transition: width .3s, opacity .3s, transform .3s;
}

@include define-transition(leave, tunnel-layout__content--transition) {
  opacity: 0;
  transform: translate(-1rem, 0);
  width: 0 !important;
}

@include define-transition(both, tunnel-layout__aside--transition) {
  transition: width .3s, opacity .3s, transform .3s;
}

@include define-transition(both, tunnel-layout__aside--transition) {
  opacity: 0;
  transform: translate(-1rem, 0);
  width: 0 !important;
}
</style>
