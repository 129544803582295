<template>
  <panel
    :back-btn="Survey.canGoToPreviousQuestion()"
    :back-callback="() => Survey.goToPreviousQuestion()"
    :back-route=" Survey.canGoToPreviousQuestion() ? undefined : { name: 'mon-compte_subscription' }"
    title="Désabonnement">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Pour nous améliorer nous aimerions connaître la raison de votre désabonnement.
      </p>
    </template>

    <div class="panel-section bg-beryl-green text-center mb-10">
      <div class="panel-section__line">
        <b>Le saviez-vous&nbsp;:</b> Vous pouvez sauter vos futures livraisons depuis les pages “Mes commandes” ou “Mon
        abonnement” sans mettre fin à votre abonnement.
      </div>
    </div>

    <survey-form :survey="Survey" />

    <template #footer>
      <span class="flex flex-col items-center">
        <potager-button
          :is-loading="isFormLoading"
          full-width
          theme="british-racing"
          @onClick="Survey.submit()">
          Me désabonner
        </potager-button>

        <potager-button
          :to="{ name: 'mon-compte_subscription' }"
          size="small"
          theme="link">
          J'ai changé d'avis
        </potager-button>
      </span>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { REMOVE_SUBSCRIPTION_ACTION } from 'Stores/types/userActionsTypes';

import Panel from 'Components/panel/Panel';
import SurveyForm from 'Components/surveys/SurveyForm';

import PotagerButton from 'UI/PotagerButton';

import Survey from 'Classes/surveys/CancelSubscriptionSurvey';

export default {

  components: {
    Panel,
    PotagerButton,
    SurveyForm,
  },

  data: () => ({
    Survey: undefined,
  }),

  watch: {
    getSubscription: {
      immediate: true,
      handler(subscription) {
        if (subscription) {
          this.$nextTick(() => {
            this.Survey.init();
          });
        }
      },
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    isFormLoading() {
      return this.$wait.is(REMOVE_SUBSCRIPTION_ACTION);
    },
  },

  created() {
    this.Survey = Survey(this);
  }
};
</script>
