<template>
  <panel
    :back-route="{ name: 'basket' }"
    :is-loading="isLoading"
    title="Paiement">
    <template #header>
      <p class="text-sm.5 font-medium text-center">
        Le paiement est sécurisé et vos données sont cryptées. Supprimez votre moyen de paiement à tout moment.
      </p>
    </template>

    <payment-processing v-if="order" />

    <template v-else>
      <basket-receipt-details read-only />

      <payment-section />
    </template>

    <template
      v-if="!order && !isLoading"
      #footer>
      <div class="flex items-center">
        <span class="text-british-racing text-sm mr-2 potager-button potager-button--small bg-white font-normal">
          Total&nbsp;:&nbsp;<b>{{ formatPrice(total) }}</b>
        </span>

        <potager-button
          v-if="!getBankCards.length && total > 0"
          :to="{ name: 'mon-compte_payment-methods_credit-card_add', query: { context, skipHub: true }}"
          full-width>
          Ajouter une carte de paiement
        </potager-button>

        <potager-button
          v-else
          :is-disabled="!canSubmit"
          :is-loading="isPaymentLoading"
          disabled-message="Veuillez sélectionner une carte de paiement par défaut"
          full-width
          theme="go-green"
          @onClick="submitBasketPayment">
          Commander
        </potager-button>
      </div>

      <div class="text-xs.5 text-center mt-3">
        En poursuivant, j’accepte les
        <potager-link
          :to="{ name: 'cgv' }"
          class="font-bold underline"
          target="_blank">
          CGV
        </potager-link>.
      </div>
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import BasketPaymentMixin from 'Mixins/BasketPaymentMixin';
import GtmMixin from 'Mixins/GtmMixin';

import { PaymentMethodIntegration } from 'PotagerLogic/Enums/PaymentMethods';
import { SecondaryLoginPaymentMethodsArray } from 'Classes/payment-methods';
import MetaInfoService from 'Classes/services/MetaInfoService';
import { getOrderPrice } from 'PotagerLogic/Utils/Order/OrderPrices';
import { formatPrice } from 'PotagerLogic/Formatting';

import PotagerButton from 'UI/PotagerButton';

import Panel from 'Components/panel/Panel';
import PaymentProcessing from 'Components/paymentMethods/PaymentProcessing';
import PaymentSection from 'Components/paymentMethods/PaymentSection.vue';

import BasketReceiptDetails from 'Pages/basket/components/BasketReceiptDetails';

import { Context } from 'PotagerLogic/Enums/Context';

export default {
  mixins: [
    BasketPaymentMixin,
    GtmMixin,
  ],

  components: {
    PaymentSection,
    BasketReceiptDetails,
    PaymentProcessing,
    Panel,
    PotagerButton,
  },

  data: () => ({
    PaymentMethodIntegration,
    SecondaryLoginPaymentMethodsArray,
    SET_DEFAULT_CARD_ACTION,
    context: Context.Basket,
  }),

  computed: {
    ...mapGetters('basket', [
      'getBasket',
      'getSimulatedOrder',
      'isSubscription',
    ]),
    total() {
      return getOrderPrice(this.getSimulatedOrder || this.getBasket)?.total || 0;
    },
    canSubmit() {
      return this.isPrimaryCardValid || this.total <= 0;
    },
  },

  methods: {
    formatPrice,
  },

  mounted() {
    this.trackCheckoutStep();
  },

  head: MetaInfoService.generate({
    title: 'Paiement',
  }),
};
</script>
