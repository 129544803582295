import StaticPage from 'Pages/static/StaticPage';
import WhoAreWePage from 'Pages/static/WhoAreWePage';
import CgvPage from 'Pages/static/CgvPage';
import LegalMentionsPage from 'Pages/static/LegalMentionsPage';
import ProfessionalEqualityPage from 'Pages/static/ProfessionalEqualityPage';
import PersonalDataPage from 'Pages/static/PersonalDataPage';
import CookiesPage from 'Pages/static/CookiesPage';
import AppPage from 'Pages/static/AppPage';
import DeliveryPointsPage from 'Pages/static/DeliveryPointsPage';
import DlpMapList from 'Components/deliveryPoint/step3/DlpMapList';
import DlpMapDetails from 'Components/deliveryPoint/step4/DlpMapDetails';
import ManifestoPage from 'Pages/static/ManifestoPage';

import NpsSurveyPage from 'Pages/static/nps/NpsSurveyPage';

export default {
  name: 'static',
  path: '/page',
  component: StaticPage,
  children: [
    {
      name: 'who-we-are',
      path: '/notre-histoire/',
      components: {
        content: WhoAreWePage,
      },
      meta: {
        scrollToTop: true,
      },
    },
    {
      name: 'missions',
      path: '/nos-missions/',
      redirect: { name: 'concept-how-it-works' },
    },
    {
      name: 'cgv',
      path: '/cgv/',
      components: {
        content: CgvPage,
      },
      meta: {
        scrollToTop: true,
        webview: true,
      },
    },
    {
      name: 'mentions-legales',
      path: '/mentions-legales/',
      components: {
        content: LegalMentionsPage,
      },
      meta: {
        scrollToTop: true,
        webview: true,
      },
    },
    {
      name: 'professional-equality-index',
      path: '/index-egalite-professionnelle/',
      components: {
        content: ProfessionalEqualityPage,
      },
      meta: {
        scrollToTop: true,
        webview: true,
      },
    },
    {
      name: 'confidentialite-et-donnees-personnelles',
      path: '/confidentialite-et-donnees-personnelles/',
      components: {
        content: PersonalDataPage,
      },
      meta: {
        scrollToTop: true,
        webview: true,
      },
    },
    {
      name: 'cookies-page',
      path: '/politique-gestion-cookies',
      components: {
        content: CookiesPage,
      },
      meta: {
        scrollToTop: true,
        webview: true,
      },
    },
    {
      name: 'app-page',
      path: 'applications',
      components: {
        content: AppPage,
      },
      meta: {
        scrollToTop: true,
        header: false,
        footer: false,
      },
    },
    {
      path: 'points-de-livraison',
      components: {
        content: DeliveryPointsPage,
      },
      props: {
        content: {
          // useful to discard infinite loop when panel AND dlpPage open at the same time
          isPanel: false,
        },
      },
      children: [
        {
          name: 'delivery-points',
          path: ':latlng?',
          component: DlpMapList,
          meta: {
            scrollToTop: true,
            footer: false,
            isWindowHeight: true,
          },
        },
        {
          name: 'delivery-point',
          path: ':deliveryPointRegionId/:deliveryPointId',
          component: DlpMapDetails,
          meta: {
            scrollToTop: true,
            footer: false,
            isWindowHeight: true,
          },
        },
      ],
    },
    {
      name: 'manifesto',
      path: '/manifeste-du-bon',
      components: {
        content: ManifestoPage,
      },
      meta: { scrollToTop: true },
    },
    // NPS
    {
      name: 'nps-survey',
      path: '/nps/enquete/:token',
      components: {
        content: NpsSurveyPage,
      },
      meta: {
        scrollToTop: true,
        header: false,
        footer: false,
        isWindowHeight: true,
      },
    }
  ],
};
